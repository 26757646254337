import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { AuthorizationDetailTabs } from "../../../../../models/Authorization";
import { ReAuthRequestDispatchTypes } from "../../../../../models/DispatchTypes/Authorization";
import {
  getAuthorizationDetailTabs,
  getDisciplines,
  getServiceProviderDetails,
  putAuthorizationDetailTab,
} from "../../../../../actions/AuthorizationDetails";
import { ApplicationState } from "../../../../../store/ApplicationState";
import { SaveIcd10RecordResponse } from "../../../../../models/Api/AllPatientRecord";
import Header from "../components/Header";
import {
  checkEligibility,
  eligibilityReset,
} from "../../../../../actions/AllPatientRecord";
import { SaveIcd10Records } from "../../../../../models/Patient";
import { UserDetailsPayload } from "../../../../../models/User";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { getPatients } from "../../../../../actions/PatientInformation";
import { eligibilityData } from "../../../../../utils/Eligibility";
import {
  getEligibilityRecord,
  getPriorAuthReferralSourceMasterResp,
  GetNtuReasonMasterResponse,
} from "../../../../../actions/Master";
import { getListResult } from "../../../../../utils";
import { resetStates } from "../../../../../actions/Common";

const saveIcd10Records = (saveIcd10RecordState: SaveIcd10RecordResponse) => {
  return saveIcd10RecordState.response;
};

const mapStateToProps = ({
  authorizationDetailTabsState,
  authPastMedicalHistoryState,
  checkEligibilityState,
  eligibilityRecordState,
  generalInfoState,
  icd10RecordState,
  icdCodeTypeState,
  loggedInUserState,
  patientInformationReceivedState,
  patientInformationViewState,
  payerState,
  putAuthorizationDetailTabState,
  saveIcd10RecordState,
  serviceProviderDetailsState,
  reAuthTimelineState,
  serviceProviderDataState,
  priorAuthReferralSourceState,
  masterNtuReasonState,
  updatePatientHeaderState
}: ApplicationState) => ({
  authorizationDetailTabsData: authorizationDetailTabsState,
  authPastMedicalHistoryData: authPastMedicalHistoryState,
  checkEligibilityData: checkEligibilityState,
  generalInfo: generalInfoState,
  icd10Records: icd10RecordState,
  icdCodeDataType: icdCodeTypeState,
  infoReceivedResp:
    patientInformationReceivedState as PatientInformationViewResponse,
  payerState: getListResult(payerState.response),
  eligibilityRecord: eligibilityData(
    eligibilityRecordState.response,
    checkEligibilityState.response
  ),
  patientData: patientInformationViewState as PatientInformationViewResponse,
  putAuthorizationDetailTabResp: putAuthorizationDetailTabState,
  serviceProviderDetails: serviceProviderDetailsState,
  saveIcd10RecordsRsp: saveIcd10Records(
    saveIcd10RecordState
  ) as SaveIcd10Records,
  user: loggedInUserState.response as UserDetailsPayload,
  reAuthTimeline: reAuthTimelineState,
  serviceProviderInfo: serviceProviderDataState,
  priorAuthReferralSource: priorAuthReferralSourceState,
  masterNtuReason: masterNtuReasonState,
  updatePatientHeaderData :updatePatientHeaderState
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<ReAuthRequestDispatchTypes>
  >
) => {
  return {
    saveAuthorizationDetailTab: (
      data: AuthorizationDetailTabs,
      referralId: string
    ) => {
      dispatch(putAuthorizationDetailTab(data, referralId));
    },
    getServiceProviderData: (referralId: number) => {
      dispatch(getServiceProviderDetails(referralId));
    },
    getAuthorizationDetailTabs: (referralId: string) => {
      dispatch(getAuthorizationDetailTabs(referralId));
    },
    getPatient: (id: number, type: number) => {
      dispatch(getPatients(id, type));
    },
    checkEligibility: (payload: any) => {
      dispatch(checkEligibility(payload));
    },
    getEligibilityData: (benefitPlanUid: string) => {
      dispatch(getEligibilityRecord(benefitPlanUid));
    },
    resetStates: (actionType: string[]) => {
      dispatch(resetStates(actionType));
    },
    eligibilityReset: () => {
      dispatch(eligibilityReset());
    },
    getDisciplinesData: (referralId: number) => {
      dispatch(getDisciplines(referralId));
    },
    getPriorAuthReferralSourceMasterResp: () => {
      dispatch(getPriorAuthReferralSourceMasterResp());
    },
    getNtuReasonMasterResponse: () => {
      dispatch(GetNtuReasonMasterResponse());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
