import { colors, others } from "../../../../../styles/colors";

export const DetailsInfoContainer = () => ({
  padding: "1rem",
});

export const DetailsInfo = () => ({
  padding: "0.9rem 0.9rem 0 0"
});

export const SearchResultGrid = () => ({
  backgroundColor: colors.backgroundColor[7],
  opacity: 1,
  padding: "1.2rem 0.8rem",
  paddingRight: "1.2rem",
  borderRadius: "1rem",
});

export const SearchGridContainer = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "1.8rem",
  borderRadius: "1rem",
});

export const SerachTitle = () => ({
  paddingLeft: "1rem",
  paddingBottom: "0.8rem",
});

export const SearchBox = () => ({
  padding: "1rem",
  width: "25rem"
});
