import { Reducer } from "redux";

import { PutAuthorizationDetailTabActionDispatchTypes } from "../constants/Authorization";
import { PutAuthorizationDetailTabDispatchTypes } from "../models/DispatchTypes/Authorization";
import { PutAuthorizationDetailTabResponse } from "../models/Api/Authorization";

const initialState: PutAuthorizationDetailTabResponse = {
  loading: false,
  response: {
    diseasesDto: [],
    psychologicalDiseasesDto: [],
    icdCodesInfoDto: [],
    authorizationGeneralInfoDto: {
      referralId: 0,
      receivedDateTime: "",
      referralStatusId: null,
      referralNtuReasonId: null,
      urgencyId: null,
      urgencyReason: "",
      startOfCareVerified: true,
      staffingStatusId: null,
      caregiverId: null,
      caregiverInfo: null,
      effectiveFrom: "",
      effectiveThrough: "",
      facilityDocumentationDate: null,
      dischargeDate: null,
      dischargeReasonId: null,
      dischargeVerified: true,
      userCreated: null,
      startOfCareDate: "",
      referralCreatedBy: "",
      referralCreatedOn: "",
      clinicalGroupDescription: null,
      serviceAddress: {
        streetName1: null,
        streetName2: null,
        county: null,
        city: null,
        patientState: null,
        zipCode: null,
        addressTypeId: null,
        latitude: null,
        longitude: null,
        streetName: null,
      },
      dinaDetailsResponse: {
        statusCode: -1,
        message: null,
        result: null,
        description: null,
        actions: null,
        error: null,
      },
    },
    requester: {
      facilityTypeId: null,
      requesterName: "",
      requesterExtension: "",
      requesterPhoneNo: "",
      requesterEmail: null,
      requesterFax: "",
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      requesterState: null,
      zipCode: null,
      facilityTypeName: "",
      facilityName: "",
      requesterNpi: null,
    },
    referralSource: {
      facilityTypeId: null,
      referralSourceName: "",
      referralSourceExtension: "",
      referralSourcePhoneNo: "",
      referralSourceEmail: null,
      referralSourceFax: null,
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      referralSourceState: null,
      zipCode: null,
      sameAsRequestingFacility: false,
      dischargeDate: null,
      facilityTypeName: "",
      facilityName: "",
      referralSourceNpi: null,
    },
  },
  error: null,
};

const PutAuthorizationDetailTabReducer: Reducer<
  PutAuthorizationDetailTabResponse,
  PutAuthorizationDetailTabDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_RESET: {
      return initialState;
    }
  }
  return state;
};

export default PutAuthorizationDetailTabReducer;
