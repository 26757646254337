import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { tooltipClasses } from "@mui/material";
import { FaTimesCircle } from "react-icons/fa";

import { colors } from "../colors";

// For Back Button
export const BackButtonUI = () => ({
  height: "1.7rem",
  padding: "0.1rem 0 0 0",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: colors.black[7],
    color: colors.primary.main,
    boxShadow: "none",
  },
});

// For Contained faded blue and primary text color Button
export const FadedContainedButtonUI = () => ({
  backgroundColor: colors.backgrounds[3],
  color: colors.fonts[18],
  height: "1.7rem",
  fontSize: ".7rem",
  fontWeight: 600,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: colors.backgrounds[3],
    color: colors.fonts[18],
    boxShadow: "none",
  },
});

// For Edit Button
export const EditButton = () => ({
  padding: "0 0 0 0",
  height: "1.15rem",
  margin: 0,
  textTransform: "initial",
});

// For BreadCrumb Selector
export const BreadcrumbsRoot = () => ({
  ".MuiBreadcrumbs-separator": {
    margin: 0,
  },
});

export const CursorPointer = () => ({
  cursor: "pointer",
});

export const ScrollLock = () => {
  return {
    disableScrollLock: true,
  };
};

export const BackButton = () => ({
  width: "4.5rem",
});

export const RouteBox = styled(Box)((props) => ({
  padding: "0 1.5rem 0 1.5rem",
  maxWidth: "none",
  margin: "0 auto",
  width: "100%",
  [props.theme.breakpoints.up(1920)]: {
    maxWidth: "1775px",
  },
}));

export const TooltipStyle = {
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
    {
      marginTop: "0.4rem",
      marginLeft: "-5rem",
    },
};

export const IconDisabled = {
  pointerEvents: "none" as React.CSSProperties["pointerEvents"],
  opacity: 0.5,
};
export const IconFaTimesCircle = styled(FaTimesCircle)(() => ({
  color: colors.red[80],
  opacity: 2,
  width: "2rem",
  height: "2rem",
  cursor: "pointer",
}));

export const ModalGridContainer = () => ({
  overflow: "scroll",
  zIndex: 1,
});

export const ExpeditedOptionColor = () => ({
  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input ": {
    color: colors.red[100],
  },
});
