import {
  Controller,
  UseFormGetValues,
  UseFormTrigger,
  UseFormWatch,
  useWatch,
} from "react-hook-form";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { isArray, isEmpty } from "lodash";
import { Dayjs } from "dayjs";
import EventIcon from "@mui/icons-material/Event";

import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import {
  AuthorizationStatusMasterResponse,
  CareCoordinationProgramResponse,
  CareGiverMasterResponse,
  DischargeReasonMasterResponse,
  HealthProgramResponse,
  NtuReasonMasterResponse,
  StaffingStatusMasterResponse,
} from "../../../../../models/Api/Master";
import {
  AuthorizationStatusMaster,
  CareCoordinationProgram,
  CareGiverMaster,
  DischargeReasonMaster,
  HealthProgram,
  StaffingStatusMaster,
} from "../../../../../models/Master";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import {
  getValue,
  length,
  getListResult,
  isExpedited,
} from "../../../../../utils";
import { addDaysToDate } from "../../../../../utils/DateTime";
import { rules } from "../../../../../utils/validation/Validation";
import colors from "../../../../../styles/colors/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AddressDetailHeader,
  AddressGrid,
  AuthorizationStatus,
  AuthStatusWidth,
  BottomGrid,
  BottomGridMiddleContainer,
  BottomGridMiddleContainerField,
  BottomGridPadding,
  CareGiverGrid,
  CareGiverInfoGrid,
  CareGiverWidth,
  CareProgramStyle,
  DateFormControl,
  DischargeDateWidth,
  DischargeVerify,
  FacilityDateWidth,
  HealthProgramStyle,
  HomeBoundWidth,
  MemberAddressBox,
  MidGrid,
  OuterBodyGrid,
  RightBottomGrid,
  ServiceAddressGrid,
  SocValidate,
  StaffingStatus,
  StartOfCare,
  TimeFormControl,
  TopGrid,
  UrgencyReason,
  UrgencyStatus,
} from "../styles/GeneralInfo";
import { FormInputTimePicker } from "../../../../../components/formComponents/FormInputTimePicker";
import {
  StaffingStatusConstant,
} from "../../../../../constants/Authorization";
import { UrgencyStatusMaster } from "../../../../../models/Patient";
import { UrgencyStatusMasterResponse } from "../../../../../models/Api/Patient";
import { AuthorizationDetailTabs } from "../../../../../models/Authorization";
import Permission from "../../../../Permission";
import { AuthDetailsAccess } from "../../../../../constants/Permission";
import AddressFormInputUseForm from "../../../../../components/formComponents/AddressFormInputUseForm";
import ReadOnlyField from "../../../../../components/formComponents/ReadOnlyField";
import { formatDate } from "../../../../../utils/DateTime";
import { DateFormats } from "../../../../../constants/Date";
import StaffingAutomationModal, {
  ModalActionType,
} from "./StaffingAutomationModal";
import {
  StaffingUrlContainer,
  StaffingUrlLabel,
  StaffingUrlValue,
} from "../styles/StaffingAutomationModal";
import { defaultDinaUrl } from "../../../../../constants/Config";

export interface PropsFromState {
  authorizationStatus: AuthorizationStatusMasterResponse;
  careCoordination: CareCoordinationProgramResponse;
  careGiver: CareGiverMasterResponse;
  control: any;
  disChargeReason: DischargeReasonMasterResponse;
  healthProgram: HealthProgramResponse;
  setValue: (id: any, value: any, boolean: any) => void;
  staffingStatus: StaffingStatusMasterResponse;
  urgencyStatus: UrgencyStatusMasterResponse;
  masterNtuReason: NtuReasonMasterResponse;
  watch: UseFormWatch<any>;
  values: AuthorizationDetailTabs;
  reset: () => void;
  trigger: UseFormTrigger<any>;
  clearErrors: (name: string) => void;
  getValues: UseFormGetValues<any>;
}

export interface propsFromDispatch {
  getNtuReasonMasterResponse: () => void;
}

type AllProps = PropsFromState & propsFromDispatch;

const AuthGeneralInfo: React.FC<AllProps> = ({
  getValues,
  authorizationStatus,
  careCoordination,
  careGiver,
  control,
  disChargeReason,
  healthProgram,
  setValue,
  staffingStatus,
  urgencyStatus,
  watch,
  reset,
  trigger,
  clearErrors,
  values,
  masterNtuReason,
  getNtuReasonMasterResponse,
}: AllProps) => {
  const searchInput = useRef() as MutableRefObject<HTMLInputElement>;

  const [urgencyStatusList, setUrgencyStatusList] = useState<
    UrgencyStatusMaster[] | any[]
  >([]);
  const [authorizationStatusList, setAuthorizationStatusList] = useState<
    AuthorizationStatusMaster[] | any[]
  >([]);

  const [careGiverList, setCareGiverList] = useState<CareGiverMaster[] | any[]>(
    []
  );
  const [dischargeReasonList, setDischargeReasonList] = useState<
    DischargeReasonMaster[] | any[]
  >([]);
  const [healthProgramList, setHealthProgramList] = useState<
    HealthProgram[] | any[]
  >([]);
  const [careCoordinationList, setCareCoordinationList] = useState<
    CareCoordinationProgram[] | any[]
  >([]);

  const [openStaffingAutomationModal, setOpenStaffingAutomationModal] =
    useState<boolean>(false);
  const [openStaffingUrlSection, setOpenStaffingUrlSection] =
    useState<boolean>(false);

  const { response: urgencyStatusResponse } = urgencyStatus;
  const { response: AuthorizationStatusResponse } = authorizationStatus;
  const { response: StaffingStatusResponse } = staffingStatus;
  const { response: CareGiverResponse } = careGiver;
  const { response: DischargeReasonResponse } = disChargeReason;
  const { response: healthProgramResponse } = healthProgram;
  const { response: careCoordinationResponse } = careCoordination;
  const { response: ntuReasonResponse } = masterNtuReason;
  const [maxSocDate, setMaxSocDate] = useState<Dayjs | null>(null);

  useWatch({
    name: "authorizationGeneralInfoDto",
    control,
  });

  useEffect(() => {
    const createdDate = getValue(
      values,
      "authorizationGeneralInfoDto.referralCreatedOn"
    );
    if (!isEmpty(createdDate)) {
      setMaxSocDate(addDaysToDate(createdDate, 6, "month"));
    }
  }, [
    values,
    getValue(values, "authorizationGeneralInfoDto.referralCreatedOn"),
  ]);

  useEffect(() => {
    const urgencyStatus =
      urgencyStatusResponse &&
      length(urgencyStatusResponse) &&
      urgencyStatusResponse.map((item) => {
        return {
          label: item.urgencyStatus,
          value: item.urgencyId,
        };
      });
    setUrgencyStatusList(urgencyStatus || []);
  }, [urgencyStatusResponse]);

  useEffect(() => {
    const authorizationStatus =
      AuthorizationStatusResponse &&
      length(AuthorizationStatusResponse) &&
      AuthorizationStatusResponse.map((item) => {
        return {
          label: item.referralStatusName,
          value: item.referralStatusId,
        };
      });
    setAuthorizationStatusList(authorizationStatus || []);
  }, [AuthorizationStatusResponse]);

  useEffect(() => {
    const careGiver =
      CareGiverResponse &&
      length(CareGiverResponse) &&
      CareGiverResponse.map((item) => {
        return {
          label: item.caregiver,
          value: item.caregiverId,
        };
      });
    setCareGiverList(careGiver || []);
  }, [CareGiverResponse]);

  useEffect(() => {
    const dischargeReason =
      DischargeReasonResponse &&
      length(DischargeReasonResponse) &&
      DischargeReasonResponse.map((item) => {
        return {
          label: item.dischargeReason,
          value: item.dischargeReasonId,
        };
      });
    setDischargeReasonList(dischargeReason || []);
  }, [DischargeReasonResponse]);

  useEffect(() => {
    const healthProgram =
      healthProgramResponse &&
      length(healthProgramResponse) &&
      healthProgramResponse.map((item) => {
        return {
          label: item.healthProgramName,
          value: item.healthProgramId,
        };
      });
    setHealthProgramList(healthProgram || []);
  }, [healthProgramResponse]);

  useEffect(() => {
    const careCoordinationProgram =
      careCoordinationResponse &&
      length(careCoordinationResponse) &&
      careCoordinationResponse.map((item) => {
        return {
          label: item.careProgramName,
          value: item.careProgramId,
        };
      });
    setCareCoordinationList(careCoordinationProgram || []);
  }, [careCoordinationResponse]);

  const ntuReasonVisibility = (referralStatusId: number) => {
    if (AuthorizationStatusResponse && length(AuthorizationStatusResponse)) {
      const authStatusObject = AuthorizationStatusResponse.find(
        (item) => item.referralStatusId === referralStatusId
      );
      if (authStatusObject) {
        return authStatusObject.haveReason;
      }
    }
  };

  const handleAuthStatusChange = (e: any) => {
    setValue("authorizationGeneralInfoDto.referralNtuReasonId", null, true);
    const referralStatusId = e.target.value;

    if (ntuReasonVisibility(referralStatusId) && !length(ntuReasonResponse)) {
      getNtuReasonMasterResponse();
    }
  };

  const labels = [
    {
      label: "Street Name 1: ",
      name: "authorizationGeneralInfoDto.serviceAddress.streetName1",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "Street Name 2: ",
      name: "authorizationGeneralInfoDto.serviceAddress.streetName2",
      helper: rules.streetName2,
      textLength: 100,
    },
    {
      label: "County: ",
      name: "authorizationGeneralInfoDto.serviceAddress.county",
      helper: rules.county,
      textLength: 100,
    },
    {
      label: "City: ",
      name: "authorizationGeneralInfoDto.serviceAddress.city",
      helper: rules.city,
      textLength: 100,
    },
    {
      label: "State: ",
      name: "authorizationGeneralInfoDto.serviceAddress.patientState",
      helper: rules.state,
      textLength: 100,
    },
    {
      label: "Zipcode: ",
      name: "authorizationGeneralInfoDto.serviceAddress.zipCode",
      helper: rules.zipcode,
      textLength: 10,
    },
  ];

  useEffect(() => {
    if (
      !isEmpty(getValues("dinaDetailsResponse")) &&
      getValues("dinaDetailsResponse.statusCode") > -1
    ) {
      setOpenStaffingUrlSection(true);
    }
  }, [getValues()]);

  const staffingUrl = getValues("dinaDetailsResponse.actions[0].url");

  const handleStaffingAutomationModal = (action: ModalActionType) => {
    setOpenStaffingAutomationModal(action === "open");
  };

  const staffingAutomationResponse = {
    details: getValues("dinaDetailsResponse.error"),
    message: getValues("dinaDetailsResponse.message"),
  };

  const staffingStatusList = (
    staffingStatusResponse: StaffingStatusMaster[]
  ) => {
    if (isArray(staffingStatusResponse)) {
      return staffingStatusResponse.map((item) => ({
        label: item.staffingStatusName,
        value: item.staffingStatusId,
        disabled:
          !isEmpty(getValues("dinaDetailsResponse.actions[0].url")) &&
          StaffingStatusConstant.indexOf(
            item.staffingStatusName.toLowerCase()
          ) !== -1
            ? true
            : false,
      }));
    }
    return [];
  };

  return (
    <Permission
      controlId={`${AuthDetailsAccess.GENERAL_INFO_CREATE}|${AuthDetailsAccess.GENERAL_INFO_READ}|`}
      passThrough
    >
      {(readOnly: boolean) => (
        <>
          <StaffingAutomationModal
            open={
              openStaffingAutomationModal &&
              !getValues("dinaDetailsResponse.actions[0].url")
            }
            title="STAFFING AUTOMATION RESPONSE"
            details={staffingAutomationResponse.details}
            message={staffingAutomationResponse.message}
            handleModal={handleStaffingAutomationModal}
          />
          <Grid container sx={OuterBodyGrid}>
            <Grid item xs={12} sx={TopGrid}>
              <Grid container>
                <Grid item xs={2.2}>
                  <FormControl variant="standard" sx={DateFormControl}>
                    <DatePicker
                      name="authorizationGeneralInfoDto.receivedDateTime"
                      control={control}
                      label={"Date Request Received:"}
                      helper={rules.dateTimeRequired}
                      allowFutureDate={false}
                      watch={watch}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2.8}>
                  <Box sx={TimeFormControl}>
                    <FormControl variant="standard">
                      <FormInputTimePicker
                        name="authorizationGeneralInfoDto.receivedDateTime"
                        control={control}
                        setValue={setValue}
                        label="Time Request Received:"
                        helper={rules.timeRequired}
                        value={getValues(
                          "authorizationGeneralInfoDto.receivedDateTime"
                        )}
                        watch={watch}
                        trigger={trigger}
                        clearErrors={clearErrors}
                        allowFutureTime={false}
                        readOnly={readOnly}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={0.1} sx={displayFlex} mt={1.5} mb={1.5}>
                  <Divider orientation="vertical" flexItem />
                </Grid>
                <Grid item xs={2.1} sx={AuthorizationStatus}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={AuthStatusWidth}
                  >
                    <FormInputDropdown
                      name="authorizationGeneralInfoDto.referralStatusId"
                      control={control}
                      label="Choose Authorization Status:"
                      helper={rules.authStatus}
                      list={authorizationStatusList}
                      onChangeHandler={handleAuthStatusChange}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </Grid>

                {ntuReasonVisibility(
                  getValue(
                    values,
                    "authorizationGeneralInfoDto.referralStatusId"
                  )
                ) && (
                  <Grid item xs={2.3} sx={AuthorizationStatus}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={AuthStatusWidth}
                    >
                      <FormInputDropdown
                        name="authorizationGeneralInfoDto.referralNtuReasonId"
                        control={control}
                        label="NTU Reason:"
                        helper={rules.ntuReason}
                        list={getListResult(ntuReasonResponse)}
                        readOnly={readOnly}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={2.5} sx={AuthorizationStatus}>
                  <ReadOnlyField
                    label={"Clinical Grouping:"}
                    value={
                      values.authorizationGeneralInfoDto
                        .clinicalGroupDescription || ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2} sx={UrgencyStatus}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={AuthStatusWidth}
                  >
                    <FormInputDropdown
                      name="authorizationGeneralInfoDto.urgencyId"
                      control={control}
                      label="Choose Urgency Status:"
                      helper={rules.urgencyStatus}
                      list={urgencyStatusList}
                      readOnly={readOnly}
                      InputStyle={isExpedited(
                        getValues("authorizationGeneralInfoDto.urgencyId")
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5.1} sx={UrgencyReason}>
                  <ReadOnlyField
                    label={"Authorization Type:"}
                    value={getValues(
                      "authorizationGeneralInfoDto.authorizationTypeName"
                    )}
                  />
                </Grid>
                <Grid item xs={2.2} sx={HealthProgramStyle}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={AuthStatusWidth}
                  >
                    <FormInputDropdown
                      name="authorizationGeneralInfoDto.healthProgramId"
                      control={control}
                      label="Health Plan Program:"
                      list={healthProgramList}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2.3} sx={CareProgramStyle}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={AuthStatusWidth}
                  >
                    <FormInputDropdown
                      name="authorizationGeneralInfoDto.careProgramId"
                      control={control}
                      label="Care Coordination Program:"
                      list={careCoordinationList}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={MidGrid}>
              <Grid container>
                <Grid item xs={2.2} sx={StartOfCare}>
                  <FormControl variant="standard" fullWidth>
                    <DatePicker
                      name="authorizationGeneralInfoDto.startOfCareDate"
                      control={control}
                      label={"Start of Care:"}
                      helper={rules.referralReceivedDate}
                      readOnly={readOnly}
                      allowFutureDate={true}
                      maximumdate={maxSocDate}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1.4} sx={SocValidate}>
                  <Controller
                    control={control}
                    name="authorizationGeneralInfoDto.startOfCareVerified"
                    defaultValue={false}
                    render={({ field: { value, onChange, ref } }) => (
                      <FormControlLabel
                        label={
                          <Typography
                            variant="body1"
                            fontWeight={fontWeight.Weight[4]}
                            color={colors.black[4]}
                          >
                            SoC Validated
                          </Typography>
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={value || false}
                            onChange={onChange}
                            inputRef={ref}
                            disabled={readOnly}
                          />
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.1} sx={StaffingStatus}>
                  <FormControl variant="standard" fullWidth>
                    <FormInputDropdown
                      name="authorizationGeneralInfoDto.staffingStatusId"
                      control={control}
                      label="Staffing Status:"
                      helper={rules.staffingStatus}
                      list={staffingStatusList(StaffingStatusResponse)}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={0.1} sx={displayFlex} p={"0.8rem 0"}>
                  <Divider orientation="vertical" flexItem />
                </Grid>
                {openStaffingUrlSection && (
                  <Grid item xs={2.1} sx={StaffingStatus}>
                    <Box sx={StaffingUrlContainer}>
                      <Box sx={StaffingUrlLabel}>
                        <Typography
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.fonts[2]}
                        >
                          Staffing URL:
                        </Typography>
                      </Box>
                      <Box sx={StaffingUrlValue}>
                        <Link
                          onClick={() => handleStaffingAutomationModal("open")}
                          href={!isEmpty(staffingUrl) && staffingUrl}
                          target="_blank"
                        >
                          <Typography
                            fontWeight={fontWeight.Weight[4]}
                            lineHeight="1.3"
                            color={colors.fonts[8]}
                          >
                            {staffingUrl || defaultDinaUrl}
                          </Typography>
                        </Link>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={AddressGrid}>
              <Grid container>
                <Grid item xs={12} sx={MemberAddressBox}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={fontWeight.Weight[5]}
                    color={colors.black[2]}
                    sx={AddressDetailHeader}
                  >
                    Please add the address details where member would be
                    receiving services.
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={ServiceAddressGrid}>
                  <AddressFormInputUseForm
                    control={control}
                    searchInput={searchInput}
                    reset={reset}
                    getValues={getValues}
                    setValue={setValue}
                    labels={labels}
                    addressLabel="ADDRESS DETAILS:"
                    addressVariant="subtitle2"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2.5} sx={BottomGrid}>
              <Grid container>
                <Grid item xs={12} sx={CareGiverGrid}>
                  <FormControl variant="standard" fullWidth sx={HomeBoundWidth}>
                    <FormInputDropdown
                      name="authorizationGeneralInfoDto.caregiverId"
                      control={control}
                      label="Caregiver:"
                      list={careGiverList}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={CareGiverInfoGrid}>
                  <FormControl variant="standard" fullWidth sx={CareGiverWidth}>
                    <FormInputText
                      name="authorizationGeneralInfoDto.caregiverInfo"
                      control={control}
                      label="Caregiver Info:"
                      textLength={128}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sx={BottomGrid}>
              <Grid container sx={BottomGridMiddleContainer}>
                <Grid item xs={4.5} sx={BottomGridMiddleContainerField}>
                  <ReadOnlyField
                    label={"Created By:"}
                    value={getValues(
                      "authorizationGeneralInfoDto.referralCreatedBy"
                    )}
                  />
                </Grid>
                <Grid item xs={4.5} sx={BottomGridMiddleContainerField}>
                  <ReadOnlyField
                    label={"Created On:"}
                    value={
                      getValues("authorizationGeneralInfoDto.referralCreatedOn")
                        ? formatDate(
                            getValues(
                              "authorizationGeneralInfoDto.referralCreatedOn"
                            ),
                            DateFormats.MM_DD_YYYY_h_mm_a
                          )
                        : ""
                    }
                    icon={<EventIcon fontSize="small" color="primary" />}
                  />
                </Grid>
                <Grid item xs={4.5} sx={BottomGridMiddleContainerField}>
                  <ReadOnlyField
                    label={"Effective From:"}
                    value={
                      getValues("authorizationGeneralInfoDto.effectiveFrom")
                        ? formatDate(
                            getValues(
                              "authorizationGeneralInfoDto.effectiveFrom"
                            ),
                            DateFormats.MM_DD_YYYY
                          )
                        : ""
                    }
                    icon={<EventIcon fontSize="small" color="primary" />}
                  />
                </Grid>
                <Grid item xs={4.5} sx={BottomGridMiddleContainerField}>
                  <ReadOnlyField
                    label={"Effective Through:"}
                    value={
                      getValues("authorizationGeneralInfoDto.effectiveThrough")
                        ? formatDate(
                            getValues(
                              "authorizationGeneralInfoDto.effectiveThrough"
                            ),
                            DateFormats.MM_DD_YYYY
                          )
                        : ""
                    }
                    icon={<EventIcon fontSize="small" color="primary" />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5.2} sx={RightBottomGrid}>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={5} sx={BottomGridPadding}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={FacilityDateWidth}
                  >
                    <DatePicker
                      name="authorizationGeneralInfoDto.facilityDocumentationDate"
                      control={control}
                      label={"Inpt Facility D/C Date:"}
                      readOnly={readOnly}
                      allowFutureDate={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7} sx={BottomGridPadding}></Grid>
                <Grid item xs={5} sx={BottomGridPadding}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={DischargeDateWidth}
                  >
                    <DatePicker
                      name="authorizationGeneralInfoDto.dischargeDate"
                      control={control}
                      label={"HH Discharge Date:"}
                      readOnly={readOnly}
                      allowFutureDate={false}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7} sx={BottomGridPadding}>
                  <FormControl variant="standard" fullWidth>
                    <FormInputDropdown
                      name="authorizationGeneralInfoDto.dischargeReasonId"
                      control={control}
                      label="Discharge Reason:"
                      list={dischargeReasonList}
                      readOnly={readOnly}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={DischargeVerify}>
                  <Controller
                    control={control}
                    name="authorizationGeneralInfoDto.dischargeVerified"
                    defaultValue={false}
                    render={({ field: { value, onChange, ref } }) => (
                      <FormControlLabel
                        label={
                          <Typography
                            variant="body1"
                            fontWeight={fontWeight.Weight[4]}
                            color={colors.black[4]}
                          >
                            Discharge Verified
                          </Typography>
                        }
                        control={
                          <Checkbox
                            size="small"
                            checked={value || false}
                            onChange={onChange}
                            inputRef={ref}
                            disabled={readOnly}
                          />
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Permission>
  );
};

export default AuthGeneralInfo;
