import { colors } from "../../../../styles/colors";

const box = {
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  marginLeft: "1.2rem",
};

const formlabel = { marginRight: "15px" };

const formcontrol = { flexDirection: "row", alignItems: "center" };
const formgroup = { flexDirection: "row" };

export const style = {
  box,
  formcontrol,
  formgroup,
  formlabel,
};

export const checkboxcolor = {
  color: colors.fonts[18],
  "&.Mui-checked": {
    color: colors.fonts[18],
  },
};

export const CheckboxFormControl = {
  "&.MuiFormControlLabel-root": {
    marginRight: "0rem",
  },
};
