import { useEffect, useState } from "react";
import { FaFax, FaRegCalendarCheck, FaUserNurse } from "react-icons/fa";
import {
  AutocompleteInputChangeReason,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { debounce, isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";

import {
  AutoComplete,
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  Gridbody,
  GridContent,
  Header,
  HeaderTitle,
  HorizontalDivider,
  Item,
  ItemIconBox,
  ItemValue,
  ShowIcon,
  SummaryBox,
  VerticalDivider,
} from "../styles/ServiceProviderDetails";
import { colors } from "../../../../../styles/colors";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  ServiceProviderData,
  ServiceProviderPayload,
} from "../../../../../models/Authorization";
import { FaPhoneInputIcon } from "../../../../../components/formComponents/styles/style";
import {
  CancelButton,
  CancelProviderModalGrid,
  ContentBox,
  FooterBox,
  GridContainer,
  HeaderHelpOutlineOutlinedIcon,
  IconBox,
  ModalGrid,
  ModalHeaderBox,
  ModalSubtitle,
  ModalTitle,
  SearchContent,
  SearchGridContainer,
  SearchResultGrid,
  SearchTitle,
  SearchTitleGrid,
  SubtitleHorizontalDivider,
} from "../styles/ModifyRequestModal";
import {
  getFormattedPhoneNo,
  getValue,
  isNil,
  length,
} from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { ServiceProvider } from "../../../../../models/Service";
import { ServiceProviderDetailsResponse } from "../../../../../models/Api/Authorization";
import { UserDetailsPayload } from "../../../../../models/User";
import VirtualizeAutocomplete from "../../../../../components/formComponents/AutocompleteList";
import { ServiceProviderResponse } from "../../../../../models/Api/Service";
import Permission from "../../../../Permission";
import { AuthDetailsAccess } from "../../../../../constants/Permission";
import {
  IconFaTimesCircle,
  ModalGridContainer,
} from "../../../../../styles/common/style";
import {
  getTableHead,
  getTableBody,
  TableDropdown,
} from "../../../../../constants/AllPatientRecord";
import { DateFormats } from "../../../../../constants/Date";
import { DeleteResponse } from "../../../../../models/Api/Delete";
import { ServiceProviderDetailsActionDispatchTypes } from "../../../../../constants/Authorization";

export interface PropsFromDispatch {
  getServiceProviderById: (value: string) => void;
  getServiceProviderData: (authNo: number) => void;
  getServiceProviders: (value: string) => void;
  resetServiceProvider: () => void;
  putServiceProviderDetails: (
    authId: number,
    payLoad: ServiceProviderData
  ) => void;
  deleteServiceProvider: (referralId: number) => void;
  getReAuthTimelineData: (referralId: string) => void;
  getAuthorizationDetailTabs: (referralId: string) => void;
  resetStates: (actionType: string[]) => void;
  setValue: (id: any, value: any, boolean: any) => void;
  control: any;
}
export interface PropsFromState {
  serviceProviderDetails: ServiceProviderDetailsResponse;
  serviceProviderList: ServiceProviderResponse;
  updatedIntakeServiceProviderData: ServiceProviderPayload;
  user: UserDetailsPayload;
  modifyDetails: boolean;
  deleteServiceProviderDetail: DeleteResponse
}
type AllProps = PropsFromState & PropsFromDispatch;

const ServiceProviderDetails: React.FC<AllProps> = ({
  getServiceProviderById,
  getServiceProviderData,
  getServiceProviders,
  putServiceProviderDetails,
  resetServiceProvider,
  serviceProviderDetails,
  serviceProviderList,
  updatedIntakeServiceProviderData,
  user,
  modifyDetails,
  deleteServiceProvider,
  deleteServiceProviderDetail,
  getReAuthTimelineData,
  getAuthorizationDetailTabs,
  resetStates,
  setValue,
  control
}: AllProps) => {
  const [serviceProviderData, setServiceProviderData] =
    useState<ServiceProviderData>(serviceProviderDetails.response);
  const [showProvider, setShowProvider] = useState(false);
  const [serviceProviders, setServiceProviders] = useState(
    serviceProviderList.response
  );
  const [openModal, setOpenModal] = useState(false);
  const [openClearProviderModal, setOpenClearProviderModal] = useState(false);

  const { authNo } = useParams();
  const referralId = authNo;

  const { loading, response } = serviceProviderDetails;
  const { response: deleteServiceProviderDetailResponse } = deleteServiceProviderDetail;
  useEffect(() => {
    if (!loading && referralId) {
      getServiceProviderById(referralId);
    }
    if (
      updatedIntakeServiceProviderData &&
      updatedIntakeServiceProviderData.referralServiceProviderId === -1
    ) {
      getServiceProviderData(Number(referralId));
    }
  }, [referralId]);

  useEffect(() => {
    if (referralId && deleteServiceProviderDetailResponse) {
      getServiceProviderData(Number(referralId));
      getServiceProviderById(referralId);
    }
  }, [deleteServiceProviderDetailResponse])

  useEffect(() => {
    if (response.referralId !== -1) {
      setServiceProviderData(serviceProviderDetails.response);
      getServiceProviderById(referralId ? referralId.toString() : "");
    }
  }, [serviceProviderDetails.response]);

  useEffect(() => {
    setServiceProviders(serviceProviderList.response);
  }, [serviceProviderList.response]);

  useEffect(() => {
    if (referralId) {
      getReAuthTimelineData(referralId)
      getAuthorizationDetailTabs(referralId)
    }
  }, [updatedIntakeServiceProviderData])

  const handleModifyRequest = () => {
    setOpenModal(true);
    resetServiceProvider();
    setServiceProviders(() => []);
  };

  const handleClearProvider = () => {
    setOpenClearProviderModal(true);
    return resetStates([
      ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_RESET,
    ]);
  }

  return (
    <>
      <Grid container sx={Gridbody}>
        <Grid item xs={12}>
          <Box sx={Header}>
            <Box sx={displayFlex}>
              {showProvider ? (
                <FaChevronCircleDownIcon
                  onClick={() => setShowProvider(false)}
                  sx={ShowIcon}
                />
              ) : !showProvider ? (
                <FaChevronCircleUpIcon
                  onClick={() => setShowProvider(true)}
                  sx={ShowIcon}
                />
              ) : (
                ""
              )}
              <FaUserNurse color={colors.fonts[18]} />
              <Typography
                variant="subtitle1"
                color={colors.fonts[20]}
                fontWeight={fontWeight.Weight[5]}
                sx={HeaderTitle}
              >
                SERVICE PROVIDER DETAILS:
              </Typography>
            </Box>
            <Permission
              controlId={`|${AuthDetailsAccess.SERVICE_PROVIDER_AUTH_DETAILS_READ}|${AuthDetailsAccess.SERVICE_PROVIDER_AUTH_DETAILS_UPDATE}`}
              passThrough
            >
              {(isDisabled: boolean) => (
                <Box>
                  <Button
                    disabled={!modifyDetails || isDisabled || isNil(getValue(serviceProviderData, "referralServiceProviderId"))}
                    variant="contained"
                    onClick={() => handleClearProvider()}
                  >
                    CLEAR SERVICE PROVIDER
                  </Button>
                  <Button
                    disabled={!modifyDetails || isDisabled}
                    variant="contained"
                    onClick={() => handleModifyRequest()}
                  >
                    MODIFY SERVICE PROVIDER
                  </Button>
                </Box>
              )}
            </Permission>
          </Box>
        </Grid>
        <Divider light sx={HorizontalDivider} />
        {!showProvider && (
          <Grid container>
            <Grid item xs={12} sx={GridContent}>
              <Grid container>
                <Grid item xs={1.8} sx={Item}>
                  {fieldRender(
                    "Provider Name:",
                    getValue(serviceProviderData, "providerName", "")
                  )}
                </Grid>
                <Divider orientation="vertical" flexItem sx={VerticalDivider} />
                <Grid item xs={9.5} sx={SummaryBox}>
                  {fieldRenderData(
                    "Street Name 1:",
                    getValue(serviceProviderData, "streetName1")
                  )}
                  {fieldRenderData(
                    "Street Name 2:",
                    getValue(serviceProviderData, "streetName2")
                  )}
                  {fieldRenderData(
                    "City:",
                    getValue(serviceProviderData, "providerCity")
                  )}
                  {fieldRenderData(
                    "State:",
                    getValue(serviceProviderData, "providerState")
                  )}
                  {fieldRenderData(
                    "Zipcode:",
                    getValue(serviceProviderData, "zipCode")
                  )}
                  {fieldRender(
                    "Phone:",
                    getFormattedPhoneNo(getValue(serviceProviderData, "phone")),
                    <FaPhoneInputIcon />
                  )}
                  {fieldRender(
                    "Fax No:",
                    getFormattedPhoneNo(getValue(serviceProviderData, "fax")),
                    <FaFax />
                  )}
                </Grid>
              </Grid>
              <Divider light sx={HorizontalDivider} />
            </Grid>
            <Grid item xs={12} sx={GridContent}>
              <Grid container>
                <Grid item xs={4} sx={SummaryBox}>
                  {fieldRender(
                    "Parent Company:",
                    getValue(serviceProviderData, "parentCompany", "")
                  )}
                  {fieldRender("Parent Company Abbreviation:", "")}
                </Grid>
                <Divider orientation="vertical" flexItem sx={VerticalDivider} />
                <Grid item xs={7} sx={SummaryBox}>
                  {fieldRender(
                    "NPI:",
                    getValue(serviceProviderData, "providerNpi", "")
                  )}
                  {fieldRender(
                    "Tax ID:",
                    getValue(serviceProviderData, "taxId", "")
                  )}
                  {fieldRender(
                    "Medicare No:",
                    getValue(serviceProviderData, "medicareId", "")
                  )}
                  {fieldRender(
                    "Medicaid No:",
                    getValue(serviceProviderData, "ahcccsId", "")
                  )}
                </Grid>
              </Grid>
              <Divider light sx={HorizontalDivider} />
            </Grid>
            <Grid item xs={11.5} sx={GridContent}>
              <Box sx={SummaryBox}>
                {fieldRender("Network:", "")}
                {fieldRender("Out of Member Network:", "")}
                {fieldRender("Reimbursement Type:", "")}
                {fieldRender("Contract Status:", "")}
                {fieldRender(
                  "Contract Effective From:",
                  formatDate(
                    getValue(serviceProviderData, "contractEffectiveFrom", ""),
                    DateFormats.MM_DD_YYYY
                  ),
                  <FaRegCalendarCheck
                    size={"1.1rem"}
                    color={colors.fonts[18]}
                  />
                )}
                {fieldRender(
                  "Contract Effective To:",
                  formatDate(
                    getValue(serviceProviderData, "contractEffectiveTo", ""),
                    DateFormats.MM_DD_YYYY
                  ),
                  <FaRegCalendarCheck
                    size={"1.1rem"}
                    color={colors.fonts[18]}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>

      <ModifyRequestModal
        openModal={openModal}
        closeModal={setOpenModal}
        serviceProviderDetails={serviceProviderData}
        serviceProviders={serviceProviders}
        putServiceProviderDetails={putServiceProviderDetails}
        getServiceProviders={getServiceProviders}
        referralId={Number(referralId)}
        user={user}
        updatedIntakeServiceProviderData={updatedIntakeServiceProviderData}
        resetServiceProvider={resetServiceProvider}
        loading={serviceProviderList.loading}
        setValue={setValue}
        control={control}
      />
      <ClearProviderModal
        openClearProviderModal={openClearProviderModal}
        closeClearProviderModal={setOpenClearProviderModal}
        deleteServiceProvider={deleteServiceProvider}
        referralId={Number(referralId)}
        resetStates={resetStates}
      />
    </>
  );
};

const fieldRender = (label: string, value: string | number, icon?: any) => {
  return (
    <Box>
      <Box>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[2]}
          mb={".6rem"}
        >
          {label}
        </Typography>
      </Box>
      <Box sx={displayFlex}>
        {value && icon ? <Box sx={ItemIconBox}>{icon}</Box> : ""}
        <Typography
          variant={label === "Provider Name:" ? "h6" : "subtitle1"}
          fontWeight={fontWeight.Weight[5]}
          color={colors.fonts[1]}
          sx={ItemValue}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const fieldRenderData = (label: string, value: string | number) => {
  return (
    <Box>
      <Typography
        variant="body1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[2]}
        mb={".6rem"}
      >
        {label}
      </Typography>
      <Typography
        variant="subtitle1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.fonts[1]}
        sx={ItemValue}
      >
        {value ? value : ""}
      </Typography>
    </Box>
  );
};

type ModalProps = {
  closeModal: (onClose: boolean) => void;
  putServiceProviderDetails: (
    authId: number,
    payLoad: ServiceProviderData
  ) => void;
  getServiceProviders: (value: string) => void;
  resetServiceProvider: () => void;
  openModal: boolean;
  serviceProviderDetails: ServiceProviderData;
  serviceProviders: ServiceProvider[];
  referralId: number;
  user: UserDetailsPayload;
  updatedIntakeServiceProviderData: ServiceProviderPayload;
  loading: boolean;
  setValue: (id: any, value: any, boolean?: any) => void;
  control: any;
};

const ModifyRequestModal = ({
  closeModal,
  putServiceProviderDetails,
  getServiceProviders,
  resetServiceProvider,
  openModal,
  serviceProviders,
  serviceProviderDetails,
  referralId,
  user,
  updatedIntakeServiceProviderData,
  loading,
  setValue,
  control
}: ModalProps) => {
  const [serviceProvidersList, setServiceProvidersList] =
    useState(serviceProviders);

  const [openProvider, setOpenProvider] = useState(false);

  const [providerDetails, setProviderDetails] =
    useState<ServiceProviderData | null>(null);

  useEffect(() => {
    if (!isNil(serviceProviders) && length(serviceProviders)) {
      const newProviderList = serviceProviders;
      if (
        !isNil(newProviderList) &&
        length(newProviderList) &&
        newProviderList.length != 0
      ) {
        setServiceProvidersList(() => newProviderList);
        setOpenProvider(true);
      }
    } else {
      setServiceProvidersList(() => []);
    }
  }, [serviceProviders]);

  const handleClose = () => {
    closeModal(false);
    setProviderDetails(null);
  };

  const handleProviderName = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (value) {
      if (reason === "input") {
        if (value.length <= 2) {
          setServiceProvidersList(() => []);
        } else {
          if (value.trim().length >= 3) {
            getServiceProviders(value);
          }
        }
      }
    } else {
      setServiceProvidersList([]);
      setOpenProvider(false);
    }
  };

  const debouncedSearch = debounce((event, value, reason) => {
    handleProviderName(event, value, reason);
  }, 500);

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    debouncedSearch(event, value, reason);
  };

  const chooseProvider = (option: any) => {
    const value = serviceProvidersList.find(
      (provider) => provider.serviceProviderId === option.serviceProviderId
    );
    setValue("providerName", value?.providerName || null);
    if (!isNil(option)) {
      setOpenProvider(false);
      setProviderDetails(option);
      resetServiceProvider();
    }
  };

  const onSubmit = () => {
    const providerDetail = JSON.parse(JSON.stringify(providerDetails));
    if (providerDetail) {
      providerDetail.userCreated = user && user.email ? user.email : "";
      providerDetail.referralId =
        referralId || serviceProviderDetails.referralId;
      providerDetail.referralServiceProviderId = updatedIntakeServiceProviderData === null ? 0 :
        updatedIntakeServiceProviderData &&
        updatedIntakeServiceProviderData.referralServiceProviderId;
    }

    if (providerDetail && providerDetail.referralId) {
      putServiceProviderDetails(
        referralId || providerDetail.referralId,
        providerDetail
      );
      closeModal(false);
      setProviderDetails(null);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={ModalGridContainer}
      >
        <Box sx={ModalGrid}>
          <Grid container sx={ContentBox}>
            <Grid item xs={11.5} sx={ModalHeaderBox}>
              <Typography
                variant="h6"
                color={colors.fonts[20]}
                fontWeight={fontWeight.Weight[5]}
                sx={ModalTitle}
              >
                ADD SERVICE PROVIDER:
              </Typography>
            </Grid>
            <Grid item xs={0.5} sx={IconBox}>
              <IconFaTimesCircle onClick={handleClose} />
            </Grid>
          </Grid>
          <Grid container sx={GridContainer}>
            <Grid item xs={12} pl={"0.5rem"}>
              <Typography
                variant="subtitle1"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[4]}
                sx={ModalSubtitle}
              >
                Search service provider and assign from here:
              </Typography>
              <Typography
                variant="body1"
                color={colors.fonts[5]}
                fontWeight={fontWeight.Weight[2]}
              >
                View service provider details in the result area and assign them
                by clicking on &quot;Add&quot; button.
              </Typography>
            </Grid>
            <Divider light sx={SubtitleHorizontalDivider} />
            <Grid item xs={12} sx={SearchTitleGrid}>
              <Box>
                <Typography
                  variant="subtitle1"
                  color={colors.fonts[2]}
                  fontWeight={fontWeight.Weight[3]}
                  pb={"1rem"}
                >
                  Name:
                </Typography>
                <Controller
                  name={"providerName"}
                  defaultValue={null}
                  control={control}
                  render={() => {
                    return (
                      <>
                        <VirtualizeAutocomplete
                          options={serviceProvidersList}
                          value={null}
                          handleInputChange={handleInputChange}
                          sx={AutoComplete}
                          open={openProvider}
                          handleSelect={chooseProvider}
                          tableHead={getTableHead(
                            TableDropdown.SERVICE_PROVIDER
                          )}
                          tableBody={getTableBody(
                            TableDropdown.SERVICE_PROVIDER
                          )}
                          autocompleteInputIcon={<FaUserNurse />}
                          setOpen={setOpenProvider}
                          maxLength={128}
                          width="55rem"
                          loading={loading}
                          placeholder="Search by name or NPI"
                        />
                      </>
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={SearchResultGrid}>
              <Box>
                <Typography
                  variant="body1"
                  color={colors.fonts[20]}
                  fontWeight={fontWeight.Weight[5]}
                  sx={SearchTitle}
                >
                  SEARCH RESULT:
                </Typography>
              </Box>
              <Grid container sx={SearchGridContainer}>
                <Grid item xs={12} sx={SearchContent}>
                  <Grid container>
                    <Grid item xs={2.3} p={"0.8rem"}>
                      {fieldRender(
                        "Provider Name:",
                        getValue(providerDetails, "providerName", "")
                      )}
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={VerticalDivider}
                    />
                    <Grid item xs={8.2} sx={SummaryBox}>
                      <Box>
                        <Typography
                          variant="body1"
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.fonts[2]}
                          mb={".6rem"}
                        >
                          Address 1:
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          fontWeight={fontWeight.Weight[5]}
                          color={colors.fonts[1]}
                          pt={"0.3rem"}
                        >
                          {getValue(providerDetails, "providerAddress", "")}
                        </Typography>
                      </Box>
                      {fieldRender(
                        "Phone:",
                        getFormattedPhoneNo(getValue(providerDetails, "phone")),
                        <FaPhoneInputIcon />
                      )}
                      {fieldRender(
                        "Fax No:",
                        getFormattedPhoneNo(getValue(providerDetails, "fax")),
                        <FaFax />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider light sx={HorizontalDivider} />
                <Grid item xs={12} sx={SearchContent}>
                  <Grid container>
                    <Grid item xs={4.8} sx={SummaryBox}>
                      {fieldRender("Parent Company:", "")}
                      {fieldRender("Parent Company Abbreviation:", "")}
                    </Grid>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={VerticalDivider}
                    />
                    <Grid item xs={6.2} sx={SummaryBox}>
                      {fieldRender(
                        "NPI:",
                        getValue(providerDetails, "providerNpi", "")
                      )}
                      {fieldRender(
                        "Tax ID:",
                        getValue(providerDetails, "taxId", "")
                      )}
                      {fieldRender(
                        "Medicare No:",
                        getValue(providerDetails, "medicareId", "")
                      )}
                      {fieldRender(
                        "Medicaid No:",
                        getValue(providerDetails, "ahcccsId", "")
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider light sx={HorizontalDivider} />
                <Grid item xs={11.5} sx={SearchContent}>
                  <Box sx={SummaryBox}>
                    {fieldRender("Network:", "")}
                    {fieldRender("Out of Member Network:", "")}
                    {fieldRender("Reimbursement Type:", "")}
                    {fieldRender("Contact Status:", "")}
                    {fieldRender(
                      "Contract Effective From:",
                      formatDate(
                        getValue(providerDetails, "contractEffectiveFrom", ""),
                        DateFormats.MM_DD_YYYY
                      ),
                      <FaRegCalendarCheck
                        size={"1.1rem"}
                        color={colors.fonts[18]}
                      />
                    )}
                    {fieldRender(
                      "Contract Effective To:",
                      formatDate(
                        getValue(providerDetails, "contractEffectiveTo", ""),
                        DateFormats.MM_DD_YYYY
                      ),
                      <FaRegCalendarCheck
                        size={"1.1rem"}
                        color={colors.fonts[18]}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={FooterBox}>
            <Button variant="outlined" onClick={handleClose} sx={CancelButton}>
              CANCEL
            </Button>
            <Button
              disabled={isEmpty(getValue(providerDetails, "providerName"))}
              variant="contained"
              onClick={() => onSubmit()}
            >
              +ADD
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

type ClearProviderModalProps = {
  openClearProviderModal: boolean;
  closeClearProviderModal: (onClose: boolean) => void;
  deleteServiceProvider: (referralId: number) => void;
  referralId: number;
  resetStates: (actionType: string[]) => void;
}
const ClearProviderModal = ({ openClearProviderModal, closeClearProviderModal, deleteServiceProvider, referralId, resetStates }: ClearProviderModalProps) => {

  const handleClose = () => {
    closeClearProviderModal(false)
  }
  const handleClearServiceProvider = () => {
    deleteServiceProvider(referralId);
    closeClearProviderModal(false);
    return resetStates([
      ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_RESET,
    ]);
  }
  return (
    <>
      <Modal
        open={openClearProviderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={ModalGridContainer}
      >
        <Box sx={CancelProviderModalGrid}>
          <Grid container sx={ContentBox}>
            <Grid item xs={11.2} >
              <Typography
                variant="h6"
                color={colors.fonts[20]}
                fontWeight={fontWeight.Weight[5]}
                sx={ModalTitle}
              >
                Clear Service Provider:
              </Typography>
            </Grid>
            <Grid item xs={0.5} sx={IconBox}>
              <IconFaTimesCircle onClick={handleClose} />
            </Grid>
            <Grid item xs={0.5}><HeaderHelpOutlineOutlinedIcon /></Grid>
            <Grid item xs={11.5}>
              <Typography
                variant="subtitle1"
                color={colors.black[2]}
                fontWeight={fontWeight.Weight[4]}
                sx={ModalSubtitle}
              >
                Are you sure you want to remove this Service Provider?
              </Typography>
            </Grid>
          </Grid>
          <Box sx={FooterBox}>
            <Button variant="outlined" onClick={handleClose} sx={CancelButton}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={handleClearServiceProvider}>
              CLEAR
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
export default ServiceProviderDetails;
