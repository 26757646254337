import { others } from "../../../../../styles/colors";
import colors from "../../../../../styles/colors/colors";

export const OuterBodyGrid = () => ({
  backgroundColor: colors.backgroundColor[7],
  opacity: 1,
  padding: "1rem 1rem",
  marginTop: "1.8rem",
  borderRadius: "1.2rem",
});

export const LofOuterBodyGrid = (columnsLength: number) => ({
  backgroundColor:
    columnsLength > 0 ? colors.backgroundColor[7] : colors.backgroundColor[2],
  opacity: 1,
  padding: "1rem",
  marginTop: "1rem",
  borderRadius: "1.2rem",
});

export const TopGrid = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  padding: "0.5rem 1rem 0 1rem",
  marginBottom: "0.8rem",
  borderRadius: "0.8rem",
});

export const DateReceived = () => ({
  padding: "1.5rem 1rem ",
});

export const TimeReceived = () => ({
  padding: "1.5rem 3rem 1.5rem 2rem ",
});

export const AuthorizationStatus = () => ({
  padding: "1.5rem 1rem ",
});

export const HealthProgramStyle = () => ({
  padding: "1.5rem 1.5rem ",
  marginLeft: "1rem",
});

export const CareProgramStyle = () => ({
  padding: "1.5rem 2.5rem ",
});

export const UrgencyStatus = () => ({
  padding: "1.5rem 0rem  1.5rem 1rem",
});

export const UrgencyReason = () => ({
  padding: "1.5rem 0rem 1rem 1rem ",
  paddingRight: "2rem",
});

export const MidGrid = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  marginBottom: "0.8rem",
  opacity: 1,
  padding: "0.5rem 0 0 1rem",
  borderRadius: "1.2rem",
});

export const StartOfCare = () => ({
  padding: " 2rem 1rem 2rem 1.5rem ",
});

export const DateFormControl = () => ({
  marginRight: "2rem",
  padding: "1.5rem 1rem ",
});

export const TimeFormControl = () => ({
  marginRight: "2rem",
  paddingTop: "1.5rem",
});

export const SocValidate = () => ({
  padding: " 0.8rem 0  0 1.5rem ",
  display: "flex",
  alignItems: "center",
  minWidth: "7.1rem",
});

export const StaffingStatus = () => ({
  padding: " 2rem 2rem ",
});

export const BottomGrid = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  paddingTop: "0.5rem",
  marginRight: "0.85rem",
  borderRadius: "1.2rem",
});

export const RightBottomGrid = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  opacity: 1,
  paddingTop: "0.5rem",
  borderRadius: "1.2rem",
});

export const BottomGridPadding = () => ({
  padding: "1rem 2.5rem",
});

export const DischargeVerify = () => ({
  padding: " 0 3rem 0.5rem 3rem ",
});

export const BottomGridMiddleContainer = () => ({
  display: "flex",
  justifyContent: "space-evenly",
  padding: "0.5rem 0rem",
});

export const BottomGridMiddleContainerField = () => ({
  padding: "1rem 0rem",
});

export const HomeBoundWidth = () => ({
  maxWidth: "8rem",
});

export const CareGiverWidth = () => ({
  maxWidth: "15rem",
});

export const FacilityDateWidth = () => ({
  minWidth: "7.5rem",
});

export const DischargeDateWidth = () => ({
  minWidth: "6.5rem",
});

export const RemainingChar = () => ({
  padding: "0.5rem 1rem 0rem 1rem ",
});

export const AuthStatusWidth = () => ({
  maxWidth: "11.5rem",
});

export const HomeBoundDateWidth = () => ({
  width: "12rem",
});

export const CareGiverGrid = () => ({
  padding: "1.5rem 2rem",
});

export const CareGiverInfoGrid = () => ({
  padding: "1rem 2rem",
});

export const AddressGrid = () => ({
  background: others.otherColors[33],
  boxShadow: `0.0487rem 0.15rem 0.78rem ${colors.dropShadow[4]}`,
  marginBottom: "0.8rem",
  opacity: 1,
  padding: "0.5rem 0 0 1rem",
  borderRadius: "1.2rem",
});

export const MemberAddressBox = () => ({
  background: others.SchedulerColors[1],
  padding: "0.7rem",
  marginTop: "1rem",
  marginRight: "1rem",
  borderRadius: "0.5rem",
});

export const AddressDetailHeader = () => ({
  display: "flex",
  marginLeft: "0.5rem",
});

export const ServiceAddressGrid = () => ({
  margin: "1rem 0 1rem 1rem",
});
