import { Box, Button, Modal, Typography } from "@mui/material";
import { CgDanger } from "react-icons/cg";
import { isEmpty } from "lodash";

import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  flexAlignCentre,
  justifyContentLeft,
} from "../../../../../styles/mui/styles/display";
import { colors } from "../../../../../styles/colors";
import {
  StaffingAutomationModalClose,
  StaffingAutomationModalContainer,
} from "../styles/StaffingAutomationModal";

export type ModalActionType = "open" | "close";

type StaffingAutomationModalProps = {
  open: boolean;
  title: string;
  details: string;
  message: string[];
  handleModal: (action: ModalActionType) => void;
};

const StaffingAutomationModal = (props: StaffingAutomationModalProps) => {
  const { open, title, message, handleModal } = props;

  const textField = (message: string, index: number) => {
    return (
      <Typography
        key={index}
        fontWeight={fontWeight.Weight[6]}
        variant="h6"
        component="h2"
        color={colors.red[100]}
      >
        {message}
      </Typography>
    );
  };

  return (
    <Modal open={open}>
      <Box sx={StaffingAutomationModalContainer}>
        <Box padding="1rem 0rem">
          <Typography
            variant="h6"
            component="h2"
            fontWeight={fontWeight.Weight[6]}
            textTransform={"capitalize"}
          >
            {title}
          </Typography>
        </Box>
        <Box padding="0.5rem 0rem">
          <Box padding="0.5rem 0rem" sx={[flexAlignCentre, justifyContentLeft]}>
            <Box pr="0.5rem" mt="0.2rem">
              <CgDanger />
            </Box>
            <Box>
              {!isEmpty(message)
                ? message.map((field: string, index: number) => {
                    return textField(field, index);
                  })
                : textField("Error Occured ...!", 0)}
            </Box>
          </Box>
        </Box>
        <Box pt="1rem">
          <Button
            variant="contained"
            sx={StaffingAutomationModalClose}
            onClick={() => handleModal("close")}
          >
            CLOSE
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default StaffingAutomationModal;
