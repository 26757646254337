import { Dispatch, ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import {
  AuthorizationHistoryDispatchTypes,
  EligibiltyHistoryDispatchTypes,
} from "../models/DispatchTypes/AuthorizationHistory";
import {
  AuthorizationHistoryActionDispatchTypes,
  EligibilityHistoryActionDispatchTypes,
} from "../constants/AuthorizationHistory";
import {
  GetAuthorizationHistory,
  GetEligibilityHistory,
} from "../services/AuthorizationHistory";
import { AuthAndEligibilityHistoryPayload } from "../models/AuthorizationHistory";
import { configUrlForReferral } from "../constants/Config";

export const getAuthorizationHistory: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<AuthorizationHistoryDispatchTypes>
  >
> = (payload: AuthAndEligibilityHistoryPayload) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AuthorizationHistoryActionDispatchTypes.AUTHORIZATION_HISTORY_INVOKE,
    });
    const response = await GetAuthorizationHistory(
      configUrlForReferral,
      payload
    );

    dispatch({
      response,
      type: AuthorizationHistoryActionDispatchTypes.AUTHORIZATION_HISTORY_SUCCESS,
    });
  };
};

export const getEligibilityHistory: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<EligibiltyHistoryDispatchTypes>
  >
> = (payload: AuthAndEligibilityHistoryPayload) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EligibilityHistoryActionDispatchTypes.ELIGIBILITY_HISTORY_INVOKE,
    });
    const response = await GetEligibilityHistory(configUrlForReferral, payload);

    dispatch({
      response,
      type: EligibilityHistoryActionDispatchTypes.ELIGIBILITY_HISTORY_SUCCESS,
    });
  };
};

export const authorizationHistoryReset: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    Action<AuthorizationHistoryDispatchTypes>
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AuthorizationHistoryActionDispatchTypes.AUTHORIZATION_HISTORY_RESET,
    });
  };
};
