import { Pagination, Button } from "@mui/material";
import { ChangeEvent } from "react";

import { ItemPagination, PaginationButton } from "../styles/style";

type PaginationProps = {
  count: number;
  page: number;
  handlePageChange: (e: ChangeEvent<unknown>, page: number) => void;
};

const PaginationItem = ({ count, page, handlePageChange }: PaginationProps) => {
  const handleBack = (e: ChangeEvent<unknown>) => {
    handlePageChange(e, page - 1);
  };

  const handleNext = (e: ChangeEvent<unknown>) => {
    if (page <= count) {
      handlePageChange(e, page + 1);
    }
  };

  return (
    <ItemPagination>
      <Button
        onClick={handleBack}
        disabled={page === 1}
        sx={PaginationButton(page === 1)}
      >
        BACK
      </Button>
      <Pagination
        count={count}
        size={"small"}
        page={page}
        onChange={handlePageChange}
        hidePrevButton={page === 1}
        hideNextButton={page === count}
      />
      <Button
        onClick={handleNext}
        disabled={page === count}
        sx={PaginationButton(page === count)}
      >
        NEXT
      </Button>
    </ItemPagination>
  );
};

export default PaginationItem;
