import {
  AutocompleteInputChangeReason,
  Box,
  FormHelperText,
  Grid,
  Typography,
  debounce,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";

import { PhysicianFormInputs } from "../../../../routes/ReferralIntake/components/PhysicianInputForm";
import {
  TableDropdown,
  getTableBody,
  getTableHead,
} from "../../../../../../constants/AllPatientRecord";
import VirtualizeAutocomplete from "../../../../../../components/formComponents/AutocompleteList";
import { IconFaUser, PhysicianInfoSave } from "../style";
import { PhysicianRecordsResponse } from "../../../../../../models/Api/PhysicianDetails";
import { PhysicianDetails } from "../../../../../../models/PhysicianDetails";
import { getFormattedPhoneNo, isNil } from "../../../../../../utils";
import { AutocompleteUI } from "../../../../routes/ReferralIntake/styles/OrderingPhysician";
import {
  PhysicianTypeID,
  physicianInitialData,
} from "../../../../../../constants/PhysicianDetails";
import fontWeight from "../../../../../../styles/mui/fontWeight";
import colors from "../../../../../../styles/colors/colors";
import { SaveButton } from ".";
import { rules } from "../../../../../../utils/validation/Validation";

export interface PropsFromState {
  control: Control<any, any>;
  reset: UseFormReset<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  inputFieldNames: any;
  physicianAutocompleteName: string;
  physicianRecords: PhysicianRecordsResponse;
  handleSave: any;
}
export interface PropsFromDispatch {
  getPhysicianRecords: (value: string) => void;
  resetPhysicianRecords: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const PhysicianInfo: React.FC<AllProps> = ({
  control,
  reset,
  getValues,
  setValue,
  inputFieldNames,
  physicianAutocompleteName,
  physicianRecords,
  resetPhysicianRecords,
  getPhysicianRecords,
  handleSave,
}: AllProps) => {
  const { response, loading } = physicianRecords;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (response && response.length != 0 && response[0].physicianName !== "") {
      setPhysicianListResp([...response]);
    } else {
      setPhysicianListResp(() => []);
    }
  }, [response]);

  const [PhysicianListResp, setPhysicianListResp] = useState<
    PhysicianDetails[]
  >([]);

  const choosePrimaryPhysician = (option: PhysicianDetails) => {
    if (!isNil(option)) {
      const {
        physicianName,
        physicianNpiId,
        taxId,
        city,
        physicianState,
        zipCode,
        streetName1,
        streetName2,
        physicianId,
        fax,
        county,
        phoneExt,
        physicianUid,
        phoneNo,
      } = option;
      reset({
        ...getValues(),
        primaryPhysician: {
          ...getValues("primaryPhysician"),
          physicianId: physicianId,
          physicianName: physicianName,
          physicianNpiId: physicianNpiId,
          taxId: taxId,
          streetName1: streetName1,
          streetName2: streetName2,
          city: city,
          county: county,
          state: physicianState,
          physicianState: physicianState,
          zipCode: zipCode,
          fax: getFormattedPhoneNo(fax),
          physicianTypeId: PhysicianTypeID.PRIMARY,
          phoneExt: phoneExt,
          physicianUid: physicianUid,
          phoneNo: getFormattedPhoneNo(phoneNo),
        },
      });
    }
  };

  const handleSelect = (option: PhysicianDetails) => {
    choosePrimaryPhysician(option);
    const value = PhysicianListResp.find(
      (physician) => physician.physicianName === option.physicianName
    );
    setValue(physicianAutocompleteName, value?.physicianName || null);
    resetPhysicianRecords();
    setOpen && setOpen(false);
  };

  const debouncedSearch = debounce((event, value, reason) => {
    handlePhysicianName(event, value, reason);
  }, 500);

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    debouncedSearch(event, value, reason);
  };

  const handlePhysicianName = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (!isNil(value) && !isNil(event)) {
      if (value.length <= 2) {
        setPhysicianListResp(() => []);
        setOpen && setOpen(false);
      }
      if (value.trim().length >= 3 && reason === "input") {
        getPhysicianRecords(value.trim());
        setOpen && setOpen(true);
      }
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[4]}
            sx={{ padding: "0.5rem" }}
          >
            Primary Care Physician Name / NPI
          </Typography>
        </Box>
        <Controller
          name={physicianAutocompleteName}
          control={control}
          defaultValue={null}
          rules={rules.physicianDetails}
          render={({ fieldState: { error } }) => {
            return (
              <>
                <VirtualizeAutocomplete
                  options={PhysicianListResp}
                  value={physicianInitialData}
                  handleInputChange={handleInputChange}
                  sx={AutocompleteUI}
                  open={open}
                  handleSelect={handleSelect}
                  tableHead={getTableHead(TableDropdown.PHYSICIAN)}
                  tableBody={getTableBody(TableDropdown.PHYSICIAN)}
                  autocompleteInputIcon={<IconFaUser />}
                  setOpen={setOpen}
                  maxLength={128}
                  width="55rem"
                  loading={loading}
                />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={getValues("isPriorAuthCreated") ? 11 : 12}>
        <PhysicianFormInputs
          control={control}
          reset={reset}
          getValues={getValues}
          setValue={setValue}
          names={inputFieldNames}
          isHeader={true}
        />
      </Grid>
      {getValues("isPriorAuthCreated") && (
        <Grid item xs={1} sx={PhysicianInfoSave}>
          {SaveButton(handleSave)}
        </Grid>
      )}
    </Grid>
  );
};

export default PhysicianInfo;
