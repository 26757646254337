import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { PatientContainerBox } from "../../../styles/style";
import { length, getValue } from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { PatientHeaderLabel } from "../../../../../constants/AllPatientRecord";
import { colors } from "../../../../../styles/colors";
import { PER_PAGE } from "../../../../../constants/FunctionalUtils";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AllPatientRecord,
  PatientRecordSearch,
  PatientSearch,
} from "../../../../../models/Patient";
import {
  EligibiliyBodyCell,
  EligibiliyHeadCell,
  PatientDataTableBody,
  PatientTableCell,
  PatientTableHeadCell,
  RecordListRow,
  TableNameDetails,
  ViewButton,
} from "../styles/style";
import { CursorPointer } from "../../../../../styles/common/style";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import { DateFormats } from "../../../../../constants/Date";
import PaginationItem from "../../../../../components/custom/pagination/components/PaginationItem";

interface Props {
  fieldValues: any;
  getAllPatientRecord: (payload: PatientSearch) => void;
  patientRecord: PatientRecordSearch | null;
  setValue: (name: string, value: number, boolean: any) => void;
}

const SearchRecord = ({
  fieldValues,
  getAllPatientRecord,
  patientRecord,
  setValue,
}: Props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const count = Math.ceil(
    patientRecord && patientRecord.totalRows
      ? Number(patientRecord.totalRows) / PER_PAGE
      : 1 / PER_PAGE
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Param e is mandatory
  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    setValue("pageNo", page, true);
    const payload = {
      contains: getValue(fieldValues, "contains", true),
      dateOfBirth: getValue(fieldValues, "dateOfBirth", null),
      authorizationNumber: getValue(fieldValues, "authorizationNumber", null),
      firstName: getValue(fieldValues, "firstName", ""),
      lastName: getValue(fieldValues, "lastName", ""),
      pageNo: page,
      pageSize: getValue(fieldValues, "pageSize", PER_PAGE),
    };
    getAllPatientRecord(payload);
  };

  const handleView = (patientId: number) => {
    navigate(
      ModuleLinks(PatientRecordsModule.PATIENT_DETAILS_FORM, {
        patientId: patientId,
      })
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <PatientContainerBox m={"0 1rem"}>
          <TableContainer
            component={Paper}
            sx={{ p: "1.2rem 1rem 1rem 1.2rem" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {PatientHeaderLabel.map((tablecell, index) => {
                    return (
                      <TableCell
                        key={index}
                        sx={
                          tablecell.label == "ELIGIBILITY DATES"
                            ? EligibiliyHeadCell
                            : PatientTableHeadCell
                        }
                        width={tablecell.width}
                      >
                        <Typography
                          variant="subtitle2"
                          fontWeight={fontWeight.Weight[3]}
                          color={colors.black[2]}
                        >
                          {tablecell.label}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody sx={PatientDataTableBody}>
                {patientRecord &&
                patientRecord.patientResponse &&
                length(patientRecord.patientResponse) &&
                patientRecord.patientResponse[0].patientId != 0 ? (
                  patientRecord.patientResponse.map(
                    (row: AllPatientRecord, rowIndex: number) => {
                      return (
                        <TableRow key={rowIndex} sx={RecordListRow}>
                          <TableCell
                            sx={TableNameDetails(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Typography
                              variant="h4"
                              fontWeight={fontWeight.Weight[5]}
                              color={colors.fonts[18]}
                              sx={CursorPointer}
                              onClick={() => handleView(row.patientId)}
                            >
                              {row.fullName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={PatientTableCell(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={fontWeight.Weight[5]}
                              color={colors.fonts[1]}
                            >
                              {formatDate(
                                row.dateOfBirth,
                                DateFormats.MM_DD_YYYY
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={PatientTableCell(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={fontWeight.Weight[4]}
                              color={colors.fonts[1]}
                            >
                              {row.subscriberId}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={PatientTableCell(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={fontWeight.Weight[5]}
                              color={colors.fonts[1]}
                            >
                              {row.medicareBeneficiaryIdentifier}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={EligibiliyBodyCell(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Typography
                              variant="subtitle1"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[1]}
                            >
                              {row.eligibilityDates}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={PatientTableCell(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[1]}
                            >
                              {row.streetName1}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={PatientTableCell(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[1]}
                            >
                              {row.city}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={PatientTableCell(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Typography
                              variant="h6"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[1]}
                            >
                              {row.patientState}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={PatientTableCell(
                              patientRecord.patientResponse.length - 1 ===
                                rowIndex
                            )}
                          >
                            <Button
                              variant="contained"
                              sx={ViewButton}
                              onClick={() => handleView(row.patientId)}
                            >
                              VIEW
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={9}>
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </PatientContainerBox>
      </Grid>
      {patientRecord &&
        patientRecord.patientResponse &&
        length(patientRecord.patientResponse) &&
        patientRecord.patientResponse[0].patientId != 0 && (
          <Grid item xs={12} m={"0.5rem"}>
            <PaginationItem
              count={count}
              page={page}
              handlePageChange={handlePageChange}
            />
          </Grid>
        )}
    </Grid>
  );
};

export default SearchRecord;
