import { FaUserMd } from "react-icons/fa";
import { styled } from "@mui/material";
import { StyledComponent } from "@emotion/styled";

import { colors } from "../../../../../styles/colors";

const Header = {
  boxSizing: "border-box",
  paddingBottom: "0.5rem",
};

const Title = {
  boxSizing: "border-box",
  padding: "0.4rem",
  textAlign: "left",
};
const Subtitle = {
  boxSizing: "border-box",
  padding: "0.4rem",
  textAlign: "left",
};
const HeaderBorder = {
  borderBottom: `1px solid ${colors.borders[5]}`,
  paddingTop: "1rem",
};

const HeaderBorderPrimary = {
  borderBottom: `1px solid ${colors.borders[5]}`,
};

const Pad = {
  padding: "1rem",
};

const Body = {
  padding: "0 1.25rem 0.8rem 0.4rem",
};

const FormInputBox = {
  padding: "1rem 3rem 1rem 0",
};

const AutocompleteContainer = {
  padding: "1rem 1rem 1rem 0",
};

const DeleteIcon = {
  paddingTop: "2rem",
};

const AutocompleteUI = () => ({
  width: "25rem",
  position: "relative",
});

const AutoCompletePaper = () => ({
  "&.MuiAutocomplete-paper": {
    width: "42rem",
  },
});

const AutocompleteTableHolder = () => ({
  width: "50rem",
  left: "-2rem",
  top: "1rem",
  backgroundColor: `${colors.white[1]} !important`, //TBD
  borderRadius: "4px",
});
const TableHeadMargin = (isHeader: boolean) => ({
  marginTop: isHeader ? "1rem" : "auto",
});

const SearchedResultTableBody = () => ({
  padding: ".5rem 0 .4rem 1.5rem",
  textTransform: "capitalize",
  border: 0,
  borderBottom: `0.05rem solid ${colors.borders[1]}`,
  "&.MuiTableCell-root:first-of-type": {
    paddingLeft: "1.5rem",
  },
});
const SwitchGrid = (val: boolean) => ({
  pointerEvents: !val ? null : "none",
  opacity: !val ? null : "0.6",
});

const MainContainer = {
  color: colors.black[1],
  padding: "0.4rem 1rem 0 2rem",
};

const IconFaUser: StyledComponent<any> = styled(FaUserMd)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "left",
  color: colors.primary.main,
  margin: "0 0.6rem 0.23rem 0",
}));

export const RequiredSymbol = () => ({
  marginLeft: "0.3rem",
  marginTop: "0.1rem",
});

export {
  Header,
  Title,
  Subtitle,
  HeaderBorder,
  HeaderBorderPrimary,
  Pad,
  Body,
  FormInputBox,
  AutocompleteUI,
  AutocompleteTableHolder,
  TableHeadMargin,
  SearchedResultTableBody,
  DeleteIcon,
  SwitchGrid,
  MainContainer,
  IconFaUser,
  AutoCompletePaper,
  AutocompleteContainer,
};
