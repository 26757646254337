import {
  ReadOnlyFieldContainer,
  ReadOnlyFieldLabel,
  ReadOnlyFieldValue,
} from "../../../../../components/formComponents/styles/style";

export const StaffingUrlContainer = ReadOnlyFieldContainer;
export const StaffingUrlLabel = ReadOnlyFieldLabel;
export const StaffingUrlValue = ReadOnlyFieldValue;

export const StaffingAutomationModalContainer = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "20.12rem",
  minHeight: "auto",
  bgcolor: "background.paper",
  padding: "1rem 2rem",
  borderRadius: "1.5rem",
};

export const StaffingAutomationModalClose = {
  position: "absolute",
  bottom: 11,
  right: 5,
};
