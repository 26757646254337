import { ThunkAction } from "redux-thunk";
import { Dispatch, ActionCreator } from "redux";
import { isNil } from "lodash";

import { ApplicationState } from "../store/ApplicationState";
import {
  AuthorizationDetailTabsActionDispatchTypes,
  PutAuthorizationDetailTabActionDispatchTypes,
  ReauthCompleteActionDispatchTypes,
  ReAuthTimelineActionDispatchTypes,
  ResumeIntakeActionDispatchTypes,
  ServiceProviderDetailsActionDispatchTypes,
  PostInitiateFaxActionDispatchTypes,
} from "../constants/Authorization";
import {
  GetAuthorizationDetailTabs,
  GetDisciplines,
  GetReauthCompleteInfo,
  GetReAuthTimelineData,
  GetReferralDisciplines,
  GetResumeIntakeInformation,
  GetServiceProviderDetails,
  PutAuthorizationDetailTabs,
  PutDisciplines,
  PutPhysicianReview,
  PutServiceProviderDetails,
  PostInitiateFax,
  DeleteServiceProviderDetails,
} from "../services/AuthorizationDetails";
import {
  AuthorizationDetailTabsDispatchTypes,
  PutAuthorizationDetailTabDispatchTypes,
  ReauthCompleteDispatchTypes,
  ReAuthTimelineDispatchTypes,
  ResumeIntakeDispatchTypes,
  ServiceProviderDetailsDispatchTypes,
  InitiateFaxDispatchTypes,
} from "../models/DispatchTypes/Authorization";
import {
  DisciplineActionDispatchTypes,
  ReferralDisciplineActionDispatchTypes,
  UpdateDisciplineActionDispatchTypes,
} from "../constants/Discipline";
import {
  DisciplineDispatchTypes,
  ReferralDisciplineDispatchTypes,
  UpdateDisciplineDispatchTypes,
} from "../models/DispatchTypes/Discipline";
import { AuthorizationDetailTabs } from "../models/Authorization";
import { ServiceProviderData } from "../models/Authorization";
import {
  AuthFollowingPhysicianDetailsDispatchTypes,
  AuthOrderingPhysicianDetailsDispatchTypes,
} from "../models/DispatchTypes/PhysicianDetails";
import {
  AuthFollowingPhysicianDetailsActionDispatchType,
  AuthOrderingPhysicianDetailsActionDispatchType,
} from "../constants/PhysicianDetails";
import {
  GetAuthFollowingPhysicianDetails,
  GetAuthOrderingPhysicianDetails,
  PutAuthFollowingPhysicianDetails,
  PutAuthOrderingPhysicianDetails,
} from "../services/PhysicianDetails";
import { configUrlForReferral } from "../constants/Config";
import { ReferralDiscipline } from "../models/Disciplines";
import {
  MessageType,
  ReferralResponseMessages,
} from "../constants/AllPatientRecord";
import { AuthPhysicianDetails } from "../models/PhysicianDetails";
import { GetServiceStatusByType } from "../services/Service";
import { ReferralServiceStatusActionDispatchTypes } from "../constants/Master";
import { ReferralServiceStatusDispatchTypes } from "../models/DispatchTypes/Master";
import SnackbarUtils from "../utils/SnackbarProvider";
import { UpdateDisciplineResponse } from "../models/Api/Discipline";
import { ResponseMessages } from "../constants/ToastMessage";
import { MessageType as ResponseMesageType } from "../constants/ToastMessage";
export const getReAuthTimeline: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReAuthTimelineDispatchTypes
  >
> = (referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReAuthTimelineActionDispatchTypes.REAUTH_TIMELINE_INVOKE,
    });
    const response = await GetReAuthTimelineData(
      configUrlForReferral,
      referralId
    );
    dispatch({
      response,
      type: ReAuthTimelineActionDispatchTypes.REAUTH_TIMELINE_SUCCESS,
    });
  };
};

export const getServiceProviderDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ServiceProviderDetailsDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_INVOKE,
    });

    const response = await GetServiceProviderDetails(
      configUrlForReferral,
      referralId
    );

    dispatch({
      response,
      type: ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_SUCCESS,
    });
  };
};

export const putServiceProviderDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ServiceProviderDetailsDispatchTypes
  >
> = (referralId: number, payLoad: ServiceProviderData) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_POST_INVOKE,
    });

    const response = await PutServiceProviderDetails(
      configUrlForReferral,
      referralId,
      payLoad
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_POST_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_POST_SUCCESS,
          });
          break;
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.SAVE_SERVICE_PROVIDER_DATA)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const deleteServiceProvider: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ServiceProviderDetailsDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_INVOKE,
    });

    const response = await DeleteServiceProviderDetails(
      configUrlForReferral,
      referralId
    );
    if (isNil(response)) {
      dispatch({
        response: true,
        type: ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_ERROR,
      });
      SnackbarUtils.error(ResponseMessages(ResponseMesageType.REQUEST_FAILED));
    } else {
      dispatch({
        response: response,
        type: ServiceProviderDetailsActionDispatchTypes.DELETE_SERVICE_PROVIDER_SUCCESS,
      });
      SnackbarUtils.success(ResponseMessages(ResponseMesageType.DELETE_SUCCESS));
    }
  }
};

export const getDisciplines: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DisciplineDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DisciplineActionDispatchTypes.DISCIPLINE_INVOKE,
    });

    const response = await GetDisciplines(configUrlForReferral, referralId);

    dispatch({
      response,
      type: DisciplineActionDispatchTypes.DISCIPLINE_SUCCESS,
    });
  };
};

export const getReferralDisciplines: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReferralDisciplineDispatchTypes
  >
> = (referralDisciplineId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralDisciplineActionDispatchTypes.REFERRAL_DISCIPLINE_INVOKE,
    });

    const response = await GetReferralDisciplines(
      configUrlForReferral,
      referralDisciplineId
    );

    dispatch({
      response,
      type: ReferralDisciplineActionDispatchTypes.REFERRAL_DISCIPLINE_SUCCESS,
    });
  };
};

export const updateDisciplinesData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UpdateDisciplineDispatchTypes
  >
> = (authDisciplineId: number, payload: UpdateDisciplineResponse) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_INVOKE,
    });

    const response = await PutDisciplines(
      configUrlForReferral,
      authDisciplineId,
      payload
    );
    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            error: response.data,
            type: UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.UPDATE_DISCIPLINE_DATA)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const updatePhysicianReviewData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    UpdateDisciplineDispatchTypes
  >
> = (authDisciplineId: number, payload: ReferralDiscipline) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_INVOKE,
    });

    const response = await PutPhysicianReview(
      configUrlForReferral,
      authDisciplineId,
      payload
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.UPDATE_DISCIPLINE_DATA)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const getAuthOrderingPhysicianDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AuthOrderingPhysicianDetailsDispatchTypes
  >
> = (referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AuthOrderingPhysicianDetailsActionDispatchType.GET_ORDERING_PHYSICIAN_DETAILS_INVOKE,
    });

    const response = await GetAuthOrderingPhysicianDetails(
      configUrlForReferral,
      referralId
    );

    dispatch({
      response,
      type: AuthOrderingPhysicianDetailsActionDispatchType.GET_ORDERING_PHYSICIAN_DETAILS_SUCCESS,
    });
  };
};

export const putAuthOrderingPhysicianDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AuthOrderingPhysicianDetailsDispatchTypes
  >
> = (referralId: string, payload: AuthPhysicianDetails) => {
  return async (dispatch: Dispatch) => {
    await putOrderingPhysicianDetails(referralId, payload, dispatch);
  };
};

export const putOrderingPhysicianDetails = async (
  referralId: string,
  payload: AuthPhysicianDetails,
  dispatch: Dispatch
) => {
  dispatch({
    type: AuthOrderingPhysicianDetailsActionDispatchType.PUT_ORDERING_PHYSICIAN_DETAILS_INVOKE,
  });

  const response = await PutAuthOrderingPhysicianDetails(
    configUrlForReferral,
    referralId,
    payload
  );

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: AuthOrderingPhysicianDetailsActionDispatchType.PUT_ORDERING_PHYSICIAN_DETAILS_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: AuthOrderingPhysicianDetailsActionDispatchType.PUT_ORDERING_PHYSICIAN_DETAILS_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(
            MessageType.UPDATE_ORDERING_PHYSICIAN_DETAILS
          )
        );
        break;
      default:
        break;
    }
  }
};

export const getAuthFollowingPhysicianDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AuthFollowingPhysicianDetailsDispatchTypes
  >
> = (referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AuthFollowingPhysicianDetailsActionDispatchType.GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE,
    });

    const response = await GetAuthFollowingPhysicianDetails(
      configUrlForReferral,
      referralId
    );

    dispatch({
      response,
      type: AuthFollowingPhysicianDetailsActionDispatchType.GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS,
    });
  };
};

export const putAuthFollowingPhysicianDetails: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AuthFollowingPhysicianDetailsDispatchTypes
  >
> = (referralId: string, payload: AuthPhysicianDetails) => {
  return async (dispatch: Dispatch) => {
    await putFollowingPhysicianDetails(referralId, payload, dispatch);
  };
};

export const putFollowingPhysicianDetails = async (
  referralId: string,
  payload: AuthPhysicianDetails,
  dispatch: Dispatch
) => {
  dispatch({
    type: AuthFollowingPhysicianDetailsActionDispatchType.PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE,
  });

  const response = await PutAuthFollowingPhysicianDetails(
    configUrlForReferral,
    referralId,
    payload
  );

  if (response && response.status) {
    switch (response.status) {
      case 422:
      case 400:
        dispatch({
          response,
          type: AuthFollowingPhysicianDetailsActionDispatchType.PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR,
        });
        break;
      case 200:
      case 201:
        dispatch({
          response: response.data,
          type: AuthFollowingPhysicianDetailsActionDispatchType.PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS,
        });
        SnackbarUtils.success(
          ReferralResponseMessages(
            MessageType.UPDATE_FOLLOWING_PHYSICIAN_DETAILS
          )
        );
        break;
      default:
        break;
    }
  }
};

export const putAuthorizationDetailTab: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    PutAuthorizationDetailTabDispatchTypes
  >
> = (data: AuthorizationDetailTabs, referralId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_INVOKE,
    });

    const response = await PutAuthorizationDetailTabs(
      configUrlForReferral,
      referralId,
      data
    );

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            error: response.data,
            type: PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: PutAuthorizationDetailTabActionDispatchTypes.PUT_AUTHORIZATION_DETAIL_TAB_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.UPDATE_REFERRAL_DATA)
          );
          break;
        default:
          break;
      }
    }
  };
};

export const getAuthorizationDetailTabs: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AuthorizationDetailTabsDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AuthorizationDetailTabsActionDispatchTypes.AUTH_DETAIL_TABS_INVOKE,
    });

    const response = await GetAuthorizationDetailTabs(
      configUrlForReferral,
      referralId
    );

    dispatch({
      response,
      type: AuthorizationDetailTabsActionDispatchTypes.AUTH_DETAIL_TABS_SUCCESS,
    });
  };
};

export const getServiceStatusByType: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReferralServiceStatusDispatchTypes
  >
> = (value: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralServiceStatusActionDispatchTypes.REFERRAL_SERVICE_STATUS_INVOKE,
    });

    const response = await GetServiceStatusByType(value, configUrlForReferral);
    dispatch({
      response: response,
      type: ReferralServiceStatusActionDispatchTypes.REFERRAL_SERVICE_STATUS_SUCCESS,
    });
  };
};

export const getResumeIntakeInformation: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ResumeIntakeDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ResumeIntakeActionDispatchTypes.RESUME_INTAKE_INVOKE,
    });

    const response = await GetResumeIntakeInformation(
      configUrlForReferral,
      referralId
    );
    dispatch({
      response: response,
      type: ResumeIntakeActionDispatchTypes.RESUME_INTAKE_SUCCESS,
    });
  };
};

export const getReauthComplete: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReauthCompleteDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReauthCompleteActionDispatchTypes.REAUTH_COMPLETE_INVOKE,
    });

    const response = await GetReauthCompleteInfo(
      configUrlForReferral,
      referralId
    );
    dispatch({
      response: response,
      type: ReauthCompleteActionDispatchTypes.REAUTH_COMPLETE_SUCCESS,
    });
  };
};

export const SaveInitiateFax: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    InitiateFaxDispatchTypes
  >
> = (payload: FormData) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_INVOKE,
    });

    const response = await PostInitiateFax(configUrlForReferral, payload);

    if (response && response.status) {
      switch (response.status) {
        case 422:
        case 400:
          dispatch({
            response,
            type: PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_ERROR,
          });
          break;
        case 200:
        case 201:
          dispatch({
            response: response.data,
            type: PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_SUCCESS,
          });
          SnackbarUtils.success(
            ReferralResponseMessages(MessageType.FAX_SENT_MESSAGE)
          );
          break;
        default:
          break;
      }
    }
  };
};
