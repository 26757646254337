import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { colors } from "../../../../styles/colors";
import fontWeight from "../../../../styles/mui/fontWeight";

export const ItemPagination = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  alignItems: "end",
}));

export const PaginationButton = (disabled: boolean) => ({
  width: "0.5rem",
  textDecoration: disabled ? "none" : "underline",
  color: colors.fonts[4],
  fontWeight: fontWeight.Weight[5],
  paddingTop: "0.2rem",
  fontSize: "0.7rem",
});