import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Survey } from "survey-react-ui";
import { Model, setLicenseKey } from "survey-core";
import { Box, Grid } from "@mui/material";
import { isNil } from "lodash";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";

import "../styles/notesStyles.css";
import { ClickableEvents, NotePreview, SurveyStyling } from "../styles/styles";
import { NotesReviewData } from "../../constants/Notes";
import SnackbarUtils from "../../utils/SnackbarProvider";
import { MessageType, ResponseMessages } from "../../constants/ToastMessage";
import { surveyJsKey } from "../../constants/Config";

export interface SurveyType {
  model: any;
  data: any;
  onComplete?: () => void;
  style: any;
  isSubmit: boolean;
  editFlag: boolean;
  setEditNotesPreview?: Dispatch<SetStateAction<boolean>>;
  setNotesData?: Dispatch<SetStateAction<any>>;
}

const NotesPreview: React.FC<any> = ({
  model,
  data,
  onComplete,
  editFlag,
  setEditNotesPreview,
  setNotesData,
}: SurveyType) => {
  const surveyOptions = {
    showCompleteButton: false,
  };
  if (!isNil(model)) {
    try {
      setLicenseKey(surveyJsKey);
      const survey = new Model(model);
      survey.applyTheme(SurveyStyling);
      survey.mode = editFlag === false ? NotesReviewData.NOTES_MODE : "";
      survey.addNavigationItem({
        id: "sv-nav-Cancel-page",
        title: "Cancel",
        visible: true,
        visibleIndex: 19,
        action: () => {
          survey.currentPage.questions.forEach((question: any) => {
            question.value = undefined;
          });
          setEditNotesPreview && setEditNotesPreview(false);
          setNotesData && setNotesData("");
        },
        css: "nav-button",
        innerCss: "sd-btn nav-input",
      });

      useEffect(() => {
        const element = document.getElementById("sv-nav-complete");
        if (element && !editFlag) {
          element.style.pointerEvents = "none";
          return;
        } else if (element) {
          element.style.pointerEvents = "auto";
        }
      }, [model, editFlag]);

      return (
        <Grid container sx={NotePreview(editFlag)}>
          <Box sx={ClickableEvents} display="contents">
            <Survey
              model={survey}
              data={data}
              onComplete={onComplete}
              options={surveyOptions}
            />
          </Box>
        </Grid>
      );
    } catch (error: any) {
      SnackbarUtils.error(ResponseMessages(MessageType.NOTES_PREVIEW_ERROR));
    }
  }

  return <Grid> </Grid>;
};

export default NotesPreview;
