import { Reducer } from "redux";

import { ReferralServiceStatusActionDispatchTypes } from "../constants/Master";
import { ReferralServiceStatusResponse } from "../models/Api/Master";
import { ReferralServiceStatusDispatchTypes } from "../models/DispatchTypes/Master";

export const initialState: ReferralServiceStatusResponse = {
  loading: false,
  response: [
    {
      serviceStatusId: -1,
      serviceStatus: "",
      haveStatusReason: false,
    },
  ],
};

const ReferralServiceReducer: Reducer<
  ReferralServiceStatusResponse,
  ReferralServiceStatusDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ReferralServiceStatusActionDispatchTypes.REFERRAL_SERVICE_STATUS_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReferralServiceStatusActionDispatchTypes.REFERRAL_SERVICE_STATUS_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ReferralServiceReducer;
