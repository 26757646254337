import { isEmpty } from "lodash";

import { getValue } from ".";
import { EligibilityRecord } from "../models/Master";
import {
  CheckEligibility,
  PatientAddressTypeId,
  PatientMailingAddress,
  PatientResidentialAddress,
} from "../models/Patient";
//Added this function to get combind data of check eligibility data and health plan datafron benefitPlanId
export const eligibilityData = (
  eligibilityRecords: EligibilityRecord,
  checkEligibility: any
) => {
  const data = {
    ...eligibilityRecords,
    subscriberId: getValue(
      checkEligibility,
      "additionalNotes.scrubbedSubscriberID"
    ),
    product: getValue(eligibilityRecords, "product", ""),
    mbi: getValue(checkEligibility, "supplementalInformation.info[0].mbi"),
    effectiveDate: getValue(
      checkEligibility,
      "supplementalInformation.info[0].healthCoverage.beginDate"
    ),
    effectiveThrough: getValue(
      checkEligibility,
      "supplementalInformation.info[0].healthCoverage.endDate"
    ),
    benefitPlanUid: getValue(
      checkEligibility,
      "additionalNotes.benefitPlanUid"
    ),
    healthPlanUid: getValue(checkEligibility, "additionalNotes.healthPlanUid"),
    apiEligibilityId: getValue(
      checkEligibility,
      "additionalNotes.apiRequestId",
      null
    ),
    eligibilityStatus: getValue(
      checkEligibility,
      "supplementalInformation.info[0].memberFound"
    ),
    cellPhone: getValue(
      checkEligibility,
      "supplementalInformation.info[0].mobilePhone"
    ),
    languagePreferenceKind: getValue(
      checkEligibility,
      "supplementalInformation.info[0].userDefined1"
    ),
    languagePreferenceValue: getValue(
      checkEligibility,
      "supplementalInformation.info[0].userDefined1"
    ),
    patientResidentialAddress: {
      ...getPatientResidentialAddress(checkEligibility),
    },
    patientMailingAddress: {
      ...getPatientMailingAddress(checkEligibility),
    },
    alternatePhone: getValue(
      checkEligibility,
      "supplementalInformation.info[0].alternatePhone"
    ),
  };
  return data;
};

export const getPatientResidentialAddress = (
  checkEligibilityResponse: CheckEligibility
): PatientResidentialAddress => {
  const ResidentialAddress = getValue(
    checkEligibilityResponse,
    "supplementalInformation.info[0].address"
  );

  const MailingAddress = getValue(
    checkEligibilityResponse,
    "record.subscriberAddress"
  );

  return ResidentialAddress &&
    isEmpty(ResidentialAddress.addressLine) &&
    isEmpty(ResidentialAddress.addressLine2) &&
    isEmpty(ResidentialAddress.city) &&
    isEmpty(ResidentialAddress.zipCode) &&
    isEmpty(ResidentialAddress.state) &&
    isEmpty(ResidentialAddress.county)
    ? {
        addressTypeId: PatientAddressTypeId.RESIDENTIAL,
        streetName1: getValue(MailingAddress, "street", null),
        streetName2: getValue(MailingAddress, "street2", null),
        city: getValue(MailingAddress, "city", null),
        zipCode: getValue(MailingAddress, "zip", null),
        patientState: getValue(MailingAddress, "state", null),
        country: getValue(MailingAddress, "country", null),
        county: getValue(MailingAddress, "county", null),
        latitude: null,
        longitude: null,
      }
    : {
        addressTypeId: PatientAddressTypeId.RESIDENTIAL,
        streetName1: getValue(ResidentialAddress, "addressLine", null),
        streetName2: getValue(ResidentialAddress, "addressLine2", null),
        city: getValue(ResidentialAddress, "city", null),
        zipCode: getValue(ResidentialAddress, "zipCode", null),
        patientState: getValue(ResidentialAddress, "state", null),
        country: getValue(ResidentialAddress, "countryCode", null),
        county: getValue(ResidentialAddress, "county", null),
        latitude: null,
        longitude: null,
      };
};
export const getPatientMailingAddress = (
  checkEligibilityResponse: CheckEligibility
): PatientMailingAddress => {
  const ResidentialAddress = getValue(
    checkEligibilityResponse,
    "supplementalInformation.info[0].address"
  );

  const MailingAddress = getValue(
    checkEligibilityResponse,
    "record.subscriberAddress"
  );

  return MailingAddress &&
    isEmpty(MailingAddress.street) &&
    isEmpty(MailingAddress.city) &&
    isEmpty(MailingAddress.zip) &&
    isEmpty(MailingAddress.state) &&
    isEmpty(MailingAddress.county)
    ? {
        addressTypeId: PatientAddressTypeId.MAILING,
        streetName1: getValue(ResidentialAddress, "addressLine", null),
        streetName2: getValue(ResidentialAddress, "addressLine2", null),
        city: getValue(ResidentialAddress, "city", null),
        zipCode: getValue(ResidentialAddress, "zipCode", null),
        patientState: getValue(ResidentialAddress, "state", null),
        country: getValue(ResidentialAddress, "countryCode", null),
        county: getValue(ResidentialAddress, "county", null),
        latitude: null,
        longitude: null,
      }
    : {
        addressTypeId: PatientAddressTypeId.MAILING,
        streetName1: getValue(MailingAddress, "street", ""),
        streetName2: getValue(MailingAddress, "street2", null),
        city: getValue(MailingAddress, "city", null),
        zipCode: getValue(MailingAddress, "zip", null),
        patientState: getValue(MailingAddress, "state", null),
        country: getValue(MailingAddress, "country", null),
        county: getValue(MailingAddress, "county", null),
        latitude: null,
        longitude: null,
      };
};
