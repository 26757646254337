import { googleMapKey } from "../constants/Config";
import { Loader } from "@googlemaps/js-api-loader"

export const initMapScript = () => {
  const loader = new Loader({
    apiKey: googleMapKey,
    version: "weekly",
  });
  return loader.importLibrary("places");
};

export const extractAddress = (place: any) => {
  const address = {
    city: "",
    patientState: "",
    zipCode: "",
    streetName: "",
    country: "",
    county: "",
    streetNumber: "",
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach(
    (component: { types: any; long_name: any }) => {
      const types = component.types;
      const value = component.long_name;
      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("street_number")) {
        address.streetNumber = value;
      }

      if (types.includes("route")) {
        address.streetName = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.patientState = value;
      }

      if (types.includes("administrative_area_level_2")) {
        address.county = value;
      }

      if (types.includes("postal_code")) {
        address.zipCode = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    }
  );

  return address;
};

export const extractPatientAddress = (place: any) => {
  const address = {
    city: null,
    patientState: null,
    zipCode: null,
    streetName1: null,
    streetName2: null,
    county: null,
    streetNumber: null,
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }
  place.address_components.forEach(
    (component: { types: any; long_name: any }) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("street_number")) {
        address.streetNumber = value;
      }

      if (types.includes("route")) {
        address.streetName1 = value;
      }

      if (types.includes("neighborhood")) {
        address.streetName2 = value;
      }

      if (types.includes("administrative_area_level_2")) {
        address.county = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.patientState = value;
      }

      if (types.includes("postal_code")) {
        address.zipCode = value;
      }
    }
  );

  return address;
};
