import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import dayjs from "dayjs";

import CreateReferralHeader from "../../../containers/CreateReferralHeader";
import PatientDetailsForm from "../containers/PatientDetailsForm";
import {
  HealthPlanPayload,
  PatientInfoResponse,
  SavePatientInformation,
} from "../../../../../models/Patient";
import { GetPatientInfo } from "../../../../../constants/PatientInformation";
import CreateReferral from "../containers/CreateReferral";
import SnackbarUtils from "../../../../../utils/SnackbarProvider";
import {
  EligibilityCheckDispatchTypes,
  MessageType,
  ReferralResponseMessages,
} from "../../../../../constants/AllPatientRecord";
import { DateFormats } from "../../../../../constants/Date";
import { CheckEligibilityResponse } from "../../../../../models/Api/AllPatientRecord";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import {
  getHealthPlanValueType,
  getValue,
  SelectGender,
} from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { UserDetailsPayload } from "../../../../../models/User";
import { EligibilityRecordActionDispatchTypes } from "../../../../../constants/Master";
import {
  getPatientMailingAddress,
  getPatientResidentialAddress,
} from "../../../../../utils/Eligibility";
import { matchState } from "../../../../../utils/StateSearch";
import { PatientRecordsAccess } from "../../../../../constants/Permission";
import { defaultPatientFormData } from "../../../../../reducers/SavePatientInformation";
import AddEligibilityDetails from "../containers/AddEligibilityDetails";
import { PageStatus } from "../../../../../constants/Authorization";
import { EligibilityData } from "../../../../../models/EligibilityData";

export interface PropsFromState {
  checkEligibilityData: CheckEligibilityResponse;
  data: PatientInformationViewResponse;
  eligibilityRecord: EligibilityData;
  savePatientInformationLoading: boolean;
  savePatientInformationResponse: PatientInfoResponse;
  user: UserDetailsPayload;
}

export interface PropsFromDispatch {
  authorizationHistoryReset: () => void;
  checkEligibility: (payload: HealthPlanPayload) => void;
  getEligibilityData: (benefitPlanUid: string) => void;
  patientInformationReset: () => void;
  savePatientInformationDetails: (data: FormData) => void;
  resetStates: (actionType: string[]) => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const PatientInfo: React.FC<AllProps> = ({
  authorizationHistoryReset,
  getEligibilityData,
  data,
  eligibilityRecord,
  patientInformationReset,
  savePatientInformationDetails,
  savePatientInformationLoading,
  savePatientInformationResponse,
  resetStates,
}: AllProps) => {
  const [createRecordStatus, setCreateRecordStatus] = useState<string>("");
  const methods = useForm<SavePatientInformation>({
    defaultValues: JSON.parse(JSON.stringify(GetPatientInfo)),
    mode: "all",
  });
  const navigate = useNavigate();
  const {
    response: checkEligibilityResponse,
    loading: checkEligibilityLoading = false,
  } = !isEmpty(eligibilityRecord.checkEligibilityResponse) && eligibilityRecord.checkEligibilityResponse;
  const { healthPlan, isManual } = useParams();
  const isManualFlow = isManual === "1";

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = methods;

  useWatch({
    name: "informationReceived",
    control,
  });

  const values = getValues();

  const onSubmit = (data: SavePatientInformation) => {
    const formData = new FormData();
    // CreatePatient
    formData.append("createPatient.CreatePatient.PatientId", "0");
    formData.append(
      "createPatient.CreatePatient.FirstName",
      data.createPatient.firstName
    );
    formData.append(
      "createPatient.CreatePatient.MiddleName",
      data.createPatient.middleName || ""
    );
    formData.append(
      "createPatient.CreatePatient.LastName",
      data.createPatient.lastName
    );
    formData.append(
      "createPatient.CreatePatient.Gender",
      data.createPatient.gender || ""
    );
    formData.append(
      "createPatient.CreatePatient.DateOfBirth",
      formatDate(
        dayjs(data.createPatient.dateOfBirth).toDate(),
        DateFormats.YYYY_MM_DD
      )
    );
    formData.append("createPatient.CreatePatient.BlobId", "0");

    formData.append(
      "createPatient.CreatePatient.PhoneNo",
      getValue(data, "createPatient.phoneNo", "")
    );

    // not present in checkEligibility response
    formData.append(
      "createPatient.CreatePatient.Extension",
      getValue(data, "createPatient.extNo", "")
    );

    formData.append(
      "createPatient.CreatePatient.CellPhone",
      getValue(data, "createPatient.cellPhone", "")
    );

    formData.append(
      "createPatient.CreatePatient.EmailAddress",
      data.createPatient.emailAddress || ""
    );
    formData.append(
      "createPatient.CreatePatient.LanguagePreferenceKind",
      getValue(data, "createPatient.languagePreferenceKind", "")
    );
    formData.append(
      "createPatient.CreatePatient.LanguagePreferenceValue",
      getValue(data, "createPatient.languagePreferenceValue", "")
    );
    formData.append(
      "createPatient.CreatePatient.IsManual",
      getValue(data, "createPatient.isManual", "")
    );

    // InformationReceived
    formData.append("createPatient.InformationReceived.PatientId", "0");
    formData.append(
      "createPatient.InformationReceived.HealthPlanUid",
      getValue(data, "informationReceived.healthPlanUid", "")
    );
    formData.append(
      "createPatient.InformationReceived.Product",
      getValue(data, "informationReceived.product", "")
    );
    formData.append(
      "createPatient.InformationReceived.BenefitPlanUid",
      getValue(data, "informationReceived.benefitPlanUid", "")
    );
    formData.append(
      "createPatient.InformationReceived.MedicareBeneficiaryIdentifier",
      getValue(data, "informationReceived.medicareBeneficiaryIdentifier", "")
    );
    formData.append(
      "createPatient.InformationReceived.AhcccsId",
      getValue(data, "informationReceived.ahcccsId", "") || ""
    );
    formData.append(
      "createPatient.InformationReceived.EligibilityStatus",
      getValue(data, "informationReceived.eligibilityStatus", "")
    );
    formData.append(
      "createPatient.InformationReceived.AlternatePhone",
      getValue(data, "informationReceived.alternatePhone", "")
    );
    const effectiveDate = getValue(
      data,
      "informationReceived.effectiveDate",
      ""
    );
    formData.append(
      "createPatient.InformationReceived.EffectiveDate",
      formatDate(effectiveDate, DateFormats.YYYY_MM_DD) || ""
    );

    const effectiveThrough = getValue(
      data,
      "informationReceived.effectiveThrough",
      ""
    );
    formData.append(
      "createPatient.InformationReceived.EffectiveThrough",
      formatDate(effectiveThrough, DateFormats.YYYY_MM_DD) || ""
    );

    formData.append(
      "createPatient.InformationReceived.SubscriberId",
      getValue(data, "informationReceived.subscriberId", "")
    );
    formData.append(
      "createPatient.InformationReceived.EligibilityPayerId",
      getValue(data, "informationReceived.eligibilityPayerId", "")
    );
    formData.append(
      "createPatient.InformationReceived.ApiEligibilityId",
      getValue(data, "informationReceived.apiEligibilityId", 0)
    );
    formData.append(
      "createPatient.InformationReceived.EligibilityPayerName",
      getValue(data, "informationReceived.eligibilityPayerName", "")
    );
    formData.append(
      "createPatient.InformationReceived.FullNameCreated",
      getValue(data, "informationReceived.FullNameCreated", "")
    );
    formData.append(
      "createPatient.InformationReceived.IsManual",
      getValue(data, "informationReceived.isManual", "")
    );

    // PatientResidentialAddress
    const residentialStreetName1 =
      getValue(values, "patientResidentialAddress.streetName1", "") || "";
    const residentialStreetName2 =
      getValue(values, "patientResidentialAddress.streetName2", "") || "";
    const residentialCounty =
      getValue(values, "patientResidentialAddress.county", "") || "";
    const residentialCity =
      getValue(values, "patientResidentialAddress.city", "") || "";
    const residentialState = getValue(
      values,
      "patientResidentialAddress.patientState",
      ""
    );
    const residentialZipcode =
      getValue(values, "patientResidentialAddress.zipCode", "") || "";
    const residentialCountry =
      getValue(data, "patientResidentialAddress.country", "") || "";

    formData.append(
      "createPatient.PatientResidentialAddress.StreetName1",
      residentialStreetName1
    );
    formData.append(
      "createPatient.PatientResidentialAddress.StreetName2",
      residentialStreetName2
    );
    formData.append(
      "createPatient.PatientResidentialAddress.County",
      residentialCounty
    );
    formData.append(
      "createPatient.PatientResidentialAddress.City",
      residentialCity
    );
    formData.append(
      "createPatient.PatientResidentialAddress.PatientState",
      !isEmpty(residentialState) ? matchState(residentialState) : ""
    );
    formData.append(
      "createPatient.PatientResidentialAddress.ZipCode",
      residentialZipcode
    );
    formData.append("createPatient.PatientResidentialAddress.Latitude", "");
    formData.append("createPatient.PatientResidentialAddress.Longitude", "");
    formData.append(
      "createPatient.PatientResidentialAddress.AddressTypeId",
      getValue(data, "patientResidentialAddress.addressTypeId", "")
    );
    formData.append(
      "createPatient.PatientResidentialAddress.Country",
      residentialCountry
    );

    if (checkEligibilityResponse) {
      const patientMailingAddress = getPatientMailingAddress(
        checkEligibilityResponse
      );
      if (!isManualFlow) {
        formData.append(
          "createPatient.PatientMailingAddress.StreetName1",
          patientMailingAddress.streetName1 || ""
        );

        formData.append(
          "createPatient.PatientMailingAddress.StreetName2",
          patientMailingAddress.streetName2 || ""
        );

        formData.append(
          "createPatient.PatientMailingAddress.County",
          patientMailingAddress.county || ""
        );

        formData.append(
          "createPatient.PatientMailingAddress.City",
          patientMailingAddress.city || ""
        );
        const mailingState = patientMailingAddress.patientState || "";
        formData.append(
          "createPatient.PatientMailingAddress.PatientState",
          !isEmpty(mailingState) ? matchState(mailingState) : ""
        );
        formData.append(
          "createPatient.PatientMailingAddress.ZipCode",
          patientMailingAddress.zipCode || ""
        );

        formData.append("createPatient.PatientMailingAddress.Latitude", "");
        formData.append("createPatient.PatientMailingAddress.Longitude", "");
        formData.append(
          "createPatient.PatientMailingAddress.AddressTypeId",
          getValue(patientMailingAddress, "addressTypeId", "")
        );
        formData.append(
          "createPatient.PatientMailingAddress.Country",
          patientMailingAddress.country || ""
        );
      } else {
        formData.append(
          "createPatient.PatientMailingAddress.StreetName1",
          residentialStreetName1
        );

        formData.append(
          "createPatient.PatientMailingAddress.StreetName2",
          residentialStreetName2
        );

        formData.append(
          "createPatient.PatientMailingAddress.County",
          residentialCounty
        );

        formData.append(
          "createPatient.PatientMailingAddress.City",
          residentialCity
        );
        formData.append(
          "createPatient.PatientMailingAddress.PatientState",
          !isEmpty(residentialState) ? matchState(residentialState) : ""
        );
        formData.append(
          "createPatient.PatientMailingAddress.ZipCode",
          residentialZipcode
        );

        formData.append("createPatient.PatientMailingAddress.Latitude", "");
        formData.append("createPatient.PatientMailingAddress.Longitude", "");
        formData.append(
          "createPatient.PatientMailingAddress.AddressTypeId",
          getValue(patientMailingAddress, "addressTypeId", "")
        );
        formData.append(
          "createPatient.PatientMailingAddress.Country",
          residentialCountry
        );
      }
    }
    savePatientInformationDetails(formData);
  };

  const getEligibilityStatus = (status: string | null) => {
    if (status && (status.includes("True") || status.includes("Eligible"))) {
      return "Eligible";
    }
    if (status && (status.includes("False") || status.includes("Not Eligible"))) {
      return "Not Eligible";
    }
    return "";
  };

  const resetEligibilityStates = () => {
    resetStates([
      EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET,
      EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_RESET,
    ]);
  };

  useEffect(() => {
    if(isEmpty(eligibilityRecord.checkEligibilityResponse)){
      navigate(ModuleLinks(PatientRecordsModule.PATIENT_RECORD));
    }

    return () => {
      resetEligibilityStates();
      reset(defaultPatientFormData);
    };
  }, [eligibilityRecord.checkEligibilityResponse]);

  useEffect(() => {
    if (isManual && !isManualFlow) {
      // get benefitPlan uid frm checkEligibility response
      if (
        checkEligibilityResponse &&
        !checkEligibilityLoading &&
        !isEmpty(
          getValue(
            checkEligibilityResponse,
            "additionalNotes.benefitPlanUid",
            ""
          )
        )
      ) {
        getEligibilityData(
          checkEligibilityResponse.additionalNotes.benefitPlanUid
        );
      }
    }
  }, [checkEligibilityLoading]);

  // set information from checkEligibility api
  useEffect(() => {
    if (
      isManual &&
      !isManualFlow &&
      getValue(checkEligibilityResponse, "additionalNotes.benefitPlanUid", "")
        .length > 0 &&
      false === checkEligibilityLoading
    ) {
      const patientInfo = JSON.parse(JSON.stringify(GetPatientInfo));
      if (
        checkEligibilityResponse &&
        getValue(checkEligibilityResponse, "record.subscriberName")
      ) {
        patientInfo.createPatient.firstName =
          checkEligibilityResponse.record.subscriberName.first;
        patientInfo.createPatient.lastName =
          checkEligibilityResponse.record.subscriberName.last;
      }

      patientInfo.createPatient.gender = SelectGender(
        getValue(checkEligibilityResponse, "record.subscriberGender", "")
      );

      patientInfo.createPatient.dateOfBirth = getValue(
        checkEligibilityResponse,
        "record.subscriberDOB",
        ""
      );

      // set patientResidentialAddress
      if (checkEligibilityResponse) {
        patientInfo.patientResidentialAddress = {
          ...getPatientResidentialAddress(checkEligibilityResponse),
        };
      }

      // set phoneNo ext and email address
      patientInfo.createPatient.phoneNo = isEmpty(
        getValue(
          checkEligibilityResponse,
          "supplementalInformation.info[0].homePhone"
        )
      )
        ? ""
        : Number(
            getValue(
              checkEligibilityResponse,
              "supplementalInformation.info[0].homePhone",
              ""
            )
          );
      patientInfo.createPatient.emailAddress = getValue(
        checkEligibilityResponse,
        "supplementalInformation.info[0].emailAddress",
        ""
      );

      // set informationReceived
      patientInfo.informationReceived.effectiveDate =
        checkEligibilityResponse &&
        checkEligibilityResponse.record.subscriberDTLoop.subscriberDT.length > 0
          ? formatDate(
              getValue(
                checkEligibilityResponse,
                "supplementalInformation.info[0].healthCoverage.beginDate"
              )
            )
          : "";
      patientInfo.informationReceived.medicareBeneficiaryIdentifier = getValue(
        checkEligibilityResponse,
        "supplementalInformation.info[0].mbi",
        ""
      );
      patientInfo.informationReceived.effectiveThrough =
        checkEligibilityResponse &&
        checkEligibilityResponse.record.subscriberDTLoop.subscriberDT.length > 0
          ? formatDate(
              getValue(
                checkEligibilityResponse,
                "supplementalInformation.info[0].healthCoverage.endDate"
              )
            )
          : "";
      // set save information details
      reset(patientInfo);
    }
  }, [checkEligibilityResponse, checkEligibilityLoading]);

  // navigate page
  useEffect(() => {
    if (
      savePatientInformationResponse &&
      savePatientInformationResponse.patientId &&
      savePatientInformationResponse.patientId !== 0
    ) {
      if (createRecordStatus === "createPatient") {
        setTimeout(() => {
          navigate(
            ModuleLinks(PatientRecordsModule.REFERRAL_INTAKE, {
              referralId: 0,
              patientId: Number(savePatientInformationResponse.patientId),
              additionalParams: 0,
            })
          );
        }, 1000);
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_PATIENT_RECORD)
        );
      } else if (createRecordStatus === "saveAndExit") {
        resetEligibilityStates();
        setTimeout(() => {
          navigate(ModuleLinks(PatientRecordsModule.PATIENT_RECORD));
        }, 0);
        SnackbarUtils.success(
          ReferralResponseMessages(MessageType.SAVE_PATIENT_RECORD)
        );
      }
    }
  }, [savePatientInformationResponse]);

  // set patient information from eligibilityRecord api
  useEffect(() => {
    if (isManual && !isManualFlow) {
      const patientInfo = JSON.parse(JSON.stringify(GetPatientInfo));

      patientInfo.informationReceived.product =
        eligibilityRecord && eligibilityRecord.product;

      patientInfo.informationReceived.healthPlanUid =
        eligibilityRecord && eligibilityRecord.healthPlanUid;

      patientInfo.informationReceived.benefitPlanUid =
        eligibilityRecord && eligibilityRecord.benefitPlanUid;

      patientInfo.informationReceived.eligibilityStatus =
        eligibilityRecord && eligibilityRecord.eligibilityStatus;

      patientInfo.informationReceived.apiEligibilityId =
        eligibilityRecord && eligibilityRecord.apiEligibilityId;

      patientInfo.createPatient.cellPhone =
        eligibilityRecord && eligibilityRecord.cellPhone;

      patientInfo.createPatient.languagePreferenceKind =
        eligibilityRecord && eligibilityRecord.languagePreferenceKind;

      patientInfo.createPatient.languagePreferenceValue =
        eligibilityRecord && eligibilityRecord.languagePreferenceValue;

      setValue(
        "informationReceived.eligibilityPayerId",
        eligibilityRecord && getHealthPlanValueType(healthPlan, 0)
      );
      setValue(
        "informationReceived.eligibilityPayerName",
        eligibilityRecord && getHealthPlanValueType(healthPlan, 1)
      );
      setValue(
        "createPatient.languagePreferenceValue",
        eligibilityRecord && eligibilityRecord.languagePreferenceValue
      );
      setValue(
        "createPatient.languagePreferenceKind",
        eligibilityRecord && eligibilityRecord.languagePreferenceKind
      );
      setValue(
        "createPatient.cellPhone",
        eligibilityRecord && eligibilityRecord.cellPhone
      );
      setValue(
        "informationReceived.apiEligibilityId",
        eligibilityRecord && eligibilityRecord.apiEligibilityId
      );
      setValue(
        "informationReceived.benefitPlanUid",
        eligibilityRecord && eligibilityRecord.benefitPlanUid
      );
      setValue(
        "informationReceived.healthPlanUid",
        eligibilityRecord && eligibilityRecord.healthPlanUid
      );

      setValue(
        "informationReceived.product",
        eligibilityRecord && eligibilityRecord.product
      );

      setValue(
        "informationReceived.subscriberId",
        eligibilityRecord && eligibilityRecord.subscriberId
      );
      setValue(
        "informationReceived.eligibilityStatus",
        eligibilityRecord &&
          getEligibilityStatus(eligibilityRecord.eligibilityStatus)
      );
      setValue(
        "informationReceived.alternatePhone",
        eligibilityRecord && eligibilityRecord.alternatePhone
      );
    }
  }, [eligibilityRecord, isManual]);

  useEffect(() => {
    setValue("createPatient.isManual", isManualFlow ? true : false);
    setValue("informationReceived.isManual", isManualFlow ? true : false);
    if (isManualFlow) {
      setValue(
        "informationReceived.eligibilityPayerId",
        getHealthPlanValueType(healthPlan, 0)
      );
      setValue(
        "informationReceived.eligibilityPayerName",
        getHealthPlanValueType(healthPlan, 1)
      );
    }
  }, [isManual]);

  return (
    <>
      <CreateReferralHeader
        onSubmit={handleSubmit(onSubmit)}
        setCreateRecordStatus={setCreateRecordStatus}
        saveDataLoading={savePatientInformationLoading}
        patientInformationReset={patientInformationReset}
        authorizationHistoryReset={authorizationHistoryReset}
      />
      <PatientDetailsForm
        control={control}
        setValue={setValue}
        values={values}
        trigger={trigger}
        isManual={isManualFlow ? true : false}
      />
      {isManual && !isManualFlow ? (
        <>
          {!checkEligibilityLoading && (
            <CreateReferral
              responseData={data}
              setPatientFormValue={setValue}
              onSubmit={handleSubmit(onSubmit)}
              pageStatus={PageStatus.CREATE_RECORD}
              setCreateRecordStatus={setCreateRecordStatus}
              eligibilityRecord={eligibilityRecord}
              htmlControlId={`${PatientRecordsAccess.CREATE_PATIENT_TO_CREATE_REFERRAL}||`}
              isModified={false}
            />
          )}
        </>
      ) : (
        <AddEligibilityDetails
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          setCreateRecordStatus={setCreateRecordStatus}
          clearErrors={clearErrors}
          setError={setError}
          getValues={getValues}
          reset={reset}
        />
      )}
    </>
  );
}; //TBD

export default PatientInfo;
