import {
  Grid,
  Box,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  Controller,
  UseFormGetValues,
  UseFormReset,
  UseFormTrigger,
} from "react-hook-form";
import { useParams } from "react-router";
import { isUndefined } from "lodash";

import {
  AddressDetailHeader,
  FaChevronCircleDownIcon,
  FaChevronCircleUpIcon,
  AddressHeaderBox,
  AddressBox,
  AddressRadio,
  MemberAddressBox,
  AddAddressGrid,
  AddAddressBox,
  AddressLine,
  numberTextField,
} from "../styles/requestStyle";
import fontWeight from "../../../../../styles/mui/fontWeight";
import { colors, others } from "../../../../../styles/colors";
import {
  displayFlex,
  flexAlignCentre,
} from "../../../../../styles/mui/styles/display";
import { AddressInputBox } from "../../../../../components/formComponents/styles/style";
import {
  PatientAddressTypeId,
  PatientRequest,
} from "../../../../../models/Patient";
import {
  extractPatientAddress,
  initMapScript,
} from "../../../../../utils/Address";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { rules } from "../../../../../utils/validation/Validation";
import { getConcatStringOrNull, getValue } from "../../../../../utils";

export interface Props {
  values: PatientRequest;
  control: any;
  trigger?: UseFormTrigger<any>;
  getValues: UseFormGetValues<any>;
  reset: UseFormReset<any>;
  patientRequestDetail: PatientRequest;
}

const RequestAddressDetail: React.FC<Props> = ({
  control,
  values,
  trigger,
  getValues,
  reset,
  patientRequestDetail,
}: Props) => {
  const searchInput = useRef() as MutableRefObject<HTMLInputElement>;

  const [showAddress, setShowAddress] = useState(true);
  const [isSameAsResidence, setIsSameAsResidence] = useState<boolean>(true);
  const [
    isSameAsResidenceUpdatedManually,
    setIsSameAsResidenceUpdatedManually,
  ] = useState<boolean>(false);
  const { patientId, referralId } = useParams();

  const setServiceAddress = () => {
    reset(
      {
        ...getValues(),
        isSameAsResidential: true,
        serviceAddress: {
          addressTypeId: PatientAddressTypeId.SERVICE,
          streetName1: getValues("residentialAddress.streetName1"),
          streetName2: getValues("residentialAddress.streetName2"),
          county: getValues("residentialAddress.county"),
          city: getValues("residentialAddress.city"),
          country: getValues("residentialAddress.country"),
          latitude: getValues("residentialAddress.latitude"),
          longitude: getValues("residentialAddress.longitude"),
          patientState: getValues("residentialAddress.patientState"),
          zipCode: getValues("residentialAddress.zipCode"),
        },
      },
      { keepErrors: true }
    );
  };

  const onAddressTypeChange = (isResidential: boolean) => {
    setIsSameAsResidence(isResidential);
    if (isResidential === true) {
      setServiceAddress();
    } else {
      reset(
        {
          ...getValues(),
          isSameAsResidential: false,
          serviceAddress: {
            addressTypeId: PatientAddressTypeId.SERVICE,
            streetName1: null,
            streetName2: null,
            county: null,
            country: null,
            latitude: null,
            longitude: null,
            city: null,
            patientState: null,
            zipCode: null,
          },
        },
        { keepErrors: true }
      );
    }
  };

  useEffect(() => {
    if (
      referralId === "0" &&
      patientId !== "0" &&
      isUndefined(getValues("referralDetail.referralId")) &&
      isSameAsResidenceUpdatedManually === false
    ) {
      onAddressTypeChange(true);
    }
  }, [patientRequestDetail]);

  const AddressDetailHeadline = (headline: string) => {
    return (
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[2]}
        color={colors.fonts[2]}
        mb={"1rem"}
      >
        {headline}:
      </Typography>
    );
  };

  const AddressRecord = (value: string | number | null) => {
    return (
      <Typography
        variant="subtitle2"
        fontWeight={fontWeight.Weight[4]}
        color={colors.black[5]}
      >
        {value}
      </Typography>
    );
  };

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, [searchInput.current, isSameAsResidence]);

  const initAutocomplete = () => {
    if (!searchInput.current) {
      return;
    } else {
      const autocomplete = new window.google.maps.places.Autocomplete(
        searchInput.current
      );
      autocomplete.setFields(["address_component", "geometry"]);
      autocomplete.addListener("place_changed", () =>
        onChangeAddress(autocomplete)
      );
    }
  };

  const onChangeAddress = (autocomplete: any) => {
    const place = autocomplete.getPlace();
    const address1 = extractPatientAddress(place);
    reset(
      {
        ...getValues(),
        isSameAsResidential: false,
        serviceAddress: {
          addressTypeId: PatientAddressTypeId.SERVICE,
          streetName1: getConcatStringOrNull(address1.streetNumber, address1.streetName1),
          streetName2: getConcatStringOrNull(address1.streetName2),
          county: getConcatStringOrNull(address1.county),
          country: null,
          latitude: null,
          longitude: null,
          city: getConcatStringOrNull(address1.city),
          patientState: getConcatStringOrNull(address1.patientState),
          zipCode: getConcatStringOrNull(address1.zipCode),
        },
      },
      { keepErrors: true }
    );
    trigger && trigger();
  };

  return (
    <Grid pb={"2.5rem"}>
      <Box sx={AddressHeaderBox}>
        {showAddress ? (
          <FaChevronCircleUpIcon onClick={() => setShowAddress(false)} />
        ) : (
          <FaChevronCircleDownIcon onClick={() => setShowAddress(true)} />
        )}
        <Typography
          variant="h6"
          fontWeight={fontWeight.Weight[5]}
          color={others.otherColors[63]}
          sx={AddressDetailHeader}
        >
          ADDRESS DETAILS:
        </Typography>
      </Box>
      <Divider light orientation="horizontal" flexItem />

      {showAddress && (
        <Box sx={AddressBox}>
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[3]}
            color={colors.black[13]}
            sx={AddressDetailHeader}
          >
            Will the member be receiving services at the residential address
            mentioned below ?
          </Typography>
          <Controller
            defaultValue=""
            render={({ field }) => (
              <Box mb={"2rem"}>
                <>
                  <RadioGroup
                    aria-labelledby="controlled-radio-buttons-group"
                    sx={AddressRadio}
                    {...field}
                    onChange={(e) => {
                      setIsSameAsResidenceUpdatedManually(true);
                      onAddressTypeChange(JSON.parse(e.target.value));
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={
                        <Typography
                          variant="subtitle2"
                          color={others.otherColors[63]}
                          fontWeight={fontWeight.Weight[5]}
                        >
                          YES
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={
                        <Typography
                          variant="subtitle2"
                          color={others.otherColors[63]}
                          fontWeight={fontWeight.Weight[5]}
                        >
                          NO
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </>
              </Box>
            )}
            name={`isSameAsResidential`}
            control={control}
          />

          <Box ml={"1rem"}>
            <Box sx={flexAlignCentre}>
              <FaMapMarkerAlt size={"1rem"} color={colors.primary.main} />
              <Typography
                variant="h6"
                fontWeight={fontWeight.Weight[5]}
                color={colors.fonts[4]}
                sx={AddressDetailHeader}
              >
                ADDRESS DETAILS:
              </Typography>
            </Box>
            <Divider orientation="horizontal" flexItem sx={AddressLine} />
            <Grid sx={displayFlex} m={"2rem 2.5rem"}>
              <Box mr={"4rem"}>
                {AddressDetailHeadline("Street Name 1")}
                {AddressRecord(
                  getValue(values, "residentialAddress.streetName1")
                )}
              </Box>

              <Box mr={"4rem"}>
                {AddressDetailHeadline("Street Name 2")}
                {AddressRecord(
                  getValue(values, "residentialAddress.streetName2")
                )}
              </Box>

              <Box mr={"4rem"}>
                {AddressDetailHeadline("County")}
                {AddressRecord(getValue(values, "residentialAddress.county"))}
              </Box>

              <Box mr={"4rem"}>
                {AddressDetailHeadline("City")}
                {AddressRecord(getValue(values, "residentialAddress.city"))}
              </Box>

              <Box mr={"4rem"}>
                {AddressDetailHeadline("State")}
                {AddressRecord(
                  getValue(values, "residentialAddress.patientState")
                )}
              </Box>

              <Box mr={"4rem"}>
                {AddressDetailHeadline("Zipcode")}
                {AddressRecord(getValue(values, "residentialAddress.zipCode"))}
              </Box>
            </Grid>
          </Box>
        </Box>
      )}
      {getValue(values, "isSameAsResidential") === false && showAddress && (
        <Grid sx={{ paddingLeft: "2rem" }}>
          <Box sx={MemberAddressBox}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={colors.black[2]}
              sx={AddressDetailHeader}
            >
              Please add the address details where member would be receiving
              services.
            </Typography>
          </Box>

          <Box sx={AddAddressBox}>
            <Typography
              variant="subtitle2"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[4]}
              sx={AddressDetailHeader}
            >
              ADDRESS DETAILS:
            </Typography>
            <Grid container sx={AddAddressGrid}>
              <Grid item xs={6}>
                <Grid container sx={displayFlex}>
                  <Grid item xs={6} sx={AddressInputBox}>
                    <FormInputText
                      name="serviceAddress.streetName1"
                      fieldrequired="required"
                      label="Street Name 1:"
                      control={control}
                      textLength={100}
                      searchInputRef={searchInput}
                      placeholder="Add here..."
                    />
                  </Grid>

                  <Grid item xs={6} sx={AddressInputBox}>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          variant="standard"
                          {...field}
                          value={field.value || ""}
                          label={
                            <Typography
                              variant="h5"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[2]}
                            >
                              Street Name 2:
                            </Typography>
                          }
                          placeholder="Add here..."
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={error && error.message ? true : false}
                          helperText={error ? error.message : null}
                          inputProps={{ maxLength: 100 }}
                        />
                      )}
                      name={`serviceAddress.streetName2`}
                      control={control}
                      rules={rules.streetName2}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={3} sx={AddressInputBox}>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          variant="standard"
                          {...field}
                          value={field.value || ""}
                          label={
                            <Typography
                              variant="h5"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[2]}
                            >
                              County:
                            </Typography>
                          }
                          placeholder="Add here..."
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={error && error.message ? true : false}
                          helperText={error ? error.message : null}
                          inputProps={{ maxLength: 100 }}
                        />
                      )}
                      name={`serviceAddress.county`}
                      control={control}
                      rules={rules.county}
                    />
                  </Grid>
                  <Grid item xs={3} sx={AddressInputBox}>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          variant="standard"
                          {...field}
                          value={field.value || ""}
                          label={
                            <Typography
                              variant="h5"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[2]}
                            >
                              City:
                            </Typography>
                          }
                          placeholder="Choose"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={error && error.message ? true : false}
                          helperText={error ? error.message : null}
                          inputProps={{ maxLength: 100 }}
                        />
                      )}
                      name={`serviceAddress.city`}
                      control={control}
                      rules={rules.city}
                    />
                  </Grid>
                  <Grid item xs={3} sx={AddressInputBox}>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          variant="standard"
                          {...field}
                          value={field.value || ""}
                          label={
                            <Typography
                              variant="h5"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[2]}
                            >
                              State:
                            </Typography>
                          }
                          placeholder="Choose"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={error && error.message ? true : false}
                          helperText={error ? error.message : null}
                        />
                      )}
                      name={`serviceAddress.patientState`}
                      control={control}
                      rules={rules.state}
                    />
                  </Grid>
                  <Grid item xs={2} sx={AddressInputBox}>
                    <Controller
                      rules={rules.zipcode}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          sx={numberTextField}
                          variant="standard"
                          {...field}
                          value={field.value || ""}
                          label={
                            <Typography
                              variant="h5"
                              fontWeight={fontWeight.Weight[3]}
                              color={colors.fonts[2]}
                            >
                              Zipcode:
                            </Typography>
                          }
                          placeholder="Add here..."
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={error && error.message ? true : false}
                          helperText={error ? error.message : null}
                          inputProps={{ maxLength: 10 }}
                        />
                      )}
                      name={`serviceAddress.zipCode`}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RequestAddressDetail;
