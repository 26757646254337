import {
  FaNotesMedical,
  FaUserCheck,
  FaUserMd,
  FaUserNurse,
  FaUserPlus,
} from "react-icons/fa";
import { colors } from "../styles/colors";

export enum ReAuthRequestActionDispatchTypes {
  REAUTH_REQUEST_INVOKE = "REAUTH_REQUEST_INVOKE",
  REAUTH_REQUEST_SUCCESS = "REAUTH_REQUEST_SUCCESS",
}

export enum GeneralInfoActionDispatchTypes {
  GENERAL_INFO_INVOKE = "GENERAL_INFO_INVOKE",
  GENERAL_INFO_SUCCESS = "GENERAL_INFO_SUCCESS",
  GENERAL_INFO_RESET = "GENERAL_INFO_RESET",
}

export enum PutAuthorizationDetailTabActionDispatchTypes {
  PUT_AUTHORIZATION_DETAIL_TAB_ERROR = "PUT_AUTHORIZATION_DETAIL_TAB_ERROR",
  PUT_AUTHORIZATION_DETAIL_TAB_INVOKE = "PUT_AUTHORIZATION_DETAIL_TAB_INVOKE",
  PUT_AUTHORIZATION_DETAIL_TAB_SUCCESS = "PUT_AUTHORIZATION_DETAIL_TAB_SUCCESS",
  PUT_AUTHORIZATION_DETAIL_TAB_RESET = "PUT_AUTHORIZATION_DETAIL_TAB_RESET",
}

export enum PatientSelectedAuthActionDispatchTypes {
  GET_PATIENT_SELECTED_AUTH_INVOKE = "GET_PATIENT_SELECTED_AUTH_INVOKE",
  GET_PATIENT_SELECTED_AUTH_SUCCESS = "GET_PATIENT_SELECTED_AUTH_SUCCESS",
}

export enum ReAuthTimelineActionDispatchTypes {
  REAUTH_TIMELINE_INVOKE = "REAUTH_TIMELINE_INVOKE",
  REAUTH_TIMELINE_RESET = "REAUTH_TIMELINE_RESET",
  REAUTH_TIMELINE_SUCCESS = "REAUTH_TIMELINE_SUCCESS",
}

export enum ServiceProviderDetailsActionDispatchTypes {
  SERVICE_PROVIDER_DETAILS_INVOKE = "SERVICE_PROVIDER_DETAILS_INVOKE",
  SERVICE_PROVIDER_DETAILS_POST_ERROR = "SERVICE_PROVIDER_DETAILS_POST_ERROR",
  SERVICE_PROVIDER_DETAILS_POST_INVOKE = "SERVICE_PROVIDER_DETAILS_POST_INVOKE",
  SERVICE_PROVIDER_DETAILS_POST_SUCCESS = "SERVICE_PROVIDER_DETAILS_POST_SUCCESS",
  SERVICE_PROVIDER_DETAILS_SUCCESS = "SERVICE_PROVIDER_DETAILS_SUCCESS",
  SERVICE_PROVIDER_DETAILS_RESET = "SERVICE_PROVIDER_DETAILS_RESET",
  DELETE_SERVICE_PROVIDER_INVOKE = "DELETE_SERVICE_PROVIDER_INVOKE",
  DELETE_SERVICE_PROVIDER_SUCCESS = "DELETE_SERVICE_PROVIDER_SUCCESS",
  DELETE_SERVICE_PROVIDER_ERROR = "DELETE_SERVICE_PROVIDER_ERROR",
  DELETE_SERVICE_PROVIDER_RESET = "DELETE_SERVICE_PROVIDER_RESET"
}

export enum AuthPastMedicalHistoryActionDispatchTypes {
  AUTH_PAST_MEDICAL_HISTORY_INVOKE = "AUTH_PAST_MEDICAL_HISTORY_INVOKE",
  AUTH_PAST_MEDICAL_HISTORY_SUCCESS = "AUTH_PAST_MEDICAL_HISTORY_SUCCESS",
}

export enum AuthorizationDetailTabsActionDispatchTypes {
  AUTH_DETAIL_TABS_INVOKE = "AUTH_DETAIL_TABS_INVOKE",
  AUTH_DETAIL_TABS_SUCCESS = "AUTH_DETAIL_TABS_SUCCESS",
  AUTH_DETAIL_TABS_RESET = "AUTH_DETAIL_TABS_RESET",
}

export enum ProviderListDefaultHeader {
  Action = "Action",
  NAME = "NAME",
  NPINumber = "NPI Number",
  NPI = "NPI",
  PROVIDER_NAME = "Name",
}

export enum ResumeIntakeActionDispatchTypes {
  RESUME_INTAKE_INVOKE = "RESUME_INTAKE_INVOKE",
  RESUME_INTAKE_RESET = "RESUME_INTAKE_RESET",
  RESUME_INTAKE_SUCCESS = "RESUME_INTAKE_SUCCESS",
}

export enum ReauthCompleteActionDispatchTypes {
  REAUTH_COMPLETE_ERROR = "REAUTH_COMPLETE_ERROR",
  REAUTH_COMPLETE_INVOKE = "REAUTH_COMPLETE_INVOKE",
  REAUTH_COMPLETE_SUCCESS = "REAUTH_COMPLETE_SUCCESS",
}

export const ActiveAuthorizationIcon = (label: string) => {
  switch (label) {
    case "Intake":
      return <FaUserPlus size={"1.2rem"} color={colors.fonts[18]} />;
    case "Clinical Review":
      return <FaNotesMedical size={"1.2rem"} color={colors.fonts[18]} />;
    case "Staffing":
      return <FaUserNurse size={"1.2rem"} color={colors.fonts[18]} />;
    case "Physician Review":
      return <FaUserMd size={"1.2rem"} color={colors.fonts[18]} />;
    case "NTU":
    case "Completed":
      return <FaUserCheck size={"1.2rem"} color={colors.fonts[18]} />;
  }
};

//Authorization Details

export enum AuthorizationDetailTitle {
  DIAGNOSIS_CODE = "DIAGNOSIS CODE",
  GENERAL_INFO = "GENERAL INFO",
  MEDICAL_HISTORY = "MEDICAL HISTORY",
  REQUEST_DETAILS = "REQUEST DETAILS",
}

export enum PostInitiateFaxActionDispatchTypes {
  POST_INITIATE_FAX_INVOKE = "POST_INITIATE_FAX_INVOKE",
  POST_INITIATE_FAX_SUCCESS = "POST_INITIATE_FAX_SUCCESS",
  POST_INITIATE_FAX_ERROR = "POST_INITIATE_FAX_ERROR",
  POST_INITIATE_FAX_RESET = "POST_INITIATE_FAX_RESET",
}

export const HeaderLabel = [
  { label: AuthorizationDetailTitle.GENERAL_INFO, fontWeight: 500 },
  { label: AuthorizationDetailTitle.DIAGNOSIS_CODE, fontWeight: 500 },
  { label: AuthorizationDetailTitle.MEDICAL_HISTORY, fontWeight: 500 },
  { label: AuthorizationDetailTitle.REQUEST_DETAILS, fontWeight: 500 },
];

export const AuthorizationDetailTabsInitialValue = {
  diseasesDto: [],
  psychologicalDiseasesDto: [],
  icdCodesInfoDto: [],
  authorizationGeneralInfoDto: {
    referralId: 0,
    receivedDateTime: "",
    referralStatusId: null,
    urgencyId: null,
    urgencyReason: "",
    startOfCareVerified: true,
    staffingStatusId: null,
    caregiverId: null,
    caregiverInfo: null,
    effectiveFrom: "",
    effectiveThrough: "",
    facilityDocumentationDate: null,
    dischargeDate: null,
    dischargeReasonId: null,
    dischargeVerified: true,
    userCreated: null,
    startOfCareDate: "",
    referralCreatedBy: "",
    referralCreatedOn: "",
    referralNtuReasonId: null,
  },
  requester: {
    facilityTypeId: null,
    requesterName: "",
    requesterExtension: "",
    requesterPhoneNo: "",
    requesterEmail: null,
    requesterFax: "",
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    requesterState: null,
    zipCode: null,
    facilityTypeName: "",
    facilityName: "",
    requesterNpi: null,
  },
  referralSource: {
    facilityTypeId: null,
    referralSourceName: "",
    referralSourceExtension: "",
    referralSourcePhoneNo: "",
    referralSourceEmail: null,
    referralSourceFax: null,
    streetName1: null,
    streetName2: null,
    county: null,
    city: null,
    referralSourceState: null,
    zipCode: null,
    sameAsRequestingFacility: false,
    dischargeDate: null,
    facilityTypeName: "",
    facilityName: "",
    referralSourceNpi: null,
  },
};

export const GeneralInfoInitialValue = {
  receivedDate: null,
  receivedTime: null,
  authStatus: null,
  urgencySatus: null,
  urgencyReason: " ",
  referralSource: null,
  startOfCare: null,
  socValidate: false,
  staffingStatus: null,
  homeBound: null,
  homeBoundDate: null,
  careGiver: null,
  careGiverInfo: "",
  createdBy: "",
  createDate: null,
  effectiveFromDate: null,
  effectiveThroughDate: null,
  requestor: null,
  facilityDate: null,
  dischargeDate: null,
  dischargeReason: null,
  dischargeVerify: false,
};

export const UrgencyReasonTextLength = 2000;

export const AuthPastMedicalHistoryInitialValues = {
  diseasesDto: [],
  psychologicalDiseasesDto: [],
  referralId: null,
  userCreated: null,
};

export enum TimelineStagesStatusList {
  COMPLETED = "COMPLETED",
  REVIEW_PENDING = "Review Pending",
  START = "START",
  YET_TO_START = "Yet To Start",
}

export const TimelineStagesMessages = (value: string) => {
  switch (value) {
    case TimelineStagesStatusList.START:
      return "START";
    case TimelineStagesStatusList.REVIEW_PENDING:
      return "Review Pending";
    case TimelineStagesStatusList.YET_TO_START:
      return "Yet To Start";
    case TimelineStagesStatusList.COMPLETED:
      return "COMPLETED";
    default:
      return "";
  }
};

export enum PageStatus {
  RE_AUTH = "reauthRequest",
  CREATE_RECORD = "createRecord",
  PATIENT_DETAILS = "patientDetails",
}

//Always add in small case
export const StaffingStatusConstant = ["staffing automation", "tbd"];
