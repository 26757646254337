import {
  Box,
  Button,
  Grid,
  Typography,
  TypographyPropsVariantOverrides,
  FormHelperText,
  AutocompleteInputChangeReason,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import { debounce, isNil } from "lodash";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";

import AddressFormInputUseForm from "../../../../../components/formComponents/AddressFormInputUseForm";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { PhysicianDetails } from "../../../../../models/PhysicianDetails";
import { colors, others } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  FormInputBox,
  IconFaUser,
  AutocompleteContainer,
  AutocompleteUI,
} from "../styles/OrderingPhysician";
import { PhysicianRecordsResponse } from "../../../../../models/Api/PhysicianDetails";
import {
  displayFlex,
  justifyContentLeft,
} from "../../../../../styles/mui/styles/display";
import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import { rules } from "../../../../../utils/validation/Validation";
import { AvailablePhysicianDetails } from "./AvailablePhysicianDetails";
import React from "react";
import { PhoneFaxExtInput } from "../../../../../components/formComponents/PhoneFaxExtInput";
import VirtualizeAutocomplete from "../../../../../components/formComponents/AutocompleteList";
import {
  TableDropdown,
  getTableBody,
  getTableHead,
} from "../../../../../constants/AllPatientRecord";
import { RequiredSymbol } from "../../../../MyPatients/styles/modal";

export interface PropsFromState {
  physicianRecords: PhysicianRecordsResponse;
  control?: any;
  getValues?: any;
  setValue?: any;
  reset?: any;
  choosePhysician: (option: PhysicianDetails) => void;
  addManually: boolean;
  handleAddManually: () => void;
  physician: PhysicianDetails | null;
  names: any;
  name: string;
  required: boolean;
  helper: any;
  inputValue: PhysicianDetails | null;
  setInputValue: (value: PhysicianDetails | null) => void;
  errorFlag?: string | number;
  modal?: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
  isSearchVisible: boolean;
  clearErrors?: any;
}
export interface PropsFromDispatch {
  getPhysicianRecords: (value: string) => void;
  resetPhysicianRecords: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch;

const PhysicianInputForm: React.FC<AllProps> = ({
  addManually,
  choosePhysician,
  control,
  errorFlag,
  getPhysicianRecords,
  getValues,
  handleAddManually,
  isSearchVisible,
  modal,
  names,
  open,
  physician,
  physicianRecords,
  reset,
  resetPhysicianRecords,
  setOpen,
  setValue,
  name,
  required,
  setInputValue,
  inputValue,
}: AllProps) => {
  const { response, loading } = physicianRecords;

  useEffect(() => {
    if (response && response.length != 0 && response[0].physicianName !== "") {
      setPhysicianListResp([...response]);
    } else {
      setPhysicianListResp(() => []);
    }
  }, [response]);

  // searching
  const [PhysicianListResp, setPhysicianListResp] = useState<
    PhysicianDetails[]
  >([]);

  const handlePhysicianName = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (!isNil(value) && !isNil(event)) {
      if (value.length <= 2) {
        setPhysicianListResp(() => []);
        setOpen && setOpen(false);
      }
      if (value.trim().length >= 3 && reason === "input") {
        getPhysicianRecords(value.trim());
        setOpen && setOpen(true);
      }
    }
  };

  const TypographyBox = (
    variant:
      | OverridableStringUnion<
          "inherit" | Variant,
          TypographyPropsVariantOverrides
        >
      | undefined,
    value: string,
    color: string,
    fontWeight: string,
    sx?: object
  ) => {
    return (
      <Typography
        variant={variant}
        fontWeight={fontWeight}
        color={color}
        sx={sx}
      >
        {value}
      </Typography>
    );
  };

  const handleSelect = (option: any) => {
    choosePhysician(option);
    setInputValue(null);
    const value = PhysicianListResp.find(
      (physician) => physician.physicianName === option.physicianName
    );
    setValue(name, value?.physicianName || null);
    resetPhysicianRecords();
    setOpen && setOpen(false);
  };

  const debouncedSearch = debounce((event, value, reason) => {
    handlePhysicianName(event, value, reason);
  }, 500);

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    debouncedSearch(event, value, reason);
  };

  const showError = () => {
    if (addManually) {
      return rules.physicianDetails;
    } else {
      if (required && !(physician && physician.physicianName)) {
        return rules.physicianNameMaster;
      }
    }
    return rules.physicianDetails;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} pt={"0.5rem"}>
          <Box sx={displayFlex}>
            {TypographyBox(
              "body1",
              "Name:",
              colors.fonts[4],
              fontWeight.Weight[3]
            )}
            {required &&
              TypographyBox(
                "subtitle1",
                "*",
                colors.red[100],
                "",
                RequiredSymbol
              )}
          </Box>
        </Grid>
        <Grid item xs={4.2}>
          <Box sx={AutocompleteContainer}>
            <Controller
              name={name}
              control={control}
              defaultValue={null}
              rules={showError()}
              render={({ fieldState: { error } }) => {
                return (
                  <>
                    <VirtualizeAutocomplete
                      options={PhysicianListResp}
                      value={inputValue}
                      handleInputChange={handleInputChange}
                      sx={AutocompleteUI}
                      open={open}
                      handleSelect={handleSelect}
                      tableHead={getTableHead(TableDropdown.PHYSICIAN)}
                      tableBody={getTableBody(TableDropdown.PHYSICIAN)}
                      autocompleteInputIcon={<IconFaUser />}
                      setOpen={setOpen}
                      maxLength={128}
                      width="55rem"
                      loading={loading}
                    />
                    {error && errorFlag === 0 && (
                      <FormHelperText>{error.message}</FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={3} sx={FormInputBox}>
          <Box sx={[flexAlignCentre, justifyContentLeft]}>
            <Box pl={"1rem"}>
              {TypographyBox(
                "subtitle2",
                "OR",
                colors.fonts[4],
                fontWeight.Weight[6]
              )}
            </Box>
            <Box pl={"2.5rem"}>
              <Button variant="contained" onClick={handleAddManually}>
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[4]}
                  color={others.otherColors[33]}
                >
                  ADD MANUALLY
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>

        {!addManually &&
          !isNil(physician) &&
          physician.physicianId !== -1 &&
          isSearchVisible && (
            <AvailablePhysicianDetails
              physicianDetails={physician}
              modal={modal}
            />
          )}

        {addManually && (
          <PhysicianFormInputs
            control={control}
            reset={reset}
            getValues={getValues}
            setValue={setValue}
            names={names}
          />
        )}
      </Grid>
    </>
  );
};

type PhysicianFormInputsProps = {
  control: any;
  reset: any;
  getValues: any;
  setValue: any;
  names: any;
  isHeader?: boolean;
};
export const PhysicianFormInputs = (props: PhysicianFormInputsProps) => {
  const { control, reset, getValues, setValue, names, isHeader } = props;

  const searchInput = useRef() as MutableRefObject<HTMLInputElement>;

  const labels = [
    {
      label: "Street Name 1: ",
      name: names.address[0],
      helper: rules.streetName1,
      textLength: 100,
    },
    {
      label: "Street Name 2: ",
      name: names.address[1],
      textLength: 100,
    },
    {
      label: "County: ",
      name: names.address[3],
      helper: rules.physicianCounty,
      textLength: 100,
    },
    {
      label: "City: ",
      name: names.address[2],
      helper: rules.city,
      textLength: 100,
    },
    {
      label: "State: ",
      name: names.address[4],
      helper: rules.state,
      textLength: 100,
    },
    {
      label: "Zipcode: ",
      name: names.address[5],
      helper: rules.physicianZipcode,
      textLength: 10,
    },
  ];

  return (
    <>
      <Grid item xs={12} pt={isHeader ? "0.5rem" : "1.5rem"}>
        <Grid container>
          <Grid item xs={2.8}>
            <Box sx={FormInputBox}>
              <FormInputText
                name={names.physicianName}
                control={control}
                label="Physician Name:"
                fieldrequired={"true"}
                helper={rules.physicianName}
                placeholder="Add here..."
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={FormInputBox}>
              <FormInputText
                name={names.physicianNpiId}
                control={control}
                label="NPI:"
                textLength={10}
                fieldrequired={"true"}
                helper={rules.physicianNpi}
                placeholder="Add here..."
                inputType="onlyNumber"
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={FormInputBox}>
              <FormInputText
                name={names.taxId}
                control={control}
                label="Tax ID:"
                textLength={14}
                fieldrequired={"true"}
                helper={rules.physicianTaxId}
                placeholder="Add here..."
              />
            </Box>
          </Grid>
          <Grid item xs={2.8}>
            <Box sx={FormInputBox}>
              <PhoneFaxExtInput
                label="Phone Number:"
                control={control}
                phoneFaxName={names.phoneNo}
                extName={names.phoneExt}
                rules={isHeader ? rules.mobilePhone : rules.phone}
              />
            </Box>
          </Grid>
          <Grid item xs={2.4}>
            <Box sx={FormInputBox}>
              <PhoneFaxExtInput
                label="Fax:"
                control={control}
                phoneFaxName={names.fax}
                rules={rules.fax}
                isFax
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <AddressFormInputUseForm
          control={control}
          searchInput={searchInput}
          reset={reset}
          getValues={getValues}
          setValue={setValue}
          labels={labels}
          addressLabel="ADDRESS DETAILS:"
          addressVariant="subtitle1"
        />
      </Grid>
    </>
  );
};

export default PhysicianInputForm;
