import { ReferralDiscipline } from "../models/Disciplines";
import { getBtoaValue } from "../utils";

export enum DisciplineActionDispatchTypes {
  DISCIPLINE_INVOKE = "DISCIPLINE_INVOKE",
  DISCIPLINE_SUCCESS = "DISCIPLINE_SUCCESS",
  DISCIPLINE_RESET = "DISCIPLINE_RESET",
}

export enum ReferralDisciplineActionDispatchTypes {
  REFERRAL_DISCIPLINE_INVOKE = "REFERRAL_DISCIPLINE_INVOKE",
  REFERRAL_DISCIPLINE_SUCCESS = "REFERRAL_DISCIPLINE_SUCCESS",
  REFERRAL_DISCIPLINE_RESET = "REFERRAL_DISCIPLINE_RESET",
}

export enum UpdateDisciplineActionDispatchTypes {
  UPDATE_DISCIPLINE_ERROR = "UPDATE_DISCIPLINE_ERROR",
  UPDATE_DISCIPLINE_INVOKE = "UPDATE_DISCIPLINE_INVOKE",
  UPDATE_DISCIPLINE_SUCCESS = "UPDATE_DISCIPLINE_SUCCESS",
  UPDATE_DISCIPLINE_RESET = "UPDATE_DISCIPLINE_RESET",
}

export const DisciplineDataSubset = {
  serviceRequestTypeId: null,
  urgencyId: null,
  serviceStatusId: null,
  serviceStatusReasonId: null,
  facilityTypeId: null,
};

export const DisciplineData:ReferralDiscipline = {
  disciplineId: -1,
  requestDateTime: null,
  urgencyChanged: false,
  urgencyChangeDateTime: null,
  originallyRequestedVisits: 0,
  hcpcs: "",
  hcpcsDescription: "",
  serviceNumber: -1,
  effectiveFromDate: "",
  effectiveThroughDate: "",
  requestCreatedOn: "",
  requestCreatedBy: "",
  decidedBy: "",
  decidedDateTime: "",
  adjudicatedBy: "",
  adjudicatedDateTime: "",
  inRuleSuggestion: "",
  userCreated: null,
  requestedVisits: 0,
  approvedVisits: 0,
  statusReasonRequester: null,
  deniedVisits: -1,
  assignedUserId: null,
  acceptedVisits: false,
  agreedByPhysician: null,
  agreementDateTime: null,
  rationale: "",
  rationaleTemplateId: null,
  resourceDescription: "",
  serviceRequestDisciplineId: -1,
  serviceRequestTypeName: "",
  stageName: "",
  otherRationale: "",
  otherResourceDescription: "",
  createdUserFullName: "",
  receivedMethodId: null,
  ...DisciplineDataSubset,

};

export const DisciplineRecord = {
  referralId: -1,
  serviceRequestId: -1,
  serviceRequestDisciplineId: -1,
  serviceRequestTypeId: -1,
  serviceRequestTypeName: "",
  urgencyId: 0,
  urgencyStatus: "",
  serviceNumber: -1,
  serviceRequestNumber: "",
  requestDateTime: "",
  effectiveFromDate: "",
  effectiveThroughDate: "",
  requestedVisits: -1,
  approvedVisits: -1,
  deniedVisits: -1,
  serviceStatus: "",
  serviceStatusId: 0,
  serviceStatusReason: "",
  skill: "",
  assignedUserFullName: "",
  decisionDateTime: ""
}

export const ServiceHeader = [
  { label: "Service No.", width: "60%" },
  { label: "Status", width: "60%" },
];

export enum ReferralDisciplinesStages {
  PhysicianReview = "Physician Review",
  ClinicalReview = "Clinical Review",
}

export const getbase64Value = () => {
  const additionalParamsObject = { isAddService: true, intakeStageId: 5 };
  const jsonString = JSON.stringify(additionalParamsObject);
  const additionalParams = getBtoaValue(jsonString);
  return additionalParams;
};
