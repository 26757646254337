export const alphabetRegex = /^[A-Za-z\s]*[A-Za-z][A-Za-z\s]*$/;
export const personNameRegex = /^[A-Za-z](?:[A-Za-z\s-]*[A-Za-z])?$/;
export const alphabetCountyRegex = /^[A-Za-zñáéíóúü\s]*[A-Za-z][A-Za-z\s]*$/;
export const facilityNameRegex = /^[A-Za-z](?:[A-Za-z\s'-]*[A-Za-z])?$/;
export const alphabetOnlyRegex = /^[a-zA-Z]*$/;
export const alphabetSpaceAndHyphen = /[^a-zA-Z\s-]/g;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const numberRegex =
    /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
export const numberOnlyRegex = /^[0-9]+$/;
export const validNonZeroNumber = /^[1-9]+[0-9]*$/;
export const numAlphabetOnlyRegex = /^[A-Za-z0-9]*$/;
export const alphaNumericRegex = /^[A-Za-z0-9\s]*[A-Za-z0-9][A-Za-z0-9\s]*$/;
export const anyCharacters = /^[!-~]*$/;
export const phoneFaxFormat = /^\([1-9]{1}[0-9]{2}\) [0-9]{3}-[0-9]{4}$/;
export const contractIdPbpRegex =/^[A-Za-z0-9](?:[A-Za-z0-9\s-]*[A-Za-z0-9])?$/;
export const onlyNumAlphabetRegex = /[^A-Za-z0-9]/g;
export const addressRegex = /[a-zA-Z]/i;