export const FormattedNumber = (value: any, length: number) => {
  let formattedNumber;
  // eslint-disable-next-line no-useless-escape
  const regex = () => value.replace(/[^0-9]+/g, "");
  const areaCode = () => `(${regex().slice(0, 3)})`;
  const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;
  const trailer = (start: any) => `${regex().slice(start, regex().length)}`;
  if (length <= 3) {
    // First 3 digits
    formattedNumber = regex();
  } else if (length === 4) {
    // After area code
    formattedNumber = `${areaCode()} ${trailer(3)}`;
  } else if (length === 5) {
    // When deleting digits inside parenthesis
    formattedNumber = `${areaCode().replace(")", "")}`;
  } else if (length > 5 && length <= 9) {
    // Before dash
    formattedNumber = `${areaCode()} ${trailer(3)}`;
  } else if (length >= 10) {
    // After dash
    formattedNumber = `${firstSix()}-${trailer(6)}`;
  }

  return formattedNumber;
};
