import { Reducer } from "redux";

import { neverReached } from "../utils";
import { EligibilityDataResponse } from "../models/Api/EligibilityData";
import { EligibilityDataDispatchTypes } from "../constants/EligibilityData";
import { EligibilityDataActionDispatchTypes } from "../models/DispatchTypes/EligibilityData";

const initialState: EligibilityDataResponse = {
  loading: false,
  response: {
    healthPlanId: -1,
    benefitPlanId: -1,
    healthPlan: "",
    product: "",
    benefitPlanNm: "",
    benefitPlanName: "",
    benefitPlanUid: "",
    healthPlanState: "",
    lengthOfEpisode: 0,
    subscriberId: "",
    mbi: "",
    effectiveDate: "",
    effectiveThrough: "",
    healthPlanUid: "",
    apiEligibilityId: "",
    eligibilityStatus: "False ",
    cellPhone: "",
    languagePreferenceKind: "",
    languagePreferenceValue: "",
    patientResidentialAddress: {
      addressTypeId: 1,
      streetName1: "",
      streetName2: "",
      city: "",
      zipCode: "",
      patientState: "",
      country: "",
      county: "",
      latitude: null,
      longitude: null,
    },
    patientMailingAddress: {
      addressTypeId: 3,
      streetName1: "",
      streetName2: null,
      city: "",
      zipCode: "",
      patientState: "",
      country: "",
      county: "",
      latitude: null,
      longitude: null,
    },
    checkEligibilityResponse: null
  }
};

const EligibilityDataReducer: Reducer<
  EligibilityDataResponse,
  EligibilityDataActionDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case EligibilityDataDispatchTypes.ELIGIBILITY_DATA_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case EligibilityDataDispatchTypes.ELIGIBILITY_DATA_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case EligibilityDataDispatchTypes.ELIGIBILITY_DATA_RESET: {
      return initialState;
    }
    default:
      neverReached(); // when a new action is created, this helps us remember to handle it in the reducer
  }
  return state;
};

export default EligibilityDataReducer;