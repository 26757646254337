import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { AllPatientTabDispatchTypes } from "../../../../../models/DispatchTypes/AllPatientRecord";
import { ApplicationState } from "../../../../../store/ApplicationState";
import {
  getPhysicianRecords,
  resetPhysicianRecords,
} from "../../../../../actions/AllPatientRecord";
import PhysicianInputForm from "../components/PhysicianInputForm";

const mapStateToProps = ({ physicianRecordsState }: ApplicationState) => ({
  physicianRecords: physicianRecordsState,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<AllPatientTabDispatchTypes>
  >
) => {
  return {
    getPhysicianRecords: (value: string) => {
      dispatch(getPhysicianRecords(value));
    },
    resetPhysicianRecords: () => {
      dispatch(resetPhysicianRecords());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PhysicianInputForm);
