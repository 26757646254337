import { FaRegQuestionCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { isEmpty, isNil, isNull } from "lodash";

import {
  AuthText,
  BackBtn,
  FooterBox,
  HeaderAuthText,
  HeaderBox,
  HeaderText,
  InnerBox,
} from "../styles/Header";
import { colors } from "../../../../../styles/colors";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  ContentBox,
  DetailsDivider,
  Gridbody,
  GridData,
  GridFooter,
  Info,
  InfoBox,
  ModalGrid,
  ModalHeaderBox,
  ModalTitle,
  npiBox,
  Outerbodygrid,
  Question,
  QuestionIcon,
} from "../styles/Modal";
import {
  AuthorizationDetailTabs,
  ServiceProviderPayload,
} from "../../../../../models/Authorization";
import PatientAuthorizationDetails from "../containers/PatientAuthorizationDetails";
import {
  CheckEligibilityResponse,
  Icd10RecordResponse,
} from "../../../../../models/Api/AllPatientRecord";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import {
  AuthorizationDetailTabsResponse,
  AuthPastMedicalHistoryResponse,
  GeneralInfoResponse,
  PutAuthorizationDetailTabResponse,
  ReAuthTimelineResponse,
  ServiceProviderDetailsResponse,
} from "../../../../../models/Api/Authorization";
import {
  getConcatStringOrNull,
  getReferralId,
  getValue,
  length,
  serverSideErrorMsg,
} from "../../../../../utils";
import { formatDate } from "../../../../../utils/DateTime";
import { UserDetailsPayload } from "../../../../../models/User";
import {
  HealthPlanPayload,
  PatientFilterOptions,
} from "../../../../../models/Patient";
import {
  AuthorizationDetailTabsInitialValue,
  ReAuthTimelineActionDispatchTypes,
  ResumeIntakeActionDispatchTypes,
} from "../../../../../constants/Authorization";
import { EligibilityRecord } from "../../../../../models/Master";
import {
  EligibilityCheckDispatchTypes,
  PatientDetailType,
  ReAuthReferralRequestDispatchType,
} from "../../../../../constants/AllPatientRecord";
import { DateFormats } from "../../../../../constants/Date";
import { ModuleLinks, PatientRecordsModule } from "../../../../AllRoutes";
import Breadcrumb from "../../../../../components/breadcrumb/Breadcrumb";
import Permission from "../../../../Permission";
import { AuthDetailsAccess } from "../../../../../constants/Permission";
import { matchState } from "../../../../../utils/StateSearch";
import {
  IconFaTimesCircle,
  ModalGridContainer,
} from "../../../../../styles/common/style";
import { PatientInformationReceivedActionDispatchTypes } from "../../../../../constants/PatientInformation";
import { EligibilityRecordActionDispatchTypes } from "../../../../../constants/Master";
import { UpdateDisciplineActionDispatchTypes } from "../../../../../constants/Discipline";
import { ServiceProviderDataResponse } from "../../../../../models/Api/Service";
import PatientHeader from "../../../components/ActiveAuthCommon/PatientHeader/containers";
import { PriorAuthReferralSourceMasterResponse } from "../../../../../models/Api/Master";
import { NtuReasonMasterResponse } from "../../../../../models/Api/Master";

export interface PropsFromDispatch {
  getPatient: (id: number, referralId: number) => void;
  checkEligibility: (payload: HealthPlanPayload) => void;
  eligibilityReset: () => void;
  getAuthorizationDetailTabs: (referralId: string) => void;
  getEligibilityData: (benefitPLanUid: any) => void;
  getServiceProviderData: (referralId: number) => void;
  resetStates: (actionType: string[]) => void;
  saveAuthorizationDetailTab: (
    data: AuthorizationDetailTabs,
    referralId: string
  ) => void;
  getDisciplinesData: (referralId: number) => void;
  getPriorAuthReferralSourceMasterResp: () => void;
  getNtuReasonMasterResponse: () => void;
}
export interface PropsFromState {
  authorizationDetailTabsData: AuthorizationDetailTabsResponse;
  authPastMedicalHistoryData: AuthPastMedicalHistoryResponse;
  checkEligibilityData: CheckEligibilityResponse;
  eligibilityRecord: EligibilityRecord;
  generalInfo: GeneralInfoResponse;
  icd10Records: Icd10RecordResponse;
  infoReceivedResp: PatientInformationViewResponse;
  patientData: PatientInformationViewResponse | any;
  payerState: PatientFilterOptions[];
  putAuthorizationDetailTabResp: PutAuthorizationDetailTabResponse;
  serviceProviderDetails: ServiceProviderDetailsResponse;
  saveIcd10RecordsRsp: any;
  user: UserDetailsPayload;
  reAuthTimeline: ReAuthTimelineResponse;
  serviceProviderInfo: ServiceProviderDataResponse;
  priorAuthReferralSource: PriorAuthReferralSourceMasterResponse;
  masterNtuReason: NtuReasonMasterResponse;
  updatePatientHeaderData: PatientInformationViewResponse;
}
type AllProps = PropsFromState & PropsFromDispatch;

const Header: React.FC<AllProps> = ({
  authorizationDetailTabsData,
  checkEligibility,
  checkEligibilityData,
  eligibilityReset,
  getAuthorizationDetailTabs,
  getEligibilityData,
  getPatient,
  getServiceProviderData,
  patientData,
  payerState,
  putAuthorizationDetailTabResp,
  serviceProviderDetails,
  resetStates,
  saveAuthorizationDetailTab,
  reAuthTimeline,
  user,
  serviceProviderInfo,
  getDisciplinesData,
  priorAuthReferralSource,
  getPriorAuthReferralSourceMasterResp,
  masterNtuReason,
  getNtuReasonMasterResponse,
  updatePatientHeaderData,
}: AllProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [referralId, setReferralId] = useState<string>("");

  const [saveAndExitBtnFlag, setSaveAndExitBtnFlag] = useState<boolean>(false);
  const [authorizationDetailTabs, setAuthorizationDetailTabs] =
    useState<AuthorizationDetailTabs>();
  const {
    loading: authorizationDetailTabsLoading,
    response: authorizationDetailTabsResponse,
  } = authorizationDetailTabsData;
  const { response: reAuthTimelineResponse } = reAuthTimeline;
  const {
    response: putAuthorizationResponse,
    error: putAuthorizationRespError,
  } = putAuthorizationDetailTabResp;
  const { response: ntuReasonResponse } = masterNtuReason;

  const { loading: priorAuthReferralSourceLoading } = priorAuthReferralSource;
  const { response: updatedPatientHeaderResponse } = updatePatientHeaderData;
  const navigate = useNavigate();

  useEffect(() => {
    if (!priorAuthReferralSourceLoading) {
      getPriorAuthReferralSourceMasterResp();
    }
  }, []);

  useEffect(() => {
    if (authorizationDetailTabsResponse) {
      setAuthorizationDetailTabs(authorizationDetailTabsResponse);
      reset(authorizationDetailTabsResponse);
      if (
        authorizationDetailTabsResponse.authorizationGeneralInfoDto
          .referralStatusId == 0
      ) {
        setValue("authorizationGeneralInfoDto.referralStatusId", null);
      }
      if (
        authorizationDetailTabsResponse.authorizationGeneralInfoDto.urgencyId ==
        0
      ) {
        setValue("authorizationGeneralInfoDto.urgencyId", null);
      }
      if (
        authorizationDetailTabsResponse.authorizationGeneralInfoDto
          .staffingStatusId == 0
      ) {
        setValue("authorizationGeneralInfoDto.staffingStatusId", null);
      }
      if (
        !isNull(
          getValue(
            authorizationDetailTabsResponse,
            "authorizationGeneralInfoDto.referralNtuReasonId"
          ) && !length(ntuReasonResponse)
        )
      ) {
        getNtuReasonMasterResponse();
      }
    }
  }, [authorizationDetailTabsResponse]);

  useEffect(() => {
    if (
      putAuthorizationDetailTabResp.loading === false &&
      putAuthorizationDetailTabResp.response &&
      saveAndExitBtnFlag === true
    ) {
      if (getValue(patientData, "response.patientId", "")) {
        navigate(
          ModuleLinks(PatientRecordsModule.PATIENT_DETAILS_FORM, {
            patientId: patientData.response.patientId,
          })
        );
      }
    } else if (
      putAuthorizationDetailTabResp.loading === false &&
      putAuthorizationDetailTabResp.response &&
      referralId
    ) {
      getAuthorizationDetailTabs(referralId);
    }
  }, [putAuthorizationDetailTabResp]);

  const methods = useForm<any>({
    defaultValues: AuthorizationDetailTabsInitialValue,
    mode: "all",
  });

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    clearErrors,
    trigger,
    setError,
    formState,
  } = methods;

  const {
    fields: icd10RecordsFields,
    append: icd10RecordsAppend,
    remove: icd10RecordsRemove,
  } = useFieldArray({
    control,
    name: "icdCodesInfoDto",
  });

  useEffect(() => {
    if (!isNil(putAuthorizationRespError)) {
      serverSideErrorMsg(putAuthorizationRespError, setError);
    }
  }, [putAuthorizationRespError]);

  useEffect(() => {
    getDisciplinesData(Number(authNo));
  }, [putAuthorizationResponse]);

  useEffect(() => {
    if (
      checkEligibilityData.response != null &&
      checkEligibilityData.loading === false &&
      !isEmpty(
        getValue(
          checkEligibilityData,
          "response.additionalNotes.benefitPlanUid",
          ""
        )
      )
    ) {
      getEligibilityData(
        checkEligibilityData.response.additionalNotes.benefitPlanUid
      );
    }
  }, [checkEligibilityData]);

  const values = getValues();

  useEffect(() => {
    const userName = getValue(user, "email", "");
    setValue("authorizationGeneralInfoDto.userCreated", userName);
  });
  const { authNo } = useParams();
  useEffect(() => {
    setReferralId(authNo ? getReferralId(authNo) : "");
    if (authNo && !isNil(reAuthTimelineResponse[4])) {
      getPatient(
        authNo ? Number(getReferralId(authNo)) : 0,
        PatientDetailType.Referral
      );
    }
  }, [getValue(reAuthTimelineResponse, "[4]"), updatedPatientHeaderResponse]);

  useEffect(() => {
    if (!serviceProviderDetails.loading) {
      getServiceProviderData(Number(authNo));
    }
    return () => {
      resetStates([
        ReAuthTimelineActionDispatchTypes.REAUTH_TIMELINE_RESET,
        ResumeIntakeActionDispatchTypes.RESUME_INTAKE_RESET,
      ]);
      eligibilityReset();
    };
  }, []);

  useEffect(() => {
    setReferralId(authNo ? getReferralId(authNo) : "");

    if (!authorizationDetailTabsLoading && authNo) {
      getAuthorizationDetailTabs(authNo);
    }
  }, []);

  const handleCreateReferral = () => {
    resetStates([
      ReAuthReferralRequestDispatchType.RE_AUTH_REFERRAL_REQUEST_RESET,
      PatientInformationReceivedActionDispatchTypes.INFORMATION_RECEIVED_RESET,
      EligibilityCheckDispatchTypes.CHECK_ELIGIBILITY_RESET,
      EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_RESET,
    ]);
    setOpenModal(true);
  };

  const SaveAuthorizationDetailTab = (data: AuthorizationDetailTabs) => {
    data.referralSource.dischargeDate =
      data.authorizationGeneralInfoDto.dischargeDate;
    const updatedResponse = {
      ...data,
      authorizationGeneralInfoDto: {
        ...data.authorizationGeneralInfoDto,
        serviceAddress: {
          ...data.authorizationGeneralInfoDto.serviceAddress,
          patientState: matchState(
            getConcatStringOrNull(
              data.authorizationGeneralInfoDto.serviceAddress.patientState
            )
          ),
        },
      },
    };
    saveAuthorizationDetailTab(updatedResponse, referralId);
  };

  const handleBackOp = () => {
    if (getValue(patientData, "response.patientId", "")) {
      navigate(
        ModuleLinks(PatientRecordsModule.PATIENT_DETAILS_FORM, {
          patientId: patientData.response.patientId,
        })
      );
    }
  };

  const savePatientData = () => {
    resetStates([UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_RESET]);
    setSaveAndExitBtnFlag(false);
    handleSubmit(SaveAuthorizationDetailTab)();
  };

  const saveAndExitOp = () => {
    resetStates([UpdateDisciplineActionDispatchTypes.UPDATE_DISCIPLINE_RESET]);
    setSaveAndExitBtnFlag(true);
    handleSubmit(SaveAuthorizationDetailTab)();
  };

  return (
    <>
      <Breadcrumb
        authDetails
        patientId={getValue(patientData, "response.patientId")}
      />
      <HeaderBox>
        <Box>
          <InnerBox>
            <Button variant="contained" sx={BackBtn} onClick={handleBackOp}>
              BACK
            </Button>
            <Typography
              variant="h4"
              fontWeight={fontWeight.Weight[6]}
              color={colors.black[2]}
              sx={HeaderText}
            >
              AUTHORIZATION DETAILS
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography
              variant="subtitle2"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[5]}
              sx={HeaderAuthText}
            >
              SELECTED AUTHORIZATION:
            </Typography>
            <Typography
              variant="body1"
              color={colors.black[2]}
              fontWeight={fontWeight.Weight[5]}
              sx={HeaderAuthText}
            >
              Auth ID:
            </Typography>
            <Typography
              variant="body1"
              color={colors.fonts[18]}
              fontWeight={fontWeight.Weight[6]}
              sx={AuthText}
            >
              {getValue(
                authorizationDetailTabsResponse,
                "authorizationGeneralInfoDto.authorizationNumber"
              )}
            </Typography>
          </InnerBox>
        </Box>
        <Box>
          <Permission
            controlId={`${AuthDetailsAccess.AUTH_DETAILS_CREATE_RE_AUTH_REQUEST}||`}
          >
            <Button
              variant="outlined"
              onClick={() => handleCreateReferral()}
              disabled={
                getValue(patientData, "response.isPrioAuthCompleted")
                  ? false
                  : true
              }
            >
              CREATE RE-AUTH. REQUEST
            </Button>
          </Permission>
          <Permission
            controlId={`||${AuthDetailsAccess.AUTH_DETAILS_SAVE_PATIENT_DATA_UPDATE}`}
          >
            <Button variant="contained" onClick={savePatientData}>
              SAVE PATIENT DATA
            </Button>
          </Permission>
          <Permission
            controlId={`||${AuthDetailsAccess.AUTH_DETAILS_SAVE_AND_EXIT_UPDATE}`}
          >
            <Button variant="contained" onClick={saveAndExitOp}>
              SAVE AND EXIT
            </Button>
          </Permission>
        </Box>
      </HeaderBox>
      <PatientHeader patientData={patientData} />
      <PatientAuthorizationDetails
        watch={watch}
        icdvalues={values}
        authValues={values}
        authorizationDetailTabs={authorizationDetailTabsResponse}
        getValues={getValues}
        icd10RecordsFields={icd10RecordsFields}
        icd10RecordsAppend={icd10RecordsAppend}
        icd10RecordsRemove={icd10RecordsRemove}
        reset={reset}
        icd10Response={
          authorizationDetailTabs && authorizationDetailTabs.icdCodesInfoDto
        }
        control={control}
        setValue={setValue}
        generalInfoValues={values}
        authPastMedicalHistoryInfo={authorizationDetailTabs}
        patientData={patientData}
        pageStatus="reauthRequest"
        checkEligibilityData={checkEligibilityData}
        clearErrors={clearErrors}
        trigger={trigger}
        formState={formState}
        getAuthorizationDetailTabs={getAuthorizationDetailTabs}
      />
      <Box sx={FooterBox}>
        <Permission
          controlId={`${AuthDetailsAccess.AUTH_DETAILS_CREATE_RE_AUTH_REQUEST}||`}
        >
          <Button
            variant="outlined"
            onClick={() => handleCreateReferral()}
            disabled={
              getValue(patientData, "response.isPrioAuthCompleted")
                ? false
                : true
            }
          >
            CREATE RE-AUTH. REQUEST
          </Button>
        </Permission>
        <Permission
          controlId={`||${AuthDetailsAccess.AUTH_DETAILS_SAVE_PATIENT_DATA_UPDATE}`}
        >
          <Button variant="contained" onClick={savePatientData}>
            SAVE PATIENT DATA
          </Button>
          <Button variant="contained" onClick={saveAndExitOp}>
            SAVE AND EXIT
          </Button>
        </Permission>
      </Box>
      <CreateModal
        openModal={openModal}
        closeModal={setOpenModal}
        serviceProviderDetails={serviceProviderInfo.response}
        referralId={referralId}
        checkEligibility={checkEligibility}
        patientData={patientData}
        payerState={payerState}
      />
    </>
  );
};

type ModalProps = {
  openModal: boolean;
  closeModal: (onClose: boolean) => void;
  serviceProviderDetails: ServiceProviderPayload;
  referralId: string;
  checkEligibility: (payload: HealthPlanPayload) => void;
  patientData: PatientInformationViewResponse;
  payerState: PatientFilterOptions[];
};

const CreateModal = ({
  openModal,
  closeModal,
  serviceProviderDetails,
  checkEligibility,
  patientData,
}: ModalProps) => {
  const handleClose = () => {
    closeModal(false);
  };
  const handleCheckEligibility = (
    patientData: PatientInformationViewResponse
  ) => {
    const name =
      patientData && patientData.response.patientName.split(" ").reverse();
    const payload = {
      lastName: name && name[0] ? name[0] : "",
      dob: formatDate(
        getValue(patientData, "response.memberInfo.dateOfBirth"),
        DateFormats.YYYY_MM_DD
      ),
      payerId: getValue(patientData, "response.eligibilityPayerId"),
      policyId: getValue(patientData, "response.memberInfo.subscriberId"),
      payerName: getValue(patientData, "response.eligibilityPayerName"),
    };
    payload.policyId && checkEligibility(payload);
    closeModal(false);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={ModalGridContainer}
      >
        <Box sx={ModalGrid}>
          <Grid container sx={ContentBox}>
            <Grid item xs={11} sx={ModalHeaderBox}>
              <Typography
                variant="h2"
                color={colors.fonts[4]}
                fontWeight={fontWeight.Weight[5]}
                sx={ModalTitle}
              >
                Create referral confirmation:
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconFaTimesCircle onClick={handleClose} />
            </Grid>
          </Grid>
          <Grid container sx={Outerbodygrid}>
            <Grid item xs={12} sx={Gridbody}>
              <Grid container>
                <Grid item xs={12} sx={ContentBox} pl={"1rem"}>
                  <Box sx={QuestionIcon}>
                    <FaRegQuestionCircle />
                  </Box>
                  <Box>
                    <Typography
                      variant="h3"
                      color={colors.black[3]}
                      fontWeight={fontWeight.Weight[4]}
                      sx={Question}
                    >
                      Is this the same agency requesting Reauthorizaton?
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={GridData}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[2]}
                      sx={InfoBox}
                    >
                      Provider Name:
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight={fontWeight.Weight[6]}
                      color={colors.black[5]}
                      sx={Info}
                    >
                      {getValue(serviceProviderDetails, "providerName", "")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={0.2} sx={GridData}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={DetailsDivider}
                  />
                </Grid>
                <Grid item xs={2.2} sx={GridData}>
                  <Box sx={npiBox}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[2]}
                      sx={InfoBox}
                    >
                      NPI:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.black[5]}
                      sx={Info}
                    >
                      {getValue(serviceProviderDetails, "providerNpi", "")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4.8} sx={GridData}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[3]}
                      color={colors.fonts[2]}
                      sx={InfoBox}
                    >
                      Address:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[4]}
                      color={colors.black[5]}
                      sx={Info}
                    >
                      {[
                        getValue(serviceProviderDetails, "streetName1", ""),
                        getValue(serviceProviderDetails, "streetName2", ""),
                        getValue(serviceProviderDetails, "providerCounty", ""),
                        getValue(serviceProviderDetails, "providerState", ""),
                        getValue(serviceProviderDetails, "providerCity", ""),
                        getValue(serviceProviderDetails, "zipCode", ""),
                      ]
                        .filter((address) => address)
                        .toString()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={GridFooter}>
              <Box>
                <Button variant="outlined" onClick={handleClose}>
                  NO
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleCheckEligibility(patientData)}
                >
                  Yes Check Eligibility
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
