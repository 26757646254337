import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

import {
  activeButton,
  inactiveButton,
  EligibilitytableCellDynamic,
  NoRecordsCell,
  PaginationGrid,
  policyButton,
  StyledTableHead,
  tableCell,
  tableCellDynamic,
  tableCellStyle,
} from "../styles/AuthorizationHistory";
import { colors } from "../../../../../styles/colors";
import { EligibilityHistoryResponse } from "../../../../../models/Api/AuthorizationHistory";
import { EligibilityHistoryHeaderLabel } from "../../../../../constants/AllPatientRecord";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AuthAndEligibilityHistoryPayload,
  EligibilityHistory,
} from "../../../../../models/AuthorizationHistory";
import { formatDate } from "../../../../../utils/DateTime";
import { PER_PAGE } from "../../../../../constants/FunctionalUtils";
import PaginationItem from "../../../../../components/custom/pagination/components/PaginationItem";
import { DateFormats } from "../../../../../constants/Date";

export interface PropsFromState {
  eligibilityHistoryResp: EligibilityHistoryResponse;
  patientId: any;
}

export interface PropsFromDispatch {
  getEligibilityHistoryRecord: (
    payload: AuthAndEligibilityHistoryPayload
  ) => void;
  setPageNumber: (value: number) => void;
}

type Props = PropsFromState & PropsFromDispatch;

const EligibilityHistoryData: React.FC<Props> = ({
  eligibilityHistoryResp,
  getEligibilityHistoryRecord,
  patientId,
  setPageNumber,
}: Props) => {
  const { response: EligibilityHistoryResponse } = eligibilityHistoryResp;

  const [page, setPage] = useState(1);
  const count = Math.ceil(
    EligibilityHistoryResponse && EligibilityHistoryResponse.totalRows
      ? Number(EligibilityHistoryResponse.totalRows) / PER_PAGE
      : 1 / PER_PAGE
  );

  useEffect(() => {
    getRecords(Number(patientId), page, PER_PAGE);
  }, []);

  const handlePageChange = (_e: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    setPageNumber(page);
    getRecords(Number(patientId), page, PER_PAGE);
  };

  const getRecords = (patientId: number, pageNo: number, pageSize: number) => {
    getEligibilityHistoryRecord({
      patientId,
      contains: true,
      pageNo,
      pageSize,
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} pl={"2rem"} pb={"1rem"}>
          <Typography
            variant={"h6"}
            fontWeight={fontWeight.Weight[5]}
            color={colors.fonts[19]}
            lineHeight={"1.5rem"}
            pb={"0.5rem"}
          >
            Eligibility LOG
          </Typography>
        </Grid>
        <Grid item xs={12} pl={"1rem"} pb={"1rem"}>
          <TableContainer component={Paper}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  {EligibilityHistoryHeaderLabel.map(
                    (data: any, index: number) => (
                      <TableCell
                        key={index}
                        sx={data.flag ? tableCellStyle : tableCell}
                      >
                        <Typography
                          color={colors.black[16]}
                          variant="body1"
                          fontWeight={fontWeight.Weight[3]}
                        >
                          {data.label}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {rowRender(
                  EligibilityHistoryResponse?.patientEligibilityHistory
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sx={PaginationGrid}>
          <PaginationItem
            count={count}
            page={page}
            handlePageChange={handlePageChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
const rowRender = (
  eligibilityHistoryData: EligibilityHistory[] | undefined
) => {
  return (
    <>
      {eligibilityHistoryData && eligibilityHistoryData.length ? (
        eligibilityHistoryData.map(
          (item: EligibilityHistory, index: number) => (
            <TableRow key={index}>
              {cellRender(
                formatDate(item.eligibilityCheckDate),
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRender(
                item.healthPlan,
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRender(
                item.policyId,
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRender(
                item.product,
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRender(
                item.benefitPlan,
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRender(
                item.patientState,
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRender(
                item.medicareBeneficiaryIdentifier,
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRender(
                item.ahcccsId,
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRender(
                item.patientStatus,
                item.patientStatus,
                true,
                eligibilityHistoryData.length - 1 === index
              )}
              {cellRenderDate(
                formatDate(item.effectiveDate, DateFormats.MM_DD_YYYY),
                item.patientStatus,
                false,
                eligibilityHistoryData.length - 1 === index,
                formatDate(item.effectiveThrough, DateFormats.MM_DD_YYYY)
              )}
            </TableRow>
          )
        )
      ) : (
        <TableRow>
          <TableCell align="center" colSpan={14}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[3]}
              color={colors.fonts[4]}
              sx={NoRecordsCell}
            >
              NO RECORDS FOUND
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
const cellRender = (
  data: any,
  referralStatusName: string,
  flag: boolean,
  removeBorder: boolean
) => {
  return (
    <>
      <TableCell
        sx={
          flag === false
            ? tableCellDynamic(flag, removeBorder)
            : EligibilitytableCellDynamic(flag, removeBorder)
        }
      >
        <Typography
          variant="subtitle1"
          fontWeight={fontWeight.Weight[5]}
          color={colors.fonts[4]}
          sx={
            flag && referralStatusName === "Eligible"
              ? activeButton
              : flag && referralStatusName === "Not Eligible"
              ? inactiveButton
              : {}
          }
        >
          {data}
        </Typography>
      </TableCell>
    </>
  );
};

const cellRenderDate = (
  data: any,
  referralStatusName: string,
  flag = false,
  removeBorder: boolean,
  effectiveThrough: string
) => {
  return (
    <>
      <TableCell
        sx={
          flag === false
            ? tableCellDynamic(flag, removeBorder)
            : EligibilitytableCellDynamic(flag, removeBorder)
        }
      >
        <Typography
          variant="subtitle1"
          fontWeight={
            referralStatusName === "Eligible"
              ? fontWeight.Weight[5]
              : fontWeight.Weight[3]
          }
          color={colors.fonts[4]}
          sx={
            flag
              ? referralStatusName === "Eligible"
                ? activeButton
                : inactiveButton
              : referralStatusName === "Not Eligible"
              ? policyButton
              : {}
          }
        >
          {data}
          {effectiveThrough ? "  -" : ""}
          {effectiveThrough}
        </Typography>
      </TableCell>
    </>
  );
};

export default EligibilityHistoryData;
