import { useEffect, useRef, useState } from "react";
import { Control, Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";

import { flexAlignCentre } from "../../../../../styles/mui/styles/display";
import { FormInputText } from "../../../../../components/formComponents/FormInputText";
import { rules } from "../../../../../utils/validation/Validation";
import { PhoneFaxExtInput } from "../../../../../components/formComponents/PhoneFaxExtInput";
import {
  AdditionalInformationTextArea,
  AdditionalInformationTextBox,
  DescriptionStyle,
  FaxGridBody,
  SendFaxGridBody,
} from "../../AuthorizationDetails/styles/Disciplines";
import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  ButtonFormControlFax,
  CancelButton,
  SendFaxDocumentTypeIcon,
  SendFaxFields,
  SendFaxHeading,
  SendFaxGridContainer,
} from "../styles/DocumentReview";
import { colors } from "../../../../../styles/colors";
import {
  AddFilesSubtitle,
  FileHeaderGrid,
  FileUploadBox,
  FlexBox,
  FormLabelSpace,
} from "../styles/Document";
import FileUpload from "../../../../../components/formComponents/FileUpload";
import {
  FaxFormMaster,
  FaxFormOptionMaster,
} from "../../../../../models/Master";
import { PostInitiateFaxActionDispatchTypes } from "../../../../../constants/Authorization";
import { UserDetailsPayload } from "../../../../../models/User";
import { inputType } from "../../../../../constants/Constants";
import {
  extractDocType,
  getList,
  getValue,
  isNil,
  length,
  smoothScroll,
} from "../../../../../utils";
import { DocumentTabType } from "../../../../../constants/Document";
import { InitiateFaxSendingResponse } from "../../../../../models/Api/Authorization";
import { getDocTypeIcon } from "../../../../../utils/DocIcons";
import { documentFaxMaxSize, documentMaxSize, documentMinSize } from "../../../../../constants/Config";
import { BlobData } from "../../../../../models/Document";
import {
  HumanaPeerToPeer,
  OtherHealthPlans,
  PartialApprovalPeerToPeer,
} from "../../AuthorizationDetails/components/SendFaxTemplates";
import {
  faxDefaultValues,
  FaxFormTemplate,
} from "../../../../../constants/Fax";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { PatientInformationView } from "../../../../../models/Patient";
import { formatDate } from "../../../../../utils/DateTime";
import { DateFormats } from "../../../../../constants/Date";
import { FaxFormOptionMasterActionDispatchTypes } from "../../../../../constants/Master";
import { SendFax } from "../../../../../models/Authorization";

export interface DocSendFaxProps {
  saveInitiateFax: (payload: FormData) => void;
  setOpenFax: React.Dispatch<React.SetStateAction<boolean>>;
  openFax: boolean;
  user: UserDetailsPayload;
  resetStates: (actionType: string[]) => void;
  faxFiles: any;
  setFileForFax: React.Dispatch<React.SetStateAction<any>>;
  isEdit: boolean;
  faxSendResponse: InitiateFaxSendingResponse;
  faxBlobId: number;
  addDocForFax: boolean;
  setAddDocForFax: React.Dispatch<React.SetStateAction<boolean>>;
  setEditFaxDocName: React.Dispatch<React.SetStateAction<string>>;
  editFaxDocName: string;
  editFaxDocMediaType: string;
  setEditFaxDocMediaType: React.Dispatch<React.SetStateAction<string>>;
  faxRef: React.MutableRefObject<any>;
  setSelectedFaxBlobIds: React.Dispatch<React.SetStateAction<BlobData[]>>;
  selectedFaxBlobIds: BlobData[];
}

export interface PropsFromDispatch {
  getFaxFormMaster: () => void;
  getFaxFormOptionMaster: (formId: number) => void;
}
export interface PropsFromState {
  faxFormList: FaxFormMaster[];
  faxFormOptionMaster: FaxFormOptionMaster;
  patientData: PatientInformationView;
}

type AllProps = PropsFromState & PropsFromDispatch & DocSendFaxProps;

const DocumentSendFax: React.FC<AllProps> = ({
  saveInitiateFax,
  setOpenFax,
  resetStates,
  faxFiles,
  setFileForFax,
  isEdit,
  faxSendResponse,
  faxBlobId,
  addDocForFax,
  setAddDocForFax,
  editFaxDocName,
  editFaxDocMediaType,
  faxRef,
  setSelectedFaxBlobIds,
  selectedFaxBlobIds,
  faxFormList,
  getFaxFormMaster,
  user,
  getFaxFormOptionMaster,
  faxFormOptionMaster,
  patientData,
}) => {
  const [faxFormOption, setFaxFormOption] =
    useState<FaxFormOptionMaster | null>(null);
  const documentUploadRef = useRef<HTMLDivElement>(null);

  const patientName = getValue(patientData, "patientName", "");
  const patientDob = formatDate(
    getValue(patientData, "memberInfo.dateOfBirth", null),
    DateFormats.MM_DD_YYYY
  );

  const maxFileSizeInMegaBytes = documentMaxSize.split("MB")[0];
  const minFileSize = Number(documentMinSize.split("KB")[0]);
  const maxFileSizeInBytes = Number(maxFileSizeInMegaBytes) * 1000000;
  const maxFileSizeInMB = maxFileSizeInBytes
    ? maxFileSizeInBytes / 1000000
    : 1000000;
  const { authNo } = useParams();
  const { response: DocSentResponse } = faxSendResponse;

  const faxFormData = getList(faxFormList, {
    label: "faxFormName",
    value: "faxFormId",
  });

  const faxMethods = useForm<SendFax>({
    defaultValues: faxDefaultValues,
    mode: "all",
  });

  const {
    handleSubmit: faxHandleSubmit,
    control: faxControl,
    watch: faxWatch,
    setValue: faxSetValue,
    reset,
  } = faxMethods;

  const faxFormId = faxWatch("faxFormId");

  useEffect(() => {
    if (!isNil(DocSentResponse) && DocSentResponse.referralBlobId !== -1) {
      setFileForFax([]);
      setOpenFax(false);
      setAddDocForFax(false);
      resetStates([PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_RESET]);
    }
  }, [DocSentResponse]);

  useEffect(() => {
    getFaxFormMaster();
    faxSetValue("faxFrom", user.fullName);

    return () => {
      resetStates([
        FaxFormOptionMasterActionDispatchTypes.GET_FAX_FORM_OPTION_MASTER_RESET,
      ]);
    };
  }, []);

  useEffect(() => {
    if (faxFormOptionMaster) {
      setFaxFormOption(faxFormOptionMaster);
    }
  }, [faxFormOptionMaster]);

  useEffect(() => {
    if (faxFormId) {
      getFaxFormOptionMaster(faxFormId);

      reset((formValues: SendFax) => ({
        ...formValues,
        [FaxFormTemplate.NOTICE_TO_REFERRAL]: "",
        [FaxFormTemplate.HOME_HEALTH_SERVICES]: "",
        [FaxFormTemplate.OTHER_REASON]: "",
      }));
    }
    return () => {
      resetStates([
        FaxFormOptionMasterActionDispatchTypes.GET_FAX_FORM_OPTION_MASTER_RESET,
      ]);
    };
  }, [faxFormId]);

  const addNewDocumentForFax = () => {
    setFileForFax([]);
    setAddDocForFax(true);
    setTimeout(() => {
      if (documentUploadRef.current != null) {
        smoothScroll(getValue(documentUploadRef, "current.offsetTop"));
      }
    }, 500);
  };

  const handleSendFax = (data: SendFax) => {
    const formData = new FormData();
    formData.append("referralFaxParameterId", "0");
    formData.append("referralId", authNo ? authNo : "");
    formData.append("referenceId", "");
    formData.append(
      "blobIdList",
      faxBlobId === 0
        ? ""
        : selectedFaxBlobIds.map((id) => id.blobId).toString()
    );

    formData.append("faxToNumber", getValue(data, "faxNumber"));
    formData.append("faxToName", getValue(data, "faxToName"));
    formData.append("faxFrom", getValue(data, "faxFrom"));
    formData.append("faxFormId", getValue(data, "faxFormId", null));
    formData.append("faxFromName", "");
    formData.append(
      FaxFormTemplate.HOME_HEALTH_SERVICES,
      getValue(data, FaxFormTemplate.HOME_HEALTH_SERVICES)
    );
    formData.append(
      FaxFormTemplate.NOTICE_TO_REFERRAL,
      getValue(data, FaxFormTemplate.NOTICE_TO_REFERRAL, null)
    );
    formData.append(
      FaxFormTemplate.OTHER_REASON,
      getValue(data, FaxFormTemplate.OTHER_REASON, null)
    );

    formData.append("additionalInformation", getValue(data, "notes"));
    formData.append("faxStatus", "");
    if (faxFiles && faxFiles.length > 0) {
      for (let i = 0; i < faxFiles.length; i++) {
        formData.append("faxFile", faxFiles[i], faxFiles[i].name);
      }
    } else {
      formData.append("faxFile", "");
    }
    saveInitiateFax(formData);
    resetStates([PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_RESET]);
  };

  const handleCloseSendFax = () => {
    setSelectedFaxBlobIds([]);
    setOpenFax(false);
    setAddDocForFax(false);
  };

  const handleDocFaxCancel = () => {
    setSelectedFaxBlobIds([]);
    setFileForFax([]);
    setAddDocForFax(false);
  };

  const renderTemplate = (templateId: number) => {
    const homeHealthServices = getValue(
      faxFormOption,
      FaxFormTemplate.HOME_HEALTH_SERVICES,
      []
    );
    const noticeToReferral = getValue(
      faxFormOption,
      FaxFormTemplate.NOTICE_TO_REFERRAL,
      []
    );

    switch (templateId) {
      case 1:
        if (!length(homeHealthServices) && !length(noticeToReferral)) {
          return null;
        }
        return (
          <Grid item xs={12} pl={"3rem"} pt={"0.8rem"}>
            <HumanaPeerToPeer
              control={faxControl}
              homeHealthServices={homeHealthServices}
              noticeToReferral={noticeToReferral}
              patientName={patientName}
              patientDob={patientDob}
              setValue={faxSetValue}
            />
          </Grid>
        );
      case 2:
        if (!length(homeHealthServices)) {
          return null;
        }
        return (
          <Grid item xs={12} pl={"3rem"} pt={"0.8rem"}>
            <PartialApprovalPeerToPeer
              control={faxControl}
              homeHealthServices={homeHealthServices}
              patientName={patientName}
              patientDob={patientDob}
              setValue={faxSetValue}
            />
          </Grid>
        );
      case 3:
        if (!length(homeHealthServices) && !length(noticeToReferral)) {
          return null;
        }
        return (
          <Grid item xs={12} pl={"3rem"} pt={"0.8rem"}>
            <OtherHealthPlans
              control={faxControl}
              homeHealthServices={homeHealthServices}
              noticeToReferral={noticeToReferral}
              patientName={patientName}
              patientDob={patientDob}
              setValue={faxSetValue}
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={SendFaxGridBody} ref={faxRef}>
      <Grid container sx={FaxGridBody}>
        <Grid item xs={12} pr={"2rem"}>
          <Grid container sx={SendFaxHeading}>
            <Grid item xs={9.6}>
              <Typography
                variant="h4"
                fontWeight={fontWeight.Weight[6]}
                color={colors.fonts[20]}
              >
                SEND FAX:
              </Typography>
            </Grid>
            <Grid item xs={2.4}>
              <Button
                variant="contained"
                onClick={() => addNewDocumentForFax()}
                disabled={addDocForFax}
              >
                UPLOAD DOCUMENT
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={SendFaxFields}>
            <Grid item xs={2.3}>
              <FormInputText
                name="faxToName"
                control={faxControl}
                label="To:"
                helper={rules.FaxTo}
                placeholder="Add here..."
                textLength={100}
                multiline={true}
              />
            </Grid>
            <Grid item xs={2.3}>
              <FormInputText
                name="faxFrom"
                control={faxControl}
                label="From:"
                textLength={100}
                multiline={true}
                placeholder="Add here..."
                helper={rules.FaxFrom}
              />
            </Grid>
            <Grid item xs={2.3}>
              <FormInputDropdown
                name="faxFormId"
                control={faxControl}
                label="Fax Form:"
                list={faxFormData}
              />
            </Grid>
            <Grid item xs={2.6}>
              <PhoneFaxExtInput
                label="Fax:"
                control={faxControl}
                phoneFaxName={`faxNumber`}
                rules={rules.sendFax}
                isFax
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={AdditionalInformationTextBox}>
            <Typography
              variant="body1"
              fontWeight={fontWeight.Weight[2]}
              color={colors.fonts[2]}
            >
              Additional Information:
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[2]}
              color={colors.red[100]}
              pl={"0.3rem"}
            >
              *
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} pl={"3rem"}>
          <FormControl sx={DescriptionStyle} variant="standard">
            <FormInputText
              name="notes"
              control={faxControl}
              inputVariant="outlined"
              inputRows={4}
              multiline={true}
              textLength={500}
              helper={rules.FaxAdditionalInfo}
              InputStyle={AdditionalInformationTextArea}
            />
          </FormControl>
        </Grid>
        {faxFormId && renderTemplate(faxFormId)}
        {addDocForFax && (
          <Grid item xs={12}>
            <UploadDocument
              faxBlobId={faxBlobId}
              maxFileSizeInMB={maxFileSizeInMB}
              faxFiles={faxFiles}
              setFileForFax={setFileForFax}
              isEdit={isEdit}
              maxFileSizeInBytes={maxFileSizeInBytes}
              faxControl={faxControl}
              editFaxDocName={editFaxDocName}
              editFaxDocMediaType={editFaxDocMediaType}
              selectedFaxBlobIds={selectedFaxBlobIds}
              handleDocFaxCancel={handleDocFaxCancel}
              documentUploadRef={documentUploadRef}
              minFileSize={minFileSize}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl variant="standard" sx={ButtonFormControlFax}>
            <Button
              variant="outlined"
              onClick={handleCloseSendFax}
              sx={CancelButton}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={faxHandleSubmit(handleSendFax)}
            >
              SEND FAX
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

type UploadDocumentProps = {
  faxBlobId: number;
  maxFileSizeInMB: number;
  faxFiles: any;
  setFileForFax: React.Dispatch<React.SetStateAction<any>>;
  isEdit: boolean;
  maxFileSizeInBytes: number;
  faxControl: Control<any, any>;
  editFaxDocName: string;
  editFaxDocMediaType: string;
  selectedFaxBlobIds: BlobData[];
  handleDocFaxCancel: () => void;
  documentUploadRef: React.RefObject<HTMLDivElement>;
  minFileSize: number;
};

const UploadDocument = ({
  faxBlobId,
  maxFileSizeInMB,
  faxFiles,
  setFileForFax,
  isEdit,
  maxFileSizeInBytes,
  faxControl,
  selectedFaxBlobIds,
  editFaxDocMediaType,
  editFaxDocName,
  handleDocFaxCancel,
  documentUploadRef,
  minFileSize
}: UploadDocumentProps) => {
  const typography = (value: string, sx?: any) => {
    return (
      <Typography
        variant="body1"
        fontWeight={fontWeight.Weight[3]}
        color={colors.black[5]}
        sx={sx}
      >
        {value}
      </Typography>
    );
  };

  const documentTypography = (value: string) => {
    return (
      <Typography
        variant="h5"
        fontWeight={fontWeight.Weight[6]}
        color={colors.primary.main}
      >
        {value}
      </Typography>
    );
  };

  const sendFaxDocument = (docName: string) => {
    return (
      <Box sx={flexAlignCentre}>
        <Box sx={SendFaxDocumentTypeIcon}>
          {getDocTypeIcon(extractDocType(editFaxDocMediaType))}
        </Box>
        {documentTypography(docName)}
      </Box>
    );
  };

  return (
    <Grid container ref={documentUploadRef}>
      <Grid item xs={12} sx={SendFaxGridContainer}>
        <Grid item xs={4.5} sx={FileHeaderGrid}>
          <Grid container sx={FileUploadBox} spacing={3}>
            {faxBlobId === 0 ? (
              <Grid item xs={6}>
                <Box sx={FlexBox}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={fontWeight.Weight[5]}
                    color={colors.fonts[20]}
                    sx={FormLabelSpace}
                  >
                    Add files:
                  </Typography>
                  {typography("Allowed file types (.pdf, .jpeg, .jpg, .png)")}
                </Box>
                <Box sx={FlexBox}>
                  {typography(
                    `Max allowed size for each file: ${maxFileSizeInMB}MB`,
                    AddFilesSubtitle
                  )}
                </Box>
                <Box sx={FlexBox}>
                  {typography(
                    `Max allowed size for all files: ${documentFaxMaxSize}MB`,
                    AddFilesSubtitle
                  )}
                </Box>
                <FileUpload
                  inputType={inputType.referralDocs}
                  files={faxFiles}
                  setFiles={setFileForFax}
                  type={DocumentTabType.AUTH_DETAILS}
                  maxFileSizeInBytes={maxFileSizeInBytes}
                  minFileSize={minFileSize}
                />
                {!isEdit && (
                  <Controller
                    defaultValue={""}
                    control={faxControl}
                    name="faxFile"
                    render={() => <FormHelperText></FormHelperText>}
                  />
                )}
              </Grid>
            ) : (
              <Grid item xs={6}>
                <Grid container>
                  {selectedFaxBlobIds.length === 0 && faxBlobId > -1 ? (
                    <Grid item xs={12}>
                      {sendFaxDocument(editFaxDocName)}
                    </Grid>
                  ) : selectedFaxBlobIds.length > 0 ? (
                    selectedFaxBlobIds.map((name: any) => (
                      <Grid item xs={12} key={name}>
                        {sendFaxDocument(name.fileName)}
                      </Grid>
                    ))
                  ) : (
                    <>{documentTypography("Please select Files")}</>
                  )}
                </Grid>
              </Grid>
            )}
            {faxBlobId === 0 && (
              <Grid item xs={2.5} mt={"5.2rem"}>
                <FormControl variant="standard">
                  <Button
                    variant="outlined"
                    onClick={handleDocFaxCancel}
                    sx={CancelButton}
                  >
                    CANCEL
                  </Button>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentSendFax;
