export enum DateFormats {
  MM_DD_YYYY = "MM/DD/YYYY",
  MM_DD_YYYY_h_mm_a = "MM/DD/YYYY h:mm a",
  MM_DD_YYYY_hh_mm_ss_a = "MM/DD/YYYY hh:mm:ss a",
  MM_DD_YYYY_comma_hh_mm_ss_a = "MM/DD/YYYY, hh:mm:ss a",
  TIME_AM_PM = "hh:mm a",
  YYYY_MM_DD = "YYYY-MM-DD",
  YYYYMMDD = "YYYYMMDD",
  TIME_HH_MM_SS = "hh:mm:ss",
}

export const dateFormat = "0001-01-01T00:00:00";

const adjudicatedDateTime = "adjudicatedDateTime";
const dateCreated = "dateCreated";
const dateOfBirth = "dateOfBirth";
const dateTimeReceived = "dateTimeReceived";
const dischargeDate = "dischargeDate";
const documentationDate = "documentationDate";
const lastLogin = "lastLogin";
const receivedDateTime = "receivedDateTime";
const referralReceivedDate = "referralReceivedDate";
const referralReceivedTime = "referralReceivedTime";
const requestCreatedOn = "requestCreatedOn";
const requestDateTime = "requestDateTime";
const signedOrderDate = "signedOrderDate";
const startOfCareDate = "startOfCareDate";
const urgencyChangeDateTime = "urgencyChangeDateTime";
const completedOn = "completedOn";
const lastModified = "lastModified";
const decidedDateTime = "decidedDateTime";
const effectiveDate = "effectiveDate";
const effectiveThrough = "effectiveThrough";
const currentStartDate = "currentStartDate";
const currentEndDate = "currentEndDate";
const nextStartDate = "nextStartDate";
const nextEndDate = "nextEndDate";
const facilityDocumentationDate = "facilityDocumentationDate";
const referralCreatedOn = "referralCreatedOn";
const homeBoundDocumentDate = "homeBoundDocumentDate";
const eligibilityCheckDate = "eligibilityCheckDate";
const effectiveThroughDate = "effectiveThroughDate";
const effectiveFromDate = "effectiveFromDate";
const decisionDateTime = "decisionDateTime";

export const FieldType = {
  receivedDateTime,
  referralReceivedDate,
  referralReceivedTime,
  dateCreated,
  dateTimeReceived,
  lastLogin,
  requestCreatedOn,
  requestDateTime,
  urgencyChangeDateTime,
  adjudicatedDateTime,
  completedOn,
  lastModified,
  decidedDateTime,
  eligibilityCheckDate,
  referralCreatedOn,
  decisionDateTime
};

export const DateFieldType = {
  dischargeDate,
  signedOrderDate,
  startOfCareDate,
  documentationDate,
  dateOfBirth,
  effectiveDate,
  effectiveThrough,
  currentStartDate,
  currentEndDate,
  nextStartDate,
  nextEndDate,
  facilityDocumentationDate,
  homeBoundDocumentDate,
  effectiveThroughDate,
  effectiveFromDate,
};
