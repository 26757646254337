import { FaPhone, FaFax, FaUserMd } from "react-icons/fa";
import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { colors } from "../../../../../../../styles/colors";
import { displayFlex } from "../../../../../../../styles/mui/styles/display";
import { Extention } from "../styles/ReAuthReviewRequest";
import { ReAuthReview } from "../../../../../../../models/Patient";
import { ReAuthReviewResponse } from "../../../../../../../models/Api/Review";
import fontWeight from "../../../../../../../styles/mui/fontWeight";
import {
  Address,
  fieldBox,
  FieldLabel,
  GridContainer,
  GridLeftBody,
  GridRightBody,
  HeaderTitle,
  HorizontalDivider,
  Icd10CodeTableBody,
  ICD10TableHead,
  ItemIconBox,
  PhysicianDetailsContainer,
  PhysicianDetailsGrid,
  VerticalDivider,
} from "../styles/ReAuthReviewICD10AndPhysicianDetails";
import { getFormattedPhoneNo } from "../../../../../../../utils";
import { NO_RECORD_FOUND } from "../../../../../../../constants/ToastMessage";
import { PhysicianDetails as PhysicianModel } from "../../../../../../../models/PhysicianDetails";
import { PhysicianDetailsLabel } from "../../../../../../../constants/PhysicianDetails";
import { ReviewRequestorLabel } from "../../../../../../../constants/Review";

export interface PropsFromState {
  reAuthReviewDetails: ReAuthReviewResponse;
}

type ReAuthReviewICD10AndPhysicianDetailsProps = PropsFromState;

const ReAuthReviewICD10AndPhysicianDetails: React.FC<
  ReAuthReviewICD10AndPhysicianDetailsProps
> = ({ reAuthReviewDetails }: ReAuthReviewICD10AndPhysicianDetailsProps) => {
  const [reviewDetails, setReviewDetails] = useState<
    ReAuthReview | undefined
  >();
  const { response } = reAuthReviewDetails;

  const followingPhysician =
    reviewDetails && reviewDetails.reAuthFollowingPhysicianDetail;
  const orderingPhysician =
    reviewDetails && reviewDetails.reAuthOrderingPhysician;
  const primaryPhysician =
    reviewDetails && reviewDetails.reAuthPrimaryPhysician;

  useEffect(() => {
    if (response) {
      setReviewDetails(response);
    }
  }, [response]);

  return (
    <Grid container sx={GridContainer}>
      <Grid item xs={5} sx={GridLeftBody}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
            >
              ICD 10 CODES:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider light sx={HorizontalDivider} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead sx={ICD10TableHead}>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        fontWeight={fontWeight.Weight[3]}
                        color={colors.fonts[4]}
                      >
                        Diagnosis Code
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={Icd10CodeTableBody}>
                  {reviewDetails?.reAuthIcdCode &&
                    reviewDetails.reAuthIcdCode.length > 0 ? (
                    reviewDetails.reAuthIcdCode.map((icdCodeData) => (
                      <TableRow key={icdCodeData.diagnosisTypeId}>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            fontWeight={fontWeight.Weight[5]}
                            color={colors.fonts[25]}
                          >
                            {icdCodeData.diagnosisCode +
                              " - " +
                              icdCodeData.diagnosisCodeDescription}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight={fontWeight.Weight[4]}
                          color={colors.fonts[25]}
                        >
                          {NO_RECORD_FOUND}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={0.2} sx={VerticalDivider}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={6.8} sx={GridRightBody}>
        <Grid container>
          <Grid item xs={12} pl={"1rem"}>
            <Typography
              variant="subtitle1"
              fontWeight={fontWeight.Weight[5]}
              color={colors.fonts[20]}
            >
              PHYSICIAN DETAILS:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container sx={PhysicianDetailsContainer}>
              <PhysicianDetails
                physicianType="PRIMARY"
                reviewDetails={reviewDetails}
                physicianDetails={primaryPhysician}
                divider={true}
              />
              <PhysicianDetails
                physicianType="ORDERING"
                reviewDetails={reviewDetails}
                physicianDetails={orderingPhysician}
                divider={true}
              />
              <PhysicianDetails
                physicianType="FOLLOWUP"
                reviewDetails={reviewDetails}
                physicianDetails={followingPhysician}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const getValue = (object: ReAuthReview | undefined, entity: any) => {
  return object && entity ? entity : "";
};

interface PhysicianDetailsProps {
  physicianType: string;
  reviewDetails: ReAuthReview | undefined;
  physicianDetails: PhysicianModel | undefined;
  divider?: boolean;
}

const PhysicianDetails = ({
  physicianType,
  reviewDetails,
  physicianDetails,
  divider,
}: PhysicianDetailsProps) => {
  return (
    <Grid item xs={4} sx={PhysicianDetailsGrid}>
      <Box width={"80%"}>
        <Box sx={displayFlex} pt={"0.5rem"}>
          <FaUserMd size={"1.2rem"} color={colors.primary.main} />
          <Typography
            variant="subtitle1"
            color={colors.black[5]}
            fontWeight={fontWeight.Weight[5]}
            sx={HeaderTitle}
          >
            {physicianType}
          </Typography>
        </Box>
        {fieldRender(
          PhysicianDetailsLabel.PHYSICIAN_NAME,
          getValue(
            reviewDetails,
            physicianDetails && physicianDetails.physicianName
          )
        )}
        {fieldRender(
          PhysicianDetailsLabel.NPI,
          getValue(
            reviewDetails,
            physicianDetails && physicianDetails.physicianNpiId
          )
        )}
        {fieldRender(
          PhysicianDetailsLabel.ADDRESS,
          getValue(
            reviewDetails,
            physicianDetails && physicianDetails.physicianAddress
          ),
          undefined,
          undefined,
          undefined,
          true
        )}
        {fieldRender(
          PhysicianDetailsLabel.PHONE,
          getValue(
            reviewDetails,
            physicianDetails && getFormattedPhoneNo(physicianDetails.phoneNo)
          ),
          <FaPhone size={15} />,
          true,
          getValue(reviewDetails, physicianDetails && physicianDetails.phoneExt)
        )}
        {fieldRender(
          PhysicianDetailsLabel.FAX,
          getValue(
            reviewDetails,
            physicianDetails &&
            physicianDetails.fax &&
            getFormattedPhoneNo(physicianDetails.fax)
          ),
          <FaFax size={15} />
        )}
      </Box>
      {divider && (
        <Box width={"20%"} pl="1rem">
          <Divider light orientation="vertical" />
        </Box>
      )}
    </Grid>
  );
};

const fieldRender = (
  label: string,
  value: string | number,
  icon?: any,
  isExtention?: boolean | undefined,
  extension?: string,
  address?: boolean
) => {
  return (
    <Box sx={fieldBox(address)}>
      <Box>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[2]}
          sx={FieldLabel}
        >
          {label}:
        </Typography>
      </Box>
      <Box sx={address ? Address : displayFlex}>
        {value && icon ? <Box sx={ItemIconBox}>{icon}</Box> : ""}
        <Typography
          variant={
            label === ReviewRequestorLabel.PHYSICIAN_NAME ||
              label === ReviewRequestorLabel.NPI
              ? "h6"
              : "subtitle2"
          }
          fontWeight={fontWeight.Weight[4]}
          color={colors.fonts[6]}
          lineHeight={1.5}
        >
          {value ? value : ""}
        </Typography>
        {extension && (
          <Typography
            variant="subtitle2"
            fontWeight={fontWeight.Weight[4]}
            color={colors.fonts[6]}
            lineHeight={1.5}
            sx={Extention(isExtention)}
          >
            Ext: {extension}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ReAuthReviewICD10AndPhysicianDetails;
