import { DefaultPatientSearchValues, PatientInformationView } from "../models/Patient";

export enum PatientSummaryActionDispatchTypes {
  PATIENT_SUMMARY_INVOKE = "PATIENT_SUMMARY_INVOKE",
  PATIENT_SUMMARY_SUCCESS = "PATIENT_SUMMARY_SUCCESS",
}

export enum PatientInformationActionDispatchTypes {
  PATIENT_INFORMATION_INVOKE = "PATIENT_INFORMATION_INVOKE",
  PATIENT_INFORMATION_SUCCESS = "PATIENT_INFORMATION_SUCCESS",
}

export enum NotifyInternalDataUpdateActionDispatchTypes {
  NOTIFY_INTERNAL_DATA_UPDATE_INVOKE = "NOTIFY_INTERNAL_DATA_UPDATE_INVOKE",
  NOTIFY_INTERNAL_DATA_UPDATE_SUCCESS = "NOTIFY_INTERNAL_DATA_UPDATE_SUCCESS",
  NOTIFY_INTERNAL_DATA_UPDATE_RESET = "NOTIFY_INTERNAL_DATA_UPDATE_RESET",
}

export enum SavePatientInformationActionDispatchTypes {
  SAVE_PATIENT_INFORMATION_INVOKE = "SAVE_PATIENT_INFORMATION_INVOKE",
  SAVE_PATIENT_INFORMATION_SUCCESS = "SAVE_PATIENT_INFORMATION_SUCCESS",
}

export enum PatientDetailsActionDispatchTypes {
  PATIENT_DETAILS_INVOKE = "PATIENT_DETAILS_INVOKE",
  PATIENT_DETAILS_SUCCESS = "PATIENT_DETAILS_SUCCESS",
}

export enum SaveOrUpdatePatientDetailsActionDispatchTypes {
  SAVE_PATIENT_DETAILS_INVOKE = "SAVE_PATIENT_DETAILS_INVOKE",
  SAVE_PATIENT_DETAILS_SUCCESS = "SAVE_PATIENT_DETAILS_SUCCESS",
}

export enum SaveReceivedMethodActionDispatchTypes {
  SAVE_RECEIVED_METHOD_INVOKE = "SAVE_RECEIVED_METHOD_INVOKE",
  SAVE_RECEIVED_METHOD_SUCCESS = "SAVE_RECEIVED_METHOD_SUCCESS",
}

export enum UrgencyStatusActionDispatchTypes {
  URGENCY_STATUS_INVOKE = "URGENCY_STATUS_INVOKE",
  URGENCY_STATUS_SUCCESS = "URGENCY_STATUS_SUCCESS",
}

export enum RelationshipActionDispatchTypes {
  RELATIONSHIP_INVOKE = "RELATIONSHIP_INVOKE",
  RELATIONSHIP_SUCCESS = "RELATIONSHIP_SUCCESS",
}

export const patientRecords = [
  {
    patientId: 0,
    fullName: "name",
    gender: "Gender",
    dateOfBirth: "DOB",
    patientMBI: "MBI NUMBER",
    ahcccsid: "AHCCCS NUMBER",
  },
];

export enum DefaultHeader {
  DOB = "dob",
  GENDER = "gender",
  NAME = "name",
}

export const requestingFacilityData = [
  {
    requestId: 1,
    label: "Home Health Agency",
  },
  {
    requestId: 2,
    label: "Patient",
  },
  {
    requestId: 3,
    label: "Insurance",
  },
  {
    requestId: 4,
    label: "Hospital",
  },
  {
    requestId: 5,
    label: "SNF",
  },
  {
    requestId: 6,
    label: "Md Office",
  },
  {
    requestId: 7,
    label: "Beneficiary",
  },
];

export const originalReferralPolicyData = [
  {
    id: 1,
    label: "Hospital",
  },
  {
    id: 2,
    label: "SNF",
  },
  {
    id: 3,
    label: "MD Office",
  },
  {
    id: 4,
    label: "Beneficiary",
  },
];

const colSize = { minWidth: "100px" };
export const callLogHeader = [
  { label: "CALL DATE", style: colSize },
  { label: "CALL TIME", style: colSize },
  { label: "REF NUMBER", style: colSize },
  { label: "SPOKE WITH", style: colSize },
  { label: "NOTE", style: { minWidth: "300px", textAlign: "center" } },
  { label: "OUTCOME", style: colSize },
];

export const viewAddCallLogHeader = [
  { label: "CALL DATE", style: colSize },
  { label: "CALL TIME", style: colSize },
  { label: "SPOKE WITH", style: colSize },
  { label: "NOTE", style: { minWidth: "300px", textAlign: "center" } },
  { label: "OUTCOME", style: colSize },
];

export enum PatientSummaryMessageType {
  SAVE_OR_UPDATE_PATIENT_SUCCESS = "SAVE_OR_UPDATE_PATIENT_SUCCESS",
  SAVE_OR_UPDATE_PATIENT_FAILED = "SAVE_OR_UPDATE_PATIENT_FAILED",
}

export const PatientResponseMessages = (value: string) => {
  switch (value) {
    case PatientSummaryMessageType.SAVE_OR_UPDATE_PATIENT_SUCCESS:
      return "Patient details saved successfully !";
    case PatientSummaryMessageType.SAVE_OR_UPDATE_PATIENT_FAILED:
      return "Patient details save failed !";
    default:
      return "";
  }
};

export const PatientDetailsInfo =
  "Please add Last name first. Enter the patient's first name and any additional name(s) (such as middle initial) exactly as show on the insurance information.";

export const UpdateEligibilityInformationQuestion =
  "Most current eligibility information is below. Does this information need to be updated?";
export const CurrentEligibilityInformation = "Current Eligibility Information:";
export const UpdateCurrentEligibilityInformation = "Update Information:";

export enum PatientInformationActionDispatchTypesView {
  VIEW_PATIENT_INFORMATION_INVOKE = "VIEW_PATIENT_INFORMATION_INVOKE",
  VIEW_PATIENT_INFORMATION_RESET = "VIEW_PATIENT_INFORMATION_RESET",
  VIEW_PATIENT_INFORMATION_SUCCESS = "VIEW_PATIENT_INFORMATION_SUCCESS",
}

export enum UpdatePatientPhotoActionDispatchTypes {
  UPDATE_PATIENT_PHOTO_ERROR = "UPDATE_PATIENT_PHOTO_ERROR",
  UPDATE_PATIENT_PHOTO_INVOKE = "UPDATE_PATIENT_PHOTO_INVOKE",
  UPDATE_PATIENT_PHOTO_SUCCESS = "UPDATE_PATIENT_PHOTO_SUCCESS",
}

export enum IncompletePatientActionDispatchTypes {
  POST_INCOMPLETE_PATIENT_ERROR = "POST_INCOMPLETE_PATIENT_ERROR",
  POST_INCOMPLETE_PATIENT_INVOKE = "POST_INCOMPLETE_PATIENT_INVOKE",
  POST_INCOMPLETE_PATIENT_SUCCESS = "POST_INCOMPLETE_PATIENT_SUCCESS",
}

export enum AssignStaffIncompletePatientActionDispatchTypes {
  ASSIGN_STAFF_INCOMPLETE_PATIENT_ERROR = "ASSIGN_STAFF_INCOMPLETE_PATIENT_ERROR",
  ASSIGN_STAFF_INCOMPLETE_PATIENT_INVOKE = "ASSIGN_STAFF_INCOMPLETE_PATIENT_INVOKE",
  ASSIGN_STAFF_INCOMPLETE_PATIENT_SUCCESS = "ASSIGN_STAFF_INCOMPLETE_PATIENT_SUCCESS",
}

export enum RemovePatientActionDispatchTypes {
  REMOVE_PATIENT_ERROR = "REMOVE_PATIENT_ERROR",
  REMOVE_PATIENT_INVOKE = "REMOVE_PATIENT_INVOKE",
  REMOVE_PATIENT_SUCCESS = "REMOVE_PATIENT_SUCCESS",
}

export enum FilterType {
  SERVICE_PROVIDER = 1,
  COUNTY,
  STATE,
  HEALTH_PLAN,
  ASSIGN_TO,
}

export const BackClickAlertMessage =
  "Are you sure you want to exit without saving current page?";
export const SaveDetailsAlertMessage = "Are you sure you want to save details?";

export const defaultHistoryObject = {
  page: 1,
  tabNumber: 0,
};

export const eligibilityStatus = [
  {
    label: "Eligible",
    value: "Eligible",
  },
  {
    label: "Not Eligible",
    value: "Not Eligible",
  },
];

export enum PatientHeaderTitle {
  MEMBER_INFO = "MEMBER INFO",
  ADDRESS_INFO = "ADDRESS INFO",
  PHYSICIAN_INFO = "PHYSICIAN INFO",
  ALTERNATE_CONTACT = "ALTERNATE CONTACT",
}

export const PatientHeaderLabel = [
  { label: PatientHeaderTitle.MEMBER_INFO, fontWeight: 500 },
  { label: PatientHeaderTitle.ADDRESS_INFO, fontWeight: 500 },
  { label: PatientHeaderTitle.PHYSICIAN_INFO, fontWeight: 500 },
  { label: PatientHeaderTitle.ALTERNATE_CONTACT, fontWeight: 500 },
];

export const PatientSearchRecordFields = [
  "firstName",
  "lastName",
  "authorizationNumber",
  "healthPlan",
  "dateOfBirth",
  "subscriberId",
]

export const PatientHeaderDefaultValues: PatientInformationView = {
  patientId: 0,
  referralId: 0,
  serviceRequestId: 0,
  patientName: "",
  patientFirstName: null,
  patientLastName: null,
  languagePreferenceKind: null,
  languagePreferenceValue: null,
  isPriorAuthIntakeStageComplete: true,
  isReAuthIntakeStageComplete: true,
  isPrioAuthCompleted: true,
  isManual: true,
  blobId: 0,
  blobFileName: "",
  patientEligibilityId: 0,
  intakeStageId: 0,
  eligibilityPayerId: "",
  eligibilityPayerName: "",
  ahcccsId: "",
  memberInfo: {
    dateOfBirth: "",
    gender: null,
    subscriberId: null,
    medicareBeneficiaryIdentifier: null,
    phoneNo: null,
    healthPlan: null,
    effectiveDate: "",
    effectiveThrough: "",
    dateOfIssuance: "",
    benefitPlan: null,
    product: null,
    insuranceState: null,
    cellPhone: null,
    accommodationName: null,
    mobilePhone: null,
    alternatePhone: null,
    contractID_PBP: null,
    languageId: null,
    languageName: null,
    extension: null,
    accommodationId: null,
  },
  addressInfo: {
    patientMailingAddress: {
      streetName1: null,
      streetName2: null,
      zipCode: null,
      city: null,
      patientState: null,
      addressTypeId: 0,
      county: null,
      country: null,
      latitude: 0,
      longitude: 0,
    },
    patientResidentialAddress: {
      streetName1: null,
      streetName2: null,
      zipCode: null,
      city: null,
      patientState: null,
      addressTypeId: 0,
      county: null,
      country: null,
      latitude: 0,
      longitude: 0,
    },
    residentialAddress: "",
    mailingAddress: "",
  },
  emergencyContact: [],
  primaryPhysician: {
    referralId: 0,
    referralPhysicianDetailId: 0,
    physicianId: 0,
    physicianName: "",
    physicianNpiId: "",
    phoneExt: null,
    phoneNo: "",
    taxId: null,
    streetName1: "",
    streetName2: null,
    fax: null,
    county: "",
    city: "",
    physicianState: "",
    zipCode: "",
    physicianTypeId: 0,
    physicianAddress: null,
    physicianUid: "",
    fullNameCreated: "",
  },
  isPriorAuthCreated: false,
  authorizationTypeId: 0
};

export const ReAuthHealthPlanChangedMessage =
  "Health plan and/or subscriber id has been changed, please contact supervisor.";

export const defaultFormValues: DefaultPatientSearchValues = {
  firstName: "",
  lastName: "",
  authorizationNumber: "",
  healthPlan: "",
  dateOfBirth: null,
  subscriberId: "",
  contains: true,
  pageNo: 1,
  pageSize: 10,
};
