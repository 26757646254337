import { VariableSizeList, ListChildComponentProps } from "react-window";
import * as React from "react";
import Autocomplete, {
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { useRef, useState } from "react";

import { colors } from "../../styles/colors";
import fontWeight from "../../styles/mui/fontWeight";
import FormInputFieldIcon from "./FormInputFieldIcon";
import {
  AutoCompletePaper,
  AutocompleteTableHolder,
  FaSearchIcon,
  AutocompleteRow,
  StyledPopper,
  AvailableRecords,
  PhysicianRecordHeader,
  SearchedResultTableHead,
} from "./styles/style";
import { FadedContainedButtonUI } from "../../styles/common/style";
import { length } from "../../utils";
import { TableBody, TableHead } from "./constant/FormComponents";
import { ServiceProviderData } from "../../models/Authorization";
import { ServiceProvider, ServiceProviderPayload } from "../../models/Service";
import { PhysicianDetails } from "../../models/PhysicianDetails";

const LISTBOX_PADDING = 18;

const TypographyBox = (
  variant:
    | OverridableStringUnion<
        "inherit" | Variant,
        TypographyPropsVariantOverrides
      >
    | undefined,
  value: string,
  color: string,
  fontWeight: string,
  sx?: object
) => {
  return (
    <Typography variant={variant} fontWeight={fontWeight} color={color} sx={sx}>
      {value}
    </Typography>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <Box ref={ref} {...props} {...outerProps} />;
});
OuterElementType.displayName = "Search";

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

export interface ListOptionsData {
  options: any[];
  tableHead: TableHead[];
  tableBody: TableBody[];
  open: boolean;
  value:
    | ServiceProvider
    | ServiceProviderData
    | ServiceProviderPayload
    | PhysicianDetails
    | null;
  setOpen: (value: boolean) => void;
  handleInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  sx?: any;
  handleSelect?: any;
  autocompleteInputIcon?: any;
  maxLength?: number;
  width?: string;
  loading?: boolean;
  placeholder?: string;
}

const VirtualizeAutocomplete: React.FC<ListOptionsData> = ({
  options,
  handleInputChange,
  sx,
  open,
  setOpen,
  handleSelect,
  tableHead,
  tableBody,
  autocompleteInputIcon,
  maxLength,
  width,
  loading,
  placeholder,
  value,
}: ListOptionsData) => {
  React.useEffect(() => {
    window.addEventListener("scroll", closeDropdown);
    return () => {
      window.removeEventListener("scroll", closeDropdown);
    };
  }, []);

  const [resetInputValue, setResetInputValue] = useState<boolean>(false);
  const inputRef = useRef<any>(null);

  React.useEffect(() => {
    if (resetInputValue) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  }, [resetInputValue]);

  const closeDropdown = () => {
    setOpen(false);
  };

  const CustomPaper = (props: any) => {
    return <Paper {...props} sx={AutoCompletePaper(width)} />;
  };

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const details = data[index][1];

    const inlineStyle = {
      ...style,
      width: "100%",
      marginTop: "0.4rem",
    };

    if (Object.prototype.hasOwnProperty.call(dataSet, "group")) {
      return (
        <ListSubheader key={dataSet.key} component="div">
          {dataSet.group}
        </ListSubheader>
      );
    }

    return (
      <Box sx={[inlineStyle]}>
        <Grid container sx={AutocompleteRow}>
          {tableBody.map((col, index: number) => {
            if (index === tableBody.length - 1) {
              return (
                <Grid item xs={col.xs} key={index}>
                  <Box sx={col.style}>
                    <Button
                      variant="contained"
                      sx={[FadedContainedButtonUI]}
                      onClick={() => {
                        handleSelect(details)
                        setResetInputValue(true);
                       }}
                    >
                      {TypographyBox(
                        col.type,
                        col.key,
                        col.color,
                        col.fontWeight
                      )}
                    </Button>
                  </Box>
                </Grid>
              );
            }

            return (
              <Grid item xs={col.xs} key={index}>
                <Box sx={col.style}>
                  {TypographyBox(
                    col.type,
                    details[col.key],
                    col.color,
                    col.fontWeight
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }

  // Adapter for react-window
  const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement | null>
  >(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: any[] = [];

    (children as any[]).forEach((item: any & { children?: any[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const rowHeight = 42;

    // eslint-disable-next-line
    const getChildSize = (_child: any) => {
      return rowHeight;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * rowHeight;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    const styles = (style: object) => ({
      ...SearchedResultTableHead(),
      ...PhysicianRecordHeader(),
      ...style,
    });

    return (
      <Box ref={ref}>
        <OuterElementContext.Provider value={other}>
          <Box>
            <Box sx={AutocompleteTableHolder}>
              <Grid container>
                {tableHead && length(tableHead) && (
                  <Grid item xs={12}>
                    <Box sx={AvailableRecords}>
                      {TypographyBox(
                        "subtitle2",
                        "Available records :",
                        colors.black[2],
                        fontWeight.Weight[4]
                      )}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box height="2rem">
                    <Grid container>
                      {tableHead &&
                        length(tableHead) &&
                        tableHead.map((item, index) => {
                          return (
                            <Grid item xs={item.xs} key={index}>
                              <Box sx={styles(item.style)} key={index}>
                                {TypographyBox(
                                  item.type,
                                  item.label,
                                  item.color,
                                  item.fontWeight
                                )}
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Box>
                  <Box>
                    <VariableSizeList
                      itemData={itemData}
                      height={getHeight() + 2 * LISTBOX_PADDING}
                      width="100%"
                      style={{ border: "none" }}
                      ref={gridRef}
                      outerElementType={OuterElementType}
                      innerElementType="ul"
                      itemSize={(index: any) => getChildSize(itemData[index])}
                      overscanCount={5}
                      itemCount={itemCount}
                    >
                      {renderRow}
                    </VariableSizeList>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </OuterElementContext.Provider>
      </Box>
    );
  });
  const handleChange = () => {
    setResetInputValue(true);
  };

  return (
    <Autocomplete
      id="virtualize-autocomplete"
      value={value}
      sx={sx}
      open={open}
      freeSolo
      onChange={handleChange}
      onOpen={() => {
        if (Array.isArray(options) && length(options)) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      clearOnBlur={true}
      onBlur={() => setOpen(false)}
      PopperComponent={StyledPopper}
      PaperComponent={CustomPaper}
      loading={loading}
      ListboxComponent={ListboxComponent}
      options={options || []}
      onInputChange={(event, value, reason) => {
        setResetInputValue(false)
        handleInputChange && handleInputChange(event, value.trim(), reason);
      }}
      filterOptions={(options) => options}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      renderGroup={(params) => params as unknown as React.ReactNode}
      renderInput={(params) => {
        params.inputProps.maxLength = maxLength;
        return (
          <FormInputFieldIcon
            iconStart={autocompleteInputIcon}
            secondIcon={<FaSearchIcon />}
            {...params}
            placeholder={placeholder}
            elementRef={inputRef}
          />
        );
      }}
      getOptionLabel={(option: any) => option[tableHead[0].key]}
      forcePopupIcon={Array.isArray(options) && options.length > 0}
    />
  );
};
export default VirtualizeAutocomplete;
