import Box from "@mui/material/Box/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { InputSearchIconUI } from "./styles/style";

interface inputFieldIcons {
  iconStart: any;
  InputProps: any;
  secondIcon?: any;
  value?: any;
  placeholder?: string;
  elementRef?: any;
}

type AllProps = inputFieldIcons;

const FormInputFieldIcon: React.FC<AllProps> = ({
  iconStart,
  InputProps,
  secondIcon,
  placeholder,
  elementRef,
  ...props
}: AllProps) => {

  const handleKeyDown = (event: any) =>{
    const value = event.target.value.trim();
    if(event.key === ' ' && !value.trim()){
      event.preventDefault();
    }
  }

  return (
    <Box>
      <TextField
        {...props}
        variant="standard"
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        inputRef={elementRef}
        InputProps={{
          ...InputProps,
          startAdornment: iconStart ? (
            <>
              <InputAdornment position="start" sx={InputSearchIconUI}>
                {iconStart}
              </InputAdornment>
              {secondIcon && (
                <InputAdornment position="start" sx={InputSearchIconUI}>
                  {secondIcon}
                </InputAdornment>
              )}
            </>
          ) : (
            secondIcon && (
              <InputAdornment position="start" sx={InputSearchIconUI}>
                {secondIcon}
              </InputAdornment>
            )
          ),
        }}
      />
    </Box>
  );
};

export default FormInputFieldIcon;
