import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useWatch,
} from "react-hook-form";

import {
  FileHeaderGrid,
  FileUploadBox,
  FlexBox,
  FormLabelSpace,
} from "../styles/Document";
import fontWeight from "../../../../../styles/mui/fontWeight";
import colors from "../../../../../styles/colors/colors";
import FileUpload from "../../../../../components/formComponents/FileUpload";
import { inputType } from "../../../../../constants/Constants";
import {
  ButtonFormControl,
  CancelButton,
  DateFormControl,
  DatePickerInput,
  DropdownGrid,
  GridBody,
  HiddenTextField,
  HorizontalDivider,
  SaveButton,
  TitleBox,
} from "../styles/DocumentReview";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import DatePicker from "../../../../../components/formComponents/DatePicker";
import { FormInputTimePicker } from "../../../../../components/formComponents/FormInputTimePicker";
import { FormInputDropdown } from "../../../../../components/formComponents/FormInputDropdown";
import { rules } from "../../../../../utils/validation/Validation";

export interface PropsFromState {
  control: Control<any, any>;
  files: any;
  setFiles: React.Dispatch<any>;
  maxFileSizeInBytes: number;
  maxFileSizeInMegaBytes: string;
  minFileSize: number;
  title: string;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  trigger: UseFormTrigger<any>;
  clearErrors: UseFormClearErrors<any>;
  getValues: UseFormGetValues<any>;
  dateLabel: string;
  timeLabel: string;
  addFileFormDropdownList: any;
  handleCancelFile: () => void;
  handleSave: () => void;
  documentError?: string;
  type: number;
  allowedFiles: string;
}

type AllProps = PropsFromState;

const AddFileForm: React.FC<AllProps> = ({
  control,
  files,
  setFiles,
  allowedFiles,
  type,
  maxFileSizeInBytes,
  maxFileSizeInMegaBytes,
  minFileSize,
  title,
  setValue,
  watch,
  trigger,
  clearErrors,
  getValues,
  dateLabel,
  timeLabel,
  handleCancelFile,
  handleSave,
  documentError,
  addFileFormDropdownList,
}: AllProps) => {
  useWatch({
    control,
  });

  const formInputLabel = (label: string) => {
    return (
      <InputLabel required shrink={true} sx={displayFlex}>
        <Typography
          variant="h5"
          fontWeight={fontWeight.Weight[3]}
          color={colors.fonts[2]}
        >
          {label}
        </Typography>
      </InputLabel>
    );
  };
  const typographyBox = (label: string, lineHeight?: number) => {
    return (
      <Box>
        <Typography
          variant="body1"
          fontWeight={fontWeight.Weight[3]}
          color={colors.black[5]}
          lineHeight={lineHeight}
        >
          {label}
        </Typography>
      </Box>
    );
  };

  return (
    <Grid container sx={GridBody}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          fontWeight={fontWeight.Weight[5]}
          color={colors.fonts[20]}
          sx={TitleBox}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider light sx={HorizontalDivider} />
      </Grid>
      <Grid item xs={12} sx={FileHeaderGrid}>
        <Grid container sx={FileUploadBox}>
          <Grid item xs={3.5}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container sx={FlexBox}>
                  <Grid item xs={3}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.fonts[20]}
                      sx={FormLabelSpace}
                    >
                      Add files:
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {typographyBox(allowedFiles)}
                    {typographyBox(
                      `Max file size: ${maxFileSizeInMegaBytes} mb`,
                      1.5
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  inputType={inputType.referralDocs}
                  files={files}
                  setFiles={setFiles}
                  maxFileSizeInBytes={maxFileSizeInBytes}
                  type={type}
                  minFileSize={minFileSize}
                />
              </Grid>
            </Grid>
            <Controller
              defaultValue={""}
              control={control}
              rules={rules.fileSelect}
              name="fileName"
              render={({ fieldState: { error } }) => (
                <FormHelperText>{error ? error.message : null}</FormHelperText>
              )}
            />
          </Grid>
          <Grid item xs={0.2} sx={displayFlex}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={2.5}>
            <FormControl variant="standard" sx={DateFormControl}>
              <DatePicker
                name="dateTimeReceived"
                control={control}
                label={dateLabel}
                helper={rules.dateTimeRequired}
                allowFutureDate={false}
                InputStyle={DatePickerInput}
              />
            </FormControl>
            <FormControl variant="standard" fullWidth sx={DateFormControl}>
              <FormInputTimePicker
                name="dateTimeReceived"
                control={control}
                setValue={setValue}
                label={timeLabel}
                helper={rules.timeRequired}
                value={getValues("dateTimeReceived")}
                allowFutureTime={false}
                watch={watch}
                trigger={trigger}
                clearErrors={clearErrors}
                InputStyle={DatePickerInput}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0.2} sx={displayFlex}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item xs={5.6}>
            <Grid container>
              <Grid item xs={5} sx={DropdownGrid}>
                <FormControl variant="standard" fullWidth>
                  {formInputLabel(addFileFormDropdownList[0].inputLabel)}
                  <FormInputDropdown
                    name={addFileFormDropdownList[0].dropdownName}
                    control={control}
                    helper={addFileFormDropdownList[0].dropdownHelper}
                    list={addFileFormDropdownList[0].dropdownList}
                  />
                </FormControl>
                <FormControl variant="standard" fullWidth  sx={DateFormControl}>
                  {formInputLabel(addFileFormDropdownList[1].inputLabel)}
                  <FormInputDropdown
                    name={addFileFormDropdownList[1].dropdownName}
                    control={control}
                    helper={addFileFormDropdownList[1].dropdownHelper}
                    list={addFileFormDropdownList[1].dropdownList}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={7} pl={"1rem"}>
                <FormControl variant="standard" sx={ButtonFormControl}>
                  <Button
                    variant="outlined"
                    sx={CancelButton}
                    onClick={handleCancelFile}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    sx={SaveButton}
                    onClick={handleSave}
                  >
                    SAVE
                  </Button>
                </FormControl>
              </Grid>
              {documentError && (
                <Grid item xs={12}>
                  <HiddenTextField type="hidden" />
                  <FormHelperText>{documentError}</FormHelperText>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddFileForm;
